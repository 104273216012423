/* RESET + VARS + TOOLTIP IMPORT */
@import "var/fonts";
@import "var/colors";
@import "var/prices";
@import "components/reset";
@import "components/product_tooltip";

/* APP PLUGINS */
@import "plugin/OverlayScrollbars";
@import "plugin/swiper_slider";

/* APP IMPORT */
@import "../../../_app/_src/scss/desktop/app";
@import "../../../_app/_src/scss/desktop/components/add_cart_product_tooltip";
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";

/* COMPONENTS IMPORT */
@import "components/global";
@import "components/forms";
@import "components/header";
@import "components/footer";
@import "components/buttons";
@import "components/lightbox";
@import "components/achat_express";

/* PAGE IMPORT */
@import "pages/home";
@import "pages/account";
@import "pages/texte";
@import "pages/satellite";
@import "pages/faq";
@import "pages/storelocator";
@import "pages/product";
@import "pages/tunnel";
@import "pages/category";
@import "pages/lookbook";
.lookbook_page {
    padding-top: 0 !important;

    &.bandeauDelivery {
        padding-top: 39px !important;
    }

    .main_wrapper {
        .titre_lookbook {
            font-family: $secondaryFont;
            font-size: 60px;
            letter-spacing: 0;
            line-height: 1;
        }

        .rayon_lookbook {
            .in_lookbook {
                #scroll_items {
                    #list_item {
                        max-width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        padding: 0;

                        .titre_lookbook {
                            width: 35.6%;
                        }

                        .item {
                            /* TO DELTE */
                            &.seo {
                                .txt_seo {
                                    .title {
                                        font-family: $secondaryFont;
                                        font-size: 60px;
                                        line-height: 1;
                                        margin-bottom: 30px;
                                        text-transform: uppercase;
                                        display: inline-block;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 50px;
                                        }

                                        @media only screen and (max-width: 1580px) {
                                            font-size: 40px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 35px;
                                        }
                                    }

                                    .txt {
                                        font-family: $primaryFont;
                                        font-size: 17px;
                                        line-height: 1;
                                        text-align: left;
                                        display: inline-block;
                                    }
                                }
                            }

                            &.lookbook {
                                position: relative;
                                margin: 0;

                                img {
                                    display: block;
                                }

                                &:nth-of-type(1) {
                                    width: 61.55%;
                                    max-width: 1171px;
                                    display: block;
                                    margin-right: 3.385%;
                                    margin-bottom: 100px;
                                    align-self: flex-start;

                                    .content_holder {
                                        .imgWrapper {
                                            display: flex;

                                            img:not(.tag_img) {
                                                width: 100%;
                                                height: auto;
                                            }

                                            .look_seo {
                                                p {
                                                    font-family: $primaryFont;
                                                    font-weight: 200;
                                                    font-size: 17px;
                                                    line-height: 1;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:nth-of-type(2) {
                                    width: 35%;
                                    margin: 0;
                                    padding-top: 120px;

                                    .item {
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: space-between;

                                        &.seo {
                                            width: 82.456% !important;
                                            margin: 0 0 8.75rem 0 !important;

                                            @media only screen and (max-width: 1680px) {
                                                margin: 0 0 3rem 0 !important;
                                                width: calc(100% - 30px) !important;
                                            }
                                        }

                                        &.lookbook {
                                            width: 38.88%;
                                            margin-left: 6.5789%;
                                            position: relative;
                                            height: 11rem;

                                            @media only screen and (max-width: 1680px) {
                                                height: 9rem;
                                            }

                                            @media only screen and (max-width: 1580px) {
                                                height: 7.5rem;
                                            }

                                            @media only screen and (max-width: 1480px) {
                                                height: 6.5rem;
                                            }

                                            @media only screen and (max-width: 1440px) {
                                                height: 5rem;
                                            }
                                        }
                                    }
                                }

                                &:nth-of-type(3) {
                                    width: 32.5%;
                                    margin-right: 6.5625%;
                                    margin-left: 6.25%;
                                    margin-bottom: 143px;
                                }

                                &:nth-of-type(4) {
                                    width: 35.833%;
                                    margin-right: 6.25%;
                                    margin-bottom: 143px;
                                }

                                &:nth-of-type(5) {
                                    width: 25.9375%;
                                    margin-left: 17.0833%;
                                    margin-right: 1.51%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(6) {
                                    width: 25.9375%;
                                    margin-right: 17.0833%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(7) {
                                    width: 23.958333%;
                                    margin-left: 6.25%;
                                    margin-right: 1.5625%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(8) {
                                    width: 23.958333%;
                                    margin-right: 1.5625%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(9) {
                                    width: 23.958333%;
                                    margin-right: 6.25%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(10) {
                                    width: 32.5%;
                                    margin-left: 6.25%;
                                    margin-right: 6.5625%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(11) {
                                    width: 35.8333%;
                                    margin-right: 6.25%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(12) {
                                    width: 25.9375%;
                                    margin-right: 1.5625%;
                                    margin-left: 17.08333%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(13) {
                                    width: 25.9375%;
                                    margin-right: 17.08333%;
                                    margin-bottom: 100px;
                                }

                                &:nth-of-type(14) {
                                    width: 75%;
                                    margin: auto;
                                }

                                .item_wrapper_look {
                                    display: none;
                                }
                            }
                        }

                        .tag {
                            position: absolute;

                            &:before {
                                content:"";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 203px;
                                height: 262px;
                                background: transparent;
                            }

                            .pinpoint {
                                position: relative;
                                top: 0;
                                left: 0;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background-color: rgba(255,255,255,.8);
                                z-index: 2;
                                
                                &::after {
                                    content: "+";
                                    display: block;
                                    font-size: 31.67px;
                                    color: $black;
                                    font-weight: 500;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: auto;
                                    width: 24px;
                                    height: 24px;
                                    line-height: 24px;
                                    text-align: center;
                                    font-family: $secondaryFont;
                                }

                            }
                            &:hover {
                                .tag_tooltip {
                                    opacity: 1;
                                    pointer-events: initial;
                                }
                                .pinpoint::after {
                                    content: '-';
                                    display: flex;
                                    width: 100%;
                                    height: 100%;
                                    justify-content: center;
                                }
                            }
                        }
                        .tag_tooltip {
                            position: absolute;
                            bottom: 0;
                            left: calc(100% + 10px);
                            z-index: 1;
                            pointer-events: none;
                            opacity: 0;
                            transition: all .3s ease;
                            padding: 15px;
                            background-color: $white;
                            color: $primaryColor;
                            font-weight: normal;
                            width: 169px;
                            height: 262px;
                            box-sizing: border-box;

                            img {
                                width: 100% !important;
                            }
                            
                            .tag_description {
                                line-height: 1;
                                margin-top: 3px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;

                                .tag_name {
                                    font-size: 17px;
                                    font-family: $primaryFont;
                                    display: inline;
                                    font-weight: 600;
                                    text-transform: none;
                                    padding-right: 5px;
                                }
                            }

                            .product_price {
                                line-height: 1;

                                .pricetag {
                                    font-size: 15px;
                                    line-height: 1;

                                    & + .pricetag {
                                        font-size: 13px;
                                    }
                                }
                            }

                            .tag_name {
                                margin-top: 31px;
                            }

                            img {
                                width: 100%;
                                height: 202px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* GENERAL LIGHTBOX STYLES */
.lightbox {
    width: 50%;
    display: none;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    border-bottom: solid 1px $white; /* pour retirer le blur du text */

    .close_pop {
        position: absolute;
        z-index: 5;
        top: 10px;
        right: 9px;
        background-color: transparent;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        border: none;

        span {
            font-weight: 600;
            font-size: 0;
            color: $primaryColor;
            background-image: url('../svg/spritesheet.svg');
            background-repeat: no-repeat;
            background-size: 600%;
            background-position: 75% 50%;
            width: 12px;
            height: 12px;
            display: block;
        }

        &:hover {
            opacity: 0.75;
        }
    }
    .lightbox_content {
        padding: 3em 2em;
    }

    &#abox {
        width: auto;
        background-color: $white;
        text-align: center;
        min-width: 440px;
        padding: 40px;
    }

    &#sendwishlistbox {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 692px;
        .w-sendwishlist-form,
        #wishlistform {
            position: relative;
            width: 100%;
            padding: 50px 0 35px;
            background-color: $white;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            #content_sendwishlistbox,
            #content_wishlistbox {
                width: 510px;
                margin: 0 auto;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .popup_title {
                    margin-bottom: 47px;
                    width: 100%;
                    .title {
                        font-size: 40px;
                        line-height: 26px;
                        font-family: $secondaryFont;
                        text-transform: uppercase;
                        margin-bottom: 30px;
                    }
                    .subtitle {
                        font-size: 17px;
                        font-family: $primaryFont;
                        font-weight: 300;
                        line-height: 12px;
                    }
                }
                #wrapper_sendwishlist {
                    width: 510px;
                    margin: 0 auto;
                    text-align: center;
                    .form_title {
                        font-size: 18px;
                        line-height: 15px;
                        font-family: $secondaryFont;
                        margin-bottom: 15px;
                        display: flex;
                        text-transform: uppercase;
                    }
                    input#sendWishListToMails,
                    input#sendWishListYourMail,
                    input#sendWishListYourName {
                        width: 100%;
                        padding: unset;
                        min-height: 2.3rem;
                        text-indent: 0.875rem;
                        font-family: brownpro,sans-serif;
                        font-size: .875rem;
                        background-color: transparent;
                        border: 1px solid $grey;
                        border-radius: 0;
                        outline: 0;
                        margin-bottom: 10px;
                        height: 3.42857em;
                    }
                    input#sendWishListYourMail {
                        margin-bottom: 9px;
                    }
                    textarea#sendWishListToMessage {
                        line-height: 1.14286;
                        height: 8.5em;
                        padding: 1em 0.85714em;
                        resize: none;
                        width: 96%;
                        padding-top: 1.75em;
                    }
                    
                    .w-has-error {
                        input#sendWishListToMails,
                        textarea#sendWishListToMessage,
                        input#sendWishListYourMail,
                        input#sendWishListYourName {
                            border: 1px solid $alert;
                        }
                    }

                    .receive_copy {
                        display:flex;
                    }

                    #sendfriend_form_to {
                        margin-left: 0;
                        .form_title {
                            font-size: 18px;
                            line-height: 15px;
                            font-family: $secondaryFont;
                            margin-bottom: 15px;
                            display: flex;
                            text-transform: uppercase;

                            & + .w-form-line{
                                .w-input-error{
                                    margin-top: 1.3rem;
                                }
                            }
                        }
                        .w-form-line {
                            display: flex;
                            align-items: center;
                            position: relative;
                            width: 100%;
                            margin-bottom: 1rem;                        
                            .w-input-container {
                                margin: 0 0 20px;
                                    .w-input-note {    
                                        margin-top: 5px;
                                        &:not(.w-input-error){
                                            font-size: 16px;
                                            line-height: 7px;
                                            font-family: $primaryFont;
                                            display: flex;
                                            font-weight: 300;
                                        }
                                        &.w-input-error {
                                            margin-top: 15px;
                                            text-align: left;
                                            
                                        }
                                    }
                                    .w-textarea {
                                        textarea.w-input-element {
                                            &:focus {
                                                outline: none;
                                            }
                                        }

                                        &.w-has-error{
                                            textarea.w-input-element {
                                                box-shadow: 0 0 0 1px $alert!important;
                                            }
                                        }
                                    }
                                    .w-input-label {
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: $labelColor;
                                        font-family: $tertiaryFont;
                                        top: 40%;
                                        &:nth-child(1) {
                                            transform: translateY(100%);
                                        }
                                        &:nth-child() {
                                            transform: translateY(-75%);
                                        }
                                    }
                                    .w-nonempty {
                                        .w-input-element {
                                            padding-top: 1rem;
                                        }
                                        .w-input-label {
                                            top: 50%;
                                        }
                                    }
                                    .w-textarea {
                                        .w-input-element {
                                            padding-top: 2rem;
                                        }
                                    }
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        &:nth-child(3) {
                            .w-input-container {
                                margin-bottom: 17px;
                                .w-input-note {
                                    &.w-input-error {
                                        text-align: left;
                                        margin-top: 2px;
                                    }
                                }
                                .w-input-label {
                                    top: -8px;
                                    transform: translateY(100%);
                                }
                            }
                        }
                        }
                    }
                        
                    #sendfriend_form_froms {
                        .form_title {
                            font-size: 18px;
                            line-height: 21px;
                            font-family: $secondaryFont;
                            text-transform: uppercase;
                            display: flex;
                            margin-bottom: 10px;
                        }
                        .w-form-line {
                            margin-bottom: 0;
                            &:nth-child(2) {
                                .w-input-container { 
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                }
                            }
                            &:nth-child(3) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                    .w-input {
                                        input#sendWishListYourMail {
                                            margin-bottom: 9px;  
                                        }
                                    }
                                }
                            }
                            &:nth-child(4) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin-bottom: 22px;
                                        .w-checkbox-input {
                                            height: auto;
                                        }
                                    }
                                }
                            }
                            .w-input-container { 
                                margin: 0 0 30px;
                                &:nth-child(1) {
                                    margin: 0 0 29px;
                                }
                                .w-input-note {
                                    &:not(.w-input-error){
                                        font-size: 16px;
                                        line-height: 7px;
                                        font-family: $primaryFont;
                                        display: flex;
                                    }
                                    &.w-input-error {
                                        margin-top: 0px;
                                        text-align: left;
                                    }
                                }
                                .w-input-label {
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: $black;
                                    font-family: $tertiaryFont;
                                    top: 40%;
                                }
                                .w-nonempty {
                                    .w-input-element {
                                        padding-top: 1rem;
                                    }
                                    .w-input-label {
                                        top: 50%;
                                    }
                                }
                            }
                        }
                        .g-recaptcha {
                            display: flex;
                            justify-content: center;
                        }
                    }
                    #sendfriend_form_buttons {
                        position: relative;
                        width: 225px;
                        height: 40px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 19px;
                        .w-btn-wrapper {
                            .w-submit-button {
                                width: 227px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $white;
                                font-family: $primaryFont;
                                font-size: 15px;
                                line-height: 40px;
                                background-color: $ViolentViolet;
                                border: 1px solid $black;
                                &.w-loader{
                                    background: $WhiteRock url(../svg/three_dots_black.svg) center no-repeat;
                                    background-size: 11%;
                                    border: none;
                                    border-radius: 8px;
                                }
                                &:not(.w-loader):hover span {
                                    color: $black;
                                    background-color: $WhiteRock;
                                    border-radius: 8px;
                                    border: none;
                                }

                                &:hover {
                                    border: none;
                                }

                                &:focus {
                                    span {
                                        color: $black;
                                    }
                                }
                            }

                        }
                    }
                }
                #sendfriend_alert_mail {
                    margin: 0 auto;
                }
                .g-recaptcha {
                    display: flex;
                    justify-content: center;
                }
                
                .w-submit {
                    margin: 0 auto;
                }
            }
        }

        &.disconnect{
            .popup_title {
                margin-bottom: 47px;
                .title {
                    font-size: 40px;
                    line-height: 26px;
                    font-family: $secondaryFont;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }
                .subtitle {
                    font-size: 17px;
                    font-family: $primaryFont;
                    font-weight: 300;
                    line-height: 12px;
                }
            }
            #wrapper_sendwishlist {
                .plusieur_add {
                    font-size: 16px;
                    line-height: 7px;
                    font-family: Rokkitt,sans-serif;
                    display: flex;
                    font-weight: 300;
                    text-align: left;
                    margin-bottom: 20px;
                }
                textarea#sendWishListToMessage {
                    line-height: 1.14286;
                    height: 6em !important;
                    padding: 1em 0.85714em;
                    resize: none;
                    width: 96%;
                    padding-top: 1.75em;
                }
                .inputErr {
                    border: 1px solid $alert !important;
                }
                .form_title {
                    margin-top: 10px;
                    width: 95%;
                }
                input#sendWishListYourMail {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .receive_copy {
                    margin-bottom: 20px;
                    label {
                        margin-left: 5px; 
                    }
                }
                #sendfriend_form_buttons {
                    .button {
                        margin-top: 27px;
                    }
                }
            }
        }
    }

    &#exit_overlay_OVI{
        width: 603px;
        height: 422px;
        margin-top: -211px;
        margin-left: -301.5px;
        padding: 78px 25px 50px;
        transform: none;

        #formu_inscription_news{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .title_popup_inscription_home{
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 30px;
                line-height: 30px;
                color: $primaryColor;
                text-transform: uppercase;
                text-align: center;

                small{
                    font-family: $primaryFont;
                    font-weight: 300;
                    font-size: 16px;
                    text-transform: none;
                    text-align: center;
                }
            }

            .w-adwords-form{
                width: 514px;
                margin-top: 22px;

                .w-form-line-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .w-form-line{
                        margin-bottom: 0;

                        .w-email-input{
                            .w-input-element{
                                width: 313px;
                                height: 48px;
                                border-right: 0;
                            }
                        }

                        &:nth-child(2){
                            display: none;
                        }

                        &.w-submit{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 1px;

                            .w-submit-button{
                                height: 48px;
                                border-radius: 0 10px 10px 0;

                                &:hover{
                                    span{
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }

                    & + .pop_cgv_listing{
                        font-weight: 300;
                        font-size: 16px;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        text-align: center;
                        margin-top: 28px;
                        margin-bottom: 40px;
                    }
                }
            }
        }

        #conf_inscription_news{
            width: 100%;

            .title_popup_inscription_home{
                font-family: $secondaryFont;
                font-style: italic;
                font-weight: 400;
                font-size: 30px;
                line-height: 30px;
                color: $primaryColor;
                text-transform: uppercase;
                text-align: center;
            }

            .pop_txt_listing{
                font-weight: 300;
                font-size: 16px;
                text-transform: none;
                text-align: center;
                margin-top: 22px;
            }

            .form_submit{
                max-width: 200px;
                height: 48px;
                margin: 35px auto;

                .button{
                    height: 48px;
                }
            }
        }

        .socialWrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .title{
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: $primaryColor;
                text-transform: uppercase;
                text-align: center;
            }

            .wrap_icones{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 19px;

                .social_network{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    background-size: 600%;
                    background-position: 0% 0%;
                    width: 23px;
                    height: 23px;

                    @media not all and (min-resolution:.001dpcm) { 
                        @supports (-webkit-appearance:none) {
                            background-size: 555%;
                        }
                   }

                    &.ig{
                        background-position: 2% 26%;
                    }

                    &.fb{
                        background-position: 26% 26%;
                        margin: 0 17px;
                    }

                    &.pin{
                        background-position: 50% 26%;
                    }
                }
            }
        }
    }
}

/*
    Lightbox achat express et produit offert
*/
.lightbox#lightbox_achat_express,
#lightbox_achat_express {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 837px;
    max-width: calc(100% - 3rem);
    height: 521px;
    transform: inherit;
    padding: 0;

    .express_response,
    & > div {
        .close_pop {
            background-image: url('../svg/spritesheet.svg');
            background-repeat: no-repeat;
            background-size: 600%;
            background-position: 75% 50%;
            width: 12px;
            height: 12px;
            display: block;

            span {
                display: none;
            }
        }

        .page_produit.achat_express {
            .product_main_wrapper{
                display: flex;
                justify-content: space-between;
                width: 100%;

                #buyNowSwiper {
                    width: 42.3%;
                    max-width: 354px;
                    margin: 0;

                    .noStock_img_container {
                        display: none;
                    }

                    .swiper-button-prev,
                    .swiper-button-next {
                        width: 1rem;
                        height: 1rem;
                        background: url('../svg/spritesheet.svg');
                        background-repeat: no-repeat;
                        background-size: 500%;
                        background-position: 100% 0%;
                        position: absolute;
                        top: -8px;
                        bottom: 0;
                        margin: auto;
                        right: 1rem;
                        z-index: 5;
                        cursor: pointer;

                        &.swiper-button-disabled {
                            opacity: .35;
                            cursor: default;
                        }
                    }

                    .swiper-button-prev {
                        right: auto;
                        left: 1rem;
                        transform: rotate(180deg);
                    }
                }
                
                .product_infos {
                    margin-left: 2rem;
                    width: calc( 57.7% - 61px);

                    .btn_return {
                        display: none;
                    }

                    .product_infos_content {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: relative;

                        /* INFO, PRIX, CTA, COULEUR */
                        .product_infos_form {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: center;

                            .item_name {
                                display: flex;
                                justify-content: space-between;
                                order: 1;

                                #itm_name {
                                    font-family: $primaryFont;
                                    font-weight: 600;
                                    font-size: 28px;
                                    margin-bottom: 12px;
                                    line-height: 1;

                                    span {
                                        display: inline;
                                        margin-left: 6px;
                                        font-weight: 200;
                                        font-size: 18px;
                                    }
                                }
                            }

                            .more_details {
                                order: 5;
                                line-height: 1;
                                display: inline-block;
                                font-family: $primaryFont;
                                font-size: 15.5px;
                                width: auto;
                                font-style: normal;
                                margin-top: 1.5rem;
                                text-align: center;
                                text-decoration: underline;
                                position: relative;
                                left: -33px;
                                top: 25px;
                            }

                            .item_infos {
                                order: 2;
                                display: flex;
                                flex-direction: column;

                                #addToWishlistButton {
                                    width: 100%;
                                    max-width: 360px;
                                    background-color: $ViolentViolet;
                                    height: 40px;
                                    border-radius: 10px;
                                    border: none;
                                    color: $white;
                                    text-align: center;
                                    cursor: pointer;
                                    display: block;
                                    order: 2;
                                    position: relative;
                                    top: inherit;
                                    right: inherit;
                                    background-image: inherit;

                                    span {
                                        font-family: $primaryFont;
                                        font-size: 15px;
                                        text-transform: uppercase;
                                        font-weight: 200;
                                        line-height: 40px;
                                    }

                                    &:hover {
                                        background-color: $WhiteRock;
                                        color: $black;
                                    }
                                }

                                .item_price_wrapper {
                                    margin-bottom: 45px;
                                    .item_price {
                                         display: flex;
                                         align-items: flex-end;
                                         flex-wrap: wrap;

                                        .price_tag {
                                            .price, span {
                                                font-size: 17px;
                                                font-family: $secondaryFont;
                                                font-weight: 400;

                                                span {
                                                    &.no_cents {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            & + .price_tag {
                                                margin-left: .25rem;
                                                position: relative;
                                                text-decoration: line-through;
                                                .price, span {
                                                    font-size: 15px;
                                                }
                                            }
                                        }

                                        .eclat {
                                            display: none;
                                            width: 100%;
                                            margin-top: 5px;

                                            img {
                                                width: auto;
                                                height: auto;
                                            }
                                        }
                                    }
                                }

                                .itm_form_group {
                                    position: relative;

                                    .ligne_form {
                                        &.colors {
                                            margin-bottom: 25px;

                                            .right_element_prod {
                                                .choices_list{
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    position: relative;
                                                    top: -3px;

                                                    .form_itm {
                                                        width: 1.4375rem;
                                                        height: 1.4375rem;
                                                        margin-right: 1rem;

                                                        input[type=radio] {
                                                            display: none;

                                                            & + label {
                                                                display: block;
                                                                background-size: contain;
                                                                border-radius: 100%;
                                                                width: 1.8125rem;
                                                                height: 1.8125rem;
                                                                cursor: pointer;
                                                                position: relative;
                                                                border: 2px solid $white;
                                                                box-sizing: border-box;

                                                                &:before {
                                                                    content: '';
                                                                    position: absolute;
                                                                    width: 1.45rem;
                                                                    height: 1.45rem;
                                                                    border-radius: 100%;
                                                                    position: absolute;
                                                                    top: 0;
                                                                    left: 0;
                                                                    margin: auto;
                                                                    border: 1px solid rgba(0,0,0,0.1);

                                                                }
                                                            }

                                                            &:checked+label {
                                                                box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.25);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.sizes {
                                            margin-bottom: 45px;
                                            .size_wrapper {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;

                                                .choices_list  {
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    max-width: 320px;
                                                    position: relative;
                                                    top: 9px;

                                                    .form_itm.size {
                                                        position: relative;
                                                        width: auto;
                                                        height: 1rem;
                                                        text-align: center;
                                                        margin-right: 7px;
                                                        margin-bottom: 1rem;

                                                        input {
                                                            display: none;

                                                            &+label {
                                                                font-size: 17px;
                                                                margin: auto;
                                                                width: auto;
                                                                min-width: 1.8125rem;
                                                                height: 1.8125rem;
                                                                line-height: 1.8125rem;
                                                                display: inline-flex;
                                                                justify-content: center;
                                                                text-align: center;
                                                                color: $primaryColor;
                                                                font-weight: 500;
                                                                cursor: pointer;
                                                                font-family: $secondaryFont;

                                                                &:before {
                                                                    content:'';
                                                                    position: absolute;
                                                                    bottom: -13px;
                                                                    left: 0;
                                                                    right: 0;
                                                                    margin: auto;
                                                                    width: 100%;
                                                                    height: 1px;
                                                                    min-width: 1.8125rem;
                                                                    height: 1.8125rem;
                                                                    background-color: transparent;
                                                                    border-radius: 100%;
                                                                }

                                                                span {
                                                                    text-align: center;
                                                                    padding-left: 3px;
                                                                }

                                                            }

                                                            &:checked {
                                                                &+label {
                                                                    font-weight: 900;
                                                                    &:before {
                                                                         background-color: transparent;
                                                                         border: 1px solid rgba(0,0,0,.65);
                                                                    }

                                                                    &:after {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }

                                                            &.disabled {
                                                                &+label {
                                                                    color: $disabledColor;
                                                                    position: relative;

                                                                    &:before {
                                                                        content:'';
                                                                        position: absolute;
                                                                        height: 90%;
                                                                        width: 1px;
                                                                        background-color: $disabledColor;
                                                                        min-width: 1px;
                                                                        border-radius: 0;
                                                                        top: 0;
                                                                        bottom: 0;
                                                                        left: .35rem;
                                                                        margin: auto;
                                                                        transform: rotate(50deg);
                                                                    }
                                                                }

                                                                &:checked {
                                                                    &+label {
                                                                        color: $black;

                                                                        &:before {
                                                                            border: none;
                                                                            background-color: $black;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }
                                                }

                                                .block_sizeguide {
                                                    a {
                                                        display: inline-block;
                                                        font-size: 16px;
                                                        font-family: $primaryFont;
                                                        border-bottom: 1px solid $black;
                                                        line-height: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    #sizeerror {
                                        font-family: $primaryFont;
                                        font-size: 15px;
                                        color: $alert;
                                        position: absolute;
                                        bottom: .85rem;
                                    }

                                    /* CTA FP */
                                    &#product_addcart {
                                        display: none;
                                    }
                                }
                            }
                        }

                        /* MENTION PAYER PAR 3 FOIS */
                        .paye_par_3_fois {
                            display: none;
                        }

                        /* BLOC ACCCORDEON DESCRIPTION */
                        .product_feats_wrapper_accordion {
                            display: none;
                        }

                        /* BLOC REASSURANCE */
                        .bloc_reassurances_bottom {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

#lightbox_achat_express {
    z-index: 100;
    display: none;
    background: $white;
    border-bottom: solid 1px $white;
}

/*ACHAT EXPRESS LOOKBOOK*/
.lookbook_page {
    .lightbox {
        &#lightbox_achat_express {
            .express_response {
                .page_produit.achat_express {
                    .product_main_wrapper {
                        .product_infos {
                            .product_infos_content {
                                .product_infos_form {
                                    .item_infos {

                                        #addToWishlistButton {
                                            width: 1.5rem;
                                            height: 1.5rem;
                                            background: url('../svg/spritesheet.svg');
                                            background-repeat: no-repeat;
                                            background-size: 550%;
                                            background-position: 75% 0%;
                                            display: block;
                                            position: absolute;
                                            top: 109px;
                                            right: 60px;

                                            span {
                                                font-size: 0;
                                            }

                                            &.existToWishlistButton {
                                                background-position: 75% 25%;
                                            }
                                        }

                                        .itm_form_group {
                                            &#product_addcart {
                                                display: block;
                                                width: 100%;
                                                max-width: 360px;
                                                top: 5px;

                                                .prod_btn_wrapper {
                                                    display: flex;
                                                    justify-content: space-between;

                                                     #bloc_add_basket {
                                                        min-width: calc(50% - 5px);
                                                        flex-grow: 1;
                                                        .btn_container {
                                                            button {
                                                                width: 100%;
                                                                padding: 0;
                                                                border: none;
                                                                box-shadow: none;
                                                                background: none;
                                                                cursor: pointer;
                                                                margin: 0;
                                                                
                                                                #btn_add_cart {
                                                                    width: 100%;
                                                                    background-color: $ViolentViolet;
                                                                    height: 40px;
                                                                    border-radius: 10px;
                                                                    border: none;
                                                                    color: $white;
                                                                    text-align: center;
                                                                    font-family: $primaryFont;
                                                                    font-size: 15px;
                                                                    text-transform: uppercase;
                                                                    font-weight: 200;
                                                                    cursor: pointer;
                                                                }

                                                                &+.loader {
                                                                    width: 100%;
                                                                    height: 40px;
                                                                    border: 1px solid $black;
                                                                    background:  url(../svg/three_dots_black.svg) center center no-repeat;
                                                                    background-color: $white;
                                                                    background-size: 10%;
                                                                    border-radius: 10px;
                                                                    top: inherit;
                                                                    bottom: 0;
                                                                }
                                                            }
                                                        }

                                                        .roll_preco {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


#shadCookie.actif{
    z-index: 85!important;
}

#cookiesLightbox {
    background-color: $white;
    padding: 45px 0 17px;
    min-height: 230px;

    .cookiesLightbox_container {
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: 1fr auto auto;
        row-gap: 24px;
        width: 96%;
        max-width: 1440px;
        margin: 0 auto;
        font-family: $primaryFont;
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;

        .cookiesLightbox_accept_btn {
            grid-column: 3;
            grid-row: 2;
            width: 230px;

            #js_cookie_accept {
                width: 198px;
                margin-left: auto;
            }

            .w-loader {
                width: 198px;
                left: unset;
                right: 0;
            }
        }

        .cookiesLightbox_refuse_btn {
            grid-column: 2;
            grid-row: 2;
            margin-left: 0;
            width: 198px;
            
            #js_cookie_refuse {
                width: 198px;
                background-color: $white;
                border: 1px solid $primaryColor;
                align-self: end;

                span {
                    color: $primaryColor;
                }
            }
        }

        .cookiesLightbox_content {
            grid-column: 1 / 4;
            grid-row: 1;
            width: 100%;
            max-width: none;

            h3 {
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 18px;
                text-transform: uppercase;
                margin-bottom: 0.8rem;
            }
    
            a {
                text-decoration: underline;
            }
        }

        .cookiesLightbox_custom_btn {
            grid-column: 1;
            grid-row: 2;
            margin-left: 0;

            #jsCookieCustom.toggleCookiePrefs {
                height: 40px;
                margin-top: 0;
                background-color: $white !important;
                border: none;
                width: max-content;

                &::after {
                    display: none;
                }

                span {
                    font-family: $primaryFont;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 18px;
                    color: $primaryColor !important;
                    text-decoration: underline;
                    text-align: left;
                    display: block;
                    text-transform: none;
                }
            }

            .w-loader {
                display: none;
            }
        }

        .cookiesLightbox_preferences {
            grid-column: 1 / 4;
            grid-row: 3;
            width: 100%;
            max-width: none;
            margin-top: 0;

            .cookiesInfos {
                margin-top: 0;

                #cookiePref02,
                #cookiePref03 {
                    .w-checkbox-input {
                        .w-input-element {
                            + .w-input-label {
                                &:after {
                                    top: 50%;
                                }
                            }

                            &:checked {
                                +.w-input-label {
                                    &:after {
                                        top: 50%;
                                    }
                                }
                            }
                        }
                    }

                    .icon {
                        display: inline-block;
                    }
                }
            }

            .w-checkbox-input {
                .w-input-element {
                    // For unchecked
                    & + .w-input-label {
                        &:after{
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }

                    // For checked
                    &:checked {
                        & + .w-input-label {
                            &:after {
                                top: 50%;
                                transform: translate(-13px,-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ZOOMBOX FP */
#zoomBox{
    width: 100%;
    height: 100%;
    padding: 40px 0;
    transform: none;
    top: 0;
    left: 0;
    overflow-y: auto;
    background-color: rgba(255,255,255,.95);
    box-sizing: border-box;

    @media only screen and (max-width: 1360px) {
        overflow-y: auto;
    }

    .close_pop {
        background-image: url('../svg/spritesheet.svg');
        background-repeat: no-repeat;
        background-size: 600%;
        background-position: 75% 50%;
        width: 1.75rem;
        height: 1.75rem;
        display: block;
        position: absolute;
        z-index: 5;
        top: 2.25rem;
        right: 2.25rem;

        span {
            display: none;
        }
    }

    .zoom_main_wrapper {
        display: flex;
        justify-content: flex-start;
        width: 80%;
        max-width: 1384px;
        /* height: 100%; */
        padding: 0;
        margin: 0 auto;
        position: relative;

         @media only screen and (max-width: 1160px) {
            width: 90%;
         }
        
    }

    .thumb_wrapper {
        max-width: 246px;
        height: 1545px;
        max-height: 90vh;
        position: relative;
        padding-top: 0;
        @media only screen and (max-width: 1370px) {
            max-height: 85vh;
        }

        .thumbsPrev, .thumbsNext {
            background-image: url(../svg/spritesheet.svg);
            background-size: 500%;
            background-position: 100% 0%;
            background-repeat: no-repeat;
            width: 1.75rem;
            height: 1.75rem;
            margin: auto;
            cursor: pointer;
            z-index: 5;
            bottom: 0;
            left: 0;
            right: 0;
            position: relative;
            transform: rotate(90deg);

            &.swiper-button-disabled {
                opacity: 0;
                cursor: default;
            }
        }

        .thumbsPrev {
            transform: rotate(-90deg);
            bottom: inherit;
            top: 0;
            margin-bottom: .5rem;
        }

        .thumbsNext {
            margin-top: .5rem;
        }

        &.no_swiper {
            .thumbsPrev, .thumbsNext {
                 opacity: .35;
                 cursor: default;
            }
        }
    }

    .thumbs_vis{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 246px;
        height: 1546px;
        max-height: 165vh;
        margin-right: 10px;
        position: relative;

        @media only screen and (max-width: 1370px) {
            max-height: 165vh;
            max-width: 246px;
        }

         #thumbs_scroller {
            height: 100%;
            align-items: center;
            justify-content: flex-end;
            max-height: 165vh;

            &.swiper-vertical, &.swiper-container-vertical {
                .swiper-wrapper{
                    flex-direction: column;

                    .swiper-slide {
                        text-align: center;
                        font-size: 18px;
                        background: $white;

                        /* Center slide text vertically */
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        &.has_video {
                            position: relative;
                            overflow: hidden;
                            width: 100%;
                            padding-top: 73%;
                            background-color: rgba(0,0,0,0.05);
                            iframe {
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                                margin: auto;
                            }
                        }
                    }
                }
            }
         }

        .swiper-container{
            height: 100%;

            .swiper-wrapper{

                .swiper-slide{
                    width: 245px;
                    height: 362px;
                    opacity: 0.9;
                    cursor: pointer;

                    /*@media only screen and (max-width: 1370px) {
                        width: 100px;
                        height: 100px;
                    }*/

                    &.swiper-slide-thumb-active {
                        opacity: 1;
                        cursor: default;
                    }

                    img{
                        width: 245px;
                        height: 362px;
                        display: block;

                        @media only screen and (max-width: 1370px) {
                            /*width: 100%;
                            height: 120px;*/
                        }
                    }
                }
            }
        }
    }

    #zoom_box_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        max-width: 1110px;
        height: auto;
        /* max-height: 1315px; */
        margin: 0 32px 0 0;

        img{
            position: relative;
            height: auto;
            width: 100%;
        }

        &:hover {
            cursor: url('../svg/zoom_out.svg'), auto;
        }

        .swiper-lazy-preloader,
        .swiper-slide {
            background-color: $white;
        }
    }
}

/* TROUVER EN MAGASIN */
#availabilityInStore {
    z-index: 101;
    position: fixed;
    /*top: 0;
    bottom: 0;*/
    left: 0;
    right: 0;
    
    margin: auto;
    padding: 4vmin 3vmin;
    background-color: $white;
    padding-top: 46px;
    transform: inherit;
    width: 610px;
    height: auto;
    /* max-height: 75vh; */
    overflow: hidden;
    top: 10%;
    vertical-align:center;


    .availabilityInStoreContent {
        overflow-y: auto;
    }

    .availabilityInStoreTitle {
        font-family: $secondaryFont;
        font-size: 1.5rem;
        text-align: center;
    }

    .availabilityInStoreDescription {
        margin: 2rem 0 1rem;
        line-height: 1.2;
    }

    #storeListForm {
        .formWrapper {
            .country_select {
                width: 20%;

                select {
                    font-size: 16px;
                    font-family: $secondaryFont;
                    font-style: normal;
                }
            }
        }
    }

    .store_locator {
        height: 90%;

        .formWrapper {
            display: flex;
            align-items: flex-end;
            margin-bottom: 2rem;
        }

        #paysIso {
            cursor: pointer;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            padding: .5rem .5rem .5rem 0;
        }
    
        
        .field_address {
            display: flex;

            input {
                width: 100%;
                padding: 2% 0;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 16px;
                font-family: $secondaryFont;
                font-style: normal;
            }

            button {
                padding-left: 1rem;
                background-color: transparent;
                font-size: 16px;
                font-family: $secondaryFont;
                font-style: normal;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                cursor: pointer;

                span {
                    font-size: 16px;
                    font-family: $secondaryFont;
                    font-style: normal;
                }

                &::after {
                    content: "";
                    display: block;
                    width: .75rem;
                    height: .75rem;
                    margin-left: .5rem;
                    background-image: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    background-size: 500%;
                    background-position: 100% 0%;
                    /* transform: rotate(-180deg); */
                }
            }
        }

        #paysIso, 
        .field_address {
            border-bottom: 1px solid $grey;
            width: 80%;
        }

        #paysIso, 
        .field_address input::placeholder {
            color: $black;
            font-size: 16px;
            font-family: $secondaryFont;
            font-style: normal;
        }
    }

    .liste_recherche_distributeur {
        .liste_distributeur {
            height: auto;
            max-height: 330px;
            overflow: auto;

            .liste_distributeur_scrollbar {
                display: block;
                height: 100%; 
                width: 98%;
            }

            .elem_liste_distributeur {
                border-bottom: 1px solid $black;

                &:not(:first-of-type) {
                    margin-top: 2rem;
                }

                &:last-of-type {
                    border-bottom: 0;
                }

                li {
                    line-height: 1.2;
                }
            }
        }

        .elem_list_contents {
            margin-bottom: 1rem;
            padding-right: 1rem;
        }

        .store_name {
            span.distance {
                color: $greyDarker;
                display: contents;
            }

            small {
                display: none;
            }
        }

        .store_address {
            >span {
                display: block;

                >span:nth-of-type(2) {
                    display: contents;
                }
            }
        }

        .store_phone {
            margin-top: .5rem;
        }

        .store_open_hours {
            display: block !important;
            margin-bottom: 1rem;
        }

        .store_name_wrapper{
            display: flex;
            justify-content: space-between;
            .storeStock {
                position: relative;
                &:before {
                    content: "";
                    line-height: 0;
                    left: -15px;
                    display: inline-block;
                    height: 9px;
                    width: 9px;
                    border-radius: 50%;
                    margin-right: 10px;
                    align-self: center;
                }

                &.high:before {
                    background-color: $green;
                }

                &.medium:before {
                    background-color: orange;
                }

                &.low:before {
                    background-color: red;
                }
            }
        }

        .link_store_wrapper {
            display: flex;
            justify-content: space-between;

            .show_schedule, .choice_store {
                text-transform: uppercase;
                font-weight: 400;
                cursor: pointer;
            }

            .choice_store {
                height: fit-content;
                font-size: .75rem;
            }

            .show_schedule { 
                font-size: .75rem;
                width: fit-content;
                &:after {
                    content: "";
                    width: .75rem;
                    height: .75rem;
                    margin-left: 5px;
                    display: inline-flex;
                    background: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    background-size: 550%;
                    background-position: 100% 0%;
                    z-index: 5;
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: all ease-in-out .35s;
                    position: relative;
                    top: 3px;
                }

                &.actif:after {
                    transform: rotate(-90deg);
                }
            }
        }

        .store_week_schedule {
            display: none;
            margin-top: 0.3rem;

            &.actif {
                display: block;
            }
        }
    }
    .show_schedule, .choice_store, .see_map, .modify {
        border-bottom: 1px solid $black;
        line-height: initial;
    }

    #availabilityInStoreForm {
       
        .w-form-line:nth-child(10) {
            position: relative;
            z-index: 5;
        }
        
        #availability_form_wrapper {
            height: 625px;
            padding-bottom: 4vmin;
            padding-right: 10px;

            .w-contact-form {
                padding: 0 2px;
                overflow: hidden;

                .w-checkbox-input,
                .w-radio-input {
                    .w-input-element {
                        &:checked {
                            & + .w-input-label {
                                &::before {
                                    box-shadow: inset 0 0 0 2px $white;
                                }
                            }
                        }
                    }

                }

            }

            .w-radio-group {
                margin-left: 1rem;
            }

            label:not(.w-has-error) {
                input, textarea {
                    box-shadow: unset;
                }
            }

            input, textarea {
                border-radius: 0;
                padding-right: 0 !important;

                &:focus, &:focus-visible {
                    border-color: $grey;
                    outline: none;
                }
            }

            textarea {
                padding-top: 2.5rem !important;

                +.w-input-label {
                    display: block;
                    width: calc(100% - 2rem);
                    /* position: relative; */
                    background: $white;
                    padding-top: 1rem;
                    transform: translateY(3%);
                }
            }

            .formulaire {
                .choose {
                    display: flex;
                    align-items: center;
                    margin-top: .7rem;
    
                    &.civility {
                        margin-bottom: 1rem;

                        .w-radio-group {
                            margin-left: 0;
                        }
                    }
                }
    
                .checkbox-container {
                    margin-bottom: 2rem;
    
                    &:first-of-type {
                        display: flex;
                        align-items: center;
                        .contact_bloc {
                            margin-top: 0;
                        }
                    }
                }
            }
            
            .choice_optin_news {
                display: flex;
                .choose:nth-of-type(2) {
                    margin-left: 2rem;
                }
            }

            .w-submit {
                margin: .5rem auto 1.5rem;
                width: 100%;
                max-width: 250px;
                height: 40px;

                .w-btn-wrapper {
                    .w-submit-button {
                        width: 100%;
                        background-color: $ViolentViolet;
                        height: 40px;
                        border-radius: 10px;
                        text-align: center;
                        cursor: pointer;

                        span {
                            font-family: $primaryFont;
                            font-size: 15px;
                            text-transform: uppercase;
                            font-weight: 200;
                            color: $white;
                        }

                        &.w-loader {
                            background: $WhiteRock url(../svg/three_dots_black.svg) center no-repeat;
                            background-size: auto 25%;
                        }

                        &:hover {
                            background-color: $WhiteRock;
                            border: none;
                            span {
                                color: $black;
                            }
                        }
                    }
                }
            }

            #wrapper_optin_partners {
                width: 98%;
                .w-radio-group {
                    margin-left: 0;
                }
            }
        }

        .info_product_container, .info_product_wrapper {
            display: flex;
            align-items: center;
        }

        .info_product_container {
            border: .5px solid rgba(0,0,0,.75);
            padding: .5rem 1rem;
            margin-bottom: 1rem;
            width: 100%;

            .info_store_wrapper, .info_product_wrapper {

                p {
                    font-weight: 300;
                    line-height: 1.1;

                    &#store_details span {
                        display: block;
                    }
                }
            }

            .info_store_wrapper {
                padding-right: 1.5rem;
                width: 35%;
            }
            
            .info_product_wrapper {
                width: 65%;
                border-left: 2px solid $borderColor;

                img {
                    margin: 0 1rem 0 1.5rem;
                    max-width: 125px;
                    height: auto;
                }
                .product_price > span:nth-child(2) {
                    text-decoration: line-through;
                    margin-left: .3rem;
                }
            }

            .modify {
                margin-top: 1rem;
            }
        }

        .os-content {
            padding: 0 1rem;
        }

        .step_back {
            position: absolute;
            top: 1.25rem;
            left: auto;
            width: 3rem;
            height: 3rem;
            background-image: url('../svg/arrow_up.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            transform: rotate(-90deg);
            font-size: 0;
        }

        .w-input.w-tel-input {
            .selected-flag {
                background-color: transparent;
                border: none;
                border-right: 1px solid $grey;
            }
        }
    }

    .close_pop, .step_back, .modify {
        cursor: pointer;
    }

    .close_pop {
        font-size: 0;
        background-image: url('../svg/spritesheet.svg');
        background-repeat: no-repeat;
        background-size: 600%;
        background-position: 75% 50%;
        width: 16px;
        height: 16px;
        display: block;
        margin: .5rem;
    }

    .see_map, .modify {
        font-weight: 600;
        font-size: .75rem;
        width: fit-content;
        line-height: 1;
    }

    .btn_container button {
        text-transform: initial;
    }

    #availabilityInStoreConfirmation {
        height: auto;

        .availabilityInStoreDescription {
            border: 1px solid $borderColor;
            padding: 1rem;
            font-weight: 300;
        }

        .btn_container {
            margin: auto;
            padding: 0 1rem;
        }
        
    }

    .bgSprite {
        height: auto;

        &.bfr:before {
            content: none;
        }
    }
}
#cartSizeInfoLightbox {
    width: 658px;
    padding-top: 25px;
    height: 260px;
    margin: auto;

    .close_pop {
        right: 25px !important;
    }

    .content {
        margin: 5px 30px 0 30px;

        .title {
            font-family: $secondaryFont;
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 1.8px;
            margin-bottom: 15px;
            font-weight: 600;
        }

        p {
            text-align: center;
            font-size: 15px;
            letter-spacing: 0.4px;
            line-height: 20px;
            font-family: $primaryFont;
        }

        p:nth-child(3) {
            margin-top: 20px;
        }

        .form_submit {
            position: relative;
            display: flex;
            justify-content: center;

            .button.dark {
                width: auto;
                color: $white;
                background-color: $ViolentViolet;
                padding: 0 16px;
                margin-top: 20px;
            }

            .loader {
                position: absolute;
                left: calc(50% - 245px/2);
                top: 28px;
                width: 36%;
                height: 100%;
                background-color: $white;
                background-size: 25% !important;
            }
        }
    }
}

body.en {
    #cartSizeInfoLightbox {
        height: 260px !important;
    }
}
body.in_tunnel {
    #site_head_wrap .main_menu,
    #site_head_wrap #top_wrapper .header .block_top_links,
    #site_head_wrap #header_mention_id,
    .itm_back_btn {
        display: none !important;
    }

    &.bandeauDelivery {
        padding-top: 144px;
    }

    #site_head_wrap #top_wrapper .header {
        justify-content: center;
    }

    #site_head_wrap #top_wrapper {
        padding: 95px 0 30px;
        background-color: $white;

        @media screen and (max-width: 1400px) {
            padding: 70px 0 30px;
        }
    }

    .tunnel_track_wrapper {
        margin-bottom: 5.9rem;
    }

    &.step_2_0 {
        .tunnel_track_wrapper {
            margin-bottom: 4.2rem;
        }

        .w-input-group .w-group-label {
            margin-bottom: 0.8rem;
        }

        .wrapper_panier .right_side.elem_tunnel .main_table {
            margin-top: 1.3rem;
            padding-bottom: 2rem;
        }

        .wrapper_panier {
            display: block;

            .wrapper_mes_adresses .form_submit {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .wrapper_panier .right_side.elem_tunnel .main_table {
        margin-top: 0;
    }

    &.body_login  {
        .tunnel_track_pusher {
            display: none;
        }

        .newlogin_page .login_wrapper {
            margin: 0;
            min-height: initial;
            position: relative;
            top: 1.5rem;
            padding: 0 0 0 7.5rem;
        }

        .wrapper_tunnel .newlogin_page .w-newlogin-form .w-form-line.w-submit {
            max-width: 350px;
        }

        .wrapper_tunnel .newlogin_page .wrapper_bandeau_connexion {
            max-height: 705px;
            align-self: center;

            @media screen and (max-width: 1024px) {
                align-self: baseline;
            }
        }
    }

    #site_head_wrap #top_wrapper .header .wrap_trigger_menu {
        display: none !important;
    }

    &.step_2 {
        .text_lien_cgv {
            display: block !important;
            font-family: $tertiaryFont;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-top: 28px;
            padding-right: 4%;
            color: $black;

            a {
                border-bottom: 1px solid $black;
            }
        }
    }
}

.tunnel_track_pusher {
    display: flex;
    flex-direction: column-reverse;
    width: 1440px;
    max-width: 100%;
    margin: .8rem auto 0;

    @media screen and (max-width: 1500px) {
        padding: 0 1rem;
    }

    .tunnel_track_wrapper {
        width: 680px;
        max-width: 100%;
        margin: 0 auto;

        .tunnel_track {
            display: flex;
            justify-content: space-between;

            .tunnel_step {
                font-family: $secondaryFont;
                font-size: 1.3rem;
                line-height: 1;
                text-transform: uppercase;

                &.on {
                    font-weight: 600;
                    border-bottom: 1px solid $primaryColor;
                }
            }
        }
    }

    .itm_back_btn {
        font-family: $primaryFont;
        font-size: .9375rem;
        line-height: 1;
        margin: 1.6rem 0;

        &::before {
            content: "<";
        }
    }
}

.wrapper_panier {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
}

.wrapper_mes_adresses .edit_adresse,
.wrapper_panier {
    display: flex;
    justify-content: space-between;

    .elem_tunnel {
        &.left_side {
            width: 63%;
            @media screen and (max-width: 1500px) {
                padding-left: 1rem;
            }

            body.step_2 & {
                width: 62%;
            }
        }

        &.right_side {
            width: 30%;

            @media screen and (max-width: 1500px) {
                padding-right: 0.7rem;
            }
        }
    }

    .bank_left_side {
        width: 62%;

        @media screen and (max-width: 1500px) {
            padding-left: 1rem;
        }
    }
}

.cart{
    .wrapper_mes_adresses{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 3.75rem);
        max-width: 1440px;
        margin: 0 auto;

        .edit_adresse{
            width: 100%;
        }

        .conteneur_adresse{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;

            .adresse{
                display: flex;
                justify-content: flex-start;
                width: 100%;
                max-width: 295px;
                margin: 0 29px 30px 0;

                & > div{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    border: solid 1px $primaryColor;
                    padding: 18px 29px 18px;

                    .addr_label{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        font-family: $secondaryFont;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 16px;
                        color: $primaryColor;
                        text-transform: uppercase;
                        margin-bottom: 14px;

                        .addr_type{
                            margin-left: 4px;
                        }
                    }

                    .infos{
                        p{
                            font-family: $primaryFont;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: normal;
                            color: $primaryColor;
                        }
                    }

                    .modlinks_wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        margin-top: 13px;

                        .modlink {
                            font-family: $primaryFont;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 16px;
                            color: $primaryColor;
                            text-decoration: underline;
                            text-underline-offset: 2px;

                            &:not(:last-child){
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }

        .form_submit{
            max-width: 268px;
            margin-top: 26px;
        }
    }
}

.wrapper_panier {
    #cart_slider_wrapper {
        display: none;
    }

    .wrapper_form_adresse {
        .w-input.w-tel-input .selected-flag {
            border-top: none;
            border-bottom: none;
            border-left: none;
            background-color: transparent;
        }

        .w-checkbox-input .w-input-element+.w-input-label,
        .w-radio-input .w-input-element+.w-input-label {
            color: $primaryColor;
            font-size: .875rem;
        }

        .w-group-label {
            font-family: $secondaryFont;
            font-size: 1.875rem;
            line-height: 1;
            text-transform: uppercase;
        }
    }

    .choix_type_adresse {
        display: flex;
        margin-bottom: 2.5rem;

        .type_adresse {
            font-family: $secondaryFont;
            font-size: 1.25rem;
            line-height: 1;
            text-transform: uppercase;
            padding: .8rem 3rem;
            text-align: center;
            border-bottom: 1px solid transparent;
            color: rgba($primaryColor, .5);

            @media screen and (max-width: 1024px) {
                padding: .8rem 2rem;
            }

            &.actif {
                border-bottom-color: $greyForm;
                color: $primaryColor;
            }
        }
    }
}


.wrapper_panier {

    .w-newuser-form .w-checkbox-input .w-input-element+.w-input-label::after {
        top: 4px;
    }
    .w-newuser-form {
        .phone_countries {
            font-weight: 400;
            font-size: 14px;
            color: $gray;
            line-height: 15px;
            margin-top: 0.2rem;
        }
    }

    .product_quantity_change {
        .load_qte {
            max-width: 20px;
        }

        .qteWrapper {
            font-size: 1.0625rem;
            margin-left: 0.5rem;

            .change_qte {
                border: none;
                background-color: transparent;
                cursor: pointer;
                font-size: inherit;
                padding: 0;
            }

            .selected_qte {
                width: 15px;
                font-family: $primaryFont;
                font-size: inherit;
                border: none;
                text-align: center;
            }
        }
    }


    .cart_main_title {
        font-family: $secondaryFont;
        font-size: 1.875rem;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        &.wishlist {
            border-top: 1px solid $primaryColor;
            padding-top: 2.6rem;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }

        .nb_products {
            font-family: $primaryFont;
            font-size: 1.0625rem;
            line-height: 1;
            text-transform: none;
            margin-left: .5rem;
        }
    }

    .precoBasket {
        display: none;
    }

    .cart_main_table {
        display: flex;
        flex-wrap: wrap;

        .dropdown_cart {
            margin-bottom: 1.5rem;
            width: 100%;

            h3 {
                font-family: $primaryFont;
                font-size: 1.0625rem;
                line-height: 1;
                font-weight: 500;
                margin: 0 0 0.6rem;
            }

            .precoSubtitle {
                display: none;
            }

            .precoDeliveryDate {
                font-size: 1.0625rem;
                line-height: 1;
            }
        }

        &.wishlist {
            .cart_product_line {
                .cart_product_modify {
                    margin-top: 1.4rem;
                }
            }
        }
    }

    .cart_product_line {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        max-width: 328px;
        margin-bottom: 2rem;
        position: relative;
        margin-right: 5.3rem;

        .dragOverlay {
            display: none;
        }

        .eclat_rond {
            display: none !important;
        }

        .cart_product_pic {
            width: 90px;
            height: 135px;
            margin-right: 11%;
            position: relative;

            .eclat_rect {
                position: absolute;
                top: 0;
                left: 0;

                img {
                    width: 50%;
                    height: auto;
                }
            }
        }

        .cart_product_desc {
            width: calc(89% - 90px);
            padding-right: 1.5rem;

            .wrap_titre_prix {
                margin-bottom: .35rem;

                .product_mod {
                    max-width: 90%;
                    display: inline-block;
                    line-height: 1;
                }
            }

            .cart_product_brand {
                display: none;
            }

            .cart_product_title,
            .cart_product_subtitle {
                font-size: 1.25rem;
                line-height: 1;
                display: inline;
            }

            .cart_product_title {
                font-weight: 600;
            }

            .cart_product_subtitle {
                text-transform: lowercase;
            }

            .wrapper_cart_product_price {
                display: flex;
                align-items: flex-end;
                margin-bottom: 1rem;

                .pricetag {
                    font-size: 1.25rem;
                    line-height: 1;

                    & + .pricetag {
                        margin-left: 1rem;
                        font-size: 1rem;
                        padding-bottom: 1px;
                    }
                }

                .eclat_privilege,
                .eclat_rond {
                    position: absolute;
                    left: 40px;
                    top: 102px;
                    max-width: 45px;
                    display: block !important;
                }
            }

            .wrapper_cart_product_desc {
                display: flex;
                flex-direction: column;

                .line_product_desc_preorder {
                    order: 5;
                    display: none;
                }
            }

            .cart_product_sizecol {
                font-size: 1.0625rem;
                line-height: 1;
                margin-bottom: 0.3rem;

                &.color {
                    order: 2;
                }

                &.size {
                    order: 3;
                }

                &.quantity {
                    display: flex;
                    align-items: center;
                    order: 4;
                }
            }

            .cart_product_nostock {
                .product_nostock_label {
                    font-weight: 600;
                }
            }
        }

        .cart_product_modify {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            margin-top: .4rem;

            a {
                font-size: 1.0625rem;
                line-height: 1;
                display: block;
                margin-bottom: .7rem;

                &.button {
                    height: auto;
                    background-color: transparent;
                    width: auto;
                    border: none;
                    color: $primaryColor;
                    text-transform: none;
                    border-radius: 0;

                    span {
                        color: inherit;
                        display: inline;
                    }

                    &:focus {
                        background-color: transparent !important;
                        color: $primaryColor !important;

                        span {
                            color: $primaryColor !important;
                        }
                    }
                }

                span {
                    border-bottom: 1px solid $primaryColor;
                    display: inline-block;
                }
            }

            .product_del,
            .remove_wish {
                position: absolute;
                top: -0.35rem;
                right: 0;

                &:after {
                    content: "";
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(14,5,5);
                    background-color: $primaryColor;
                    width: 18px;
                    height: 18px;
                    display: block;
                }

                span {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    border: 0;
                }
            }
        }
    }

    .line_ssTotal {
        display: none;
    }

    .right_side.elem_tunnel {
        .main_table {
            background-color: $offWhite2;
            padding: 2rem 2.5rem 0;
            margin-top: 1.6rem;

            @media screen and (max-width: 1199px) {
                padding: 2rem 1.5rem 0;
            }

            .recap_cart_title {
                font-family: $secondaryFont;
                font-size: 1.25rem;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 1.7rem;

                span {
                    font-family: $primaryFont;
                    font-size: 1.0625rem;
                    line-height: 1;
                    display: block;
                    text-transform: none;
                    margin-top: 0.2rem;
                }
            }

            .bill_line {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.5rem;

                label {
                    font-size: 1.0625rem;
                    line-height: 1;
                }

                .price .pricetag {
                    font-size: 1.0625rem;
                    line-height: 1;
                }

                &.total {
                    border-top: 1px solid $primaryColor;
                    padding-top: 1.2rem;
                    margin-top: 1.7rem;

                    label {
                        font-family: $secondaryFont;
                        font-size: 1.375rem;
                        text-transform: uppercase;
                    }

                    .price .pricetag {
                        font-size: 1.375rem;
                    }
                }

                &.toggle {
                    margin-bottom: .7rem;
                    label {
                        cursor: pointer;
                        border-bottom: 1px solid $primaryColor;

                        @media screen and (max-width: 1199px) {
                            max-width: 120px;
                            border-bottom: none;
                            text-decoration: underline;
                            text-underline-position: under;
                        }

                        &:after {
                            content: "-";
                            display: inline-block;
                            margin-left: .5rem;
                        }
                    }

                    &.open label {
                        &:after {
                            content: "+";
                        }
                    }
                }

                &.coupon {
                    margin-top: 1.7rem;
                    margin-bottom: 1rem;

                    .w-coupon-form .w-form-line {
                        .w-input-element:not([type=radio]):not([type=checkbox]), input[type=text].w-input-element {
                            border-radius: 0;
                            background-color: $white;
                            border-color: $white;
                            font-size: .875rem;
                        }

                        .w-input-label {
                            color: $disabledColor;
                        }

                        &.w-submit .w-submit-button {
                            border-radius: 0;
                            background-color: $white;
                            border-color: $white;
                            color: $primaryColor;
                            font-family: $tertiaryFont;
                            font-weight: 600;
                            font-size: .875rem;

                            span {
                                color: inherit;
                            }
                        }

                        .w-input-note{
                            margin-top: 0;
                        }
                    }

                    &:has(#formPromo[style="display: none;"]) {
                        margin: 0;
                    }
                }

                &.used_cp {
                    margin-top: 1rem;
                    .code_promo {
                        font-size: 1.0625rem;
                        line-height: 1;
                        text-transform: uppercase;
                        font-family: $secondaryFont;
                        display: inline-flex;
                        align-items: center;

                        span {
                            font-size: inherit;
                        }

                        .close {
                            font-size: 0;
                            width: 15px;
                            height: 15px;
                            @include maskIconPath('../svg/spritesheet.svg');
                            @include maskIconCoord(14,5,5);
                            background-color: $primaryColor;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                }

                &.discount {
                    .error_text {
                        display: none;
                    }
                }

                .cart_product_line {
                    width: 100%;
                    max-width: 100%;
                    margin-top: .5rem;
                    margin-bottom: 0;
                    margin-right: 0;

                    &:last-child {
                        margin-bottom: 1rem;
                    }

                    .cart_product_pic {
                        width: 75px;
                        margin-right: 8%;
                    }

                    .cart_product_modify,
                    .product_quantity_change {
                        display: none;
                    }

                    .cart_product_desc {
                        .wrap_titre_prix {
                            margin-bottom: 0;
                        }

                        .cart_product_title,
                        .cart_product_subtitle {
                            font-size: 1rem;
                        }

                        .wrapper_cart_product_desc {
                            margin-top: 0.5rem;
                        }
                    }

                    .wrapper_cart_product_price {
                        margin-bottom: 0.7rem;

                        .pricetag {
                            font-size: .9rem;
                        }
                    }

                    .cart_product_sizecol {
                        font-size: .9rem;

                        label {
                            font-size: .9rem;
                        }

                        &.size {
                            margin-bottom: 0.5rem;
                        }
                    }
                }

                &.total_produit_dropdown {
                    flex-direction: column;
                    max-height: 300px;
                    overflow-y: auto;
                }
            }

            .upselling.price {
                margin-top: 1.5rem;
                box-sizing: border-box;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    height: 1.5rem;
                    width: 100%;
                    background-color: $offWhite2;
                    position: absolute;
                    top: -1.5rem;
                    left: 0;
                }


                progress {
                    width: 100%;
                    appearance: none;
                    height: 6px;
                    vertical-align: top;
                    margin-top: 0.5rem;
                    background-color: $white;
                    border: 1px solid rgba($black, 0.5);
                    border-radius: 10px;
                    overflow: hidden;
                }

                progress::-webkit-progress-bar {
                    background-color: $white;
                }

                progress::-webkit-progress-value {
                    background-color: $primaryColor;
                }

                progress::-moz-progress-bar {
                    background-color: $primaryColor;
                }
            }

            .formPanier_container  {
                margin-top: 1.2rem;

                #btn_cmd_valid,
                .loader {
                    height: 50px;
                    border: none;
                }
            }
        }

        .paye_par_3_fois {
            display: none;
            background-color: $offWhite2;
            text-align: center;
            padding: 1.7rem 0 1.3rem;

            a {
                display: none;
            }

            span {
                font-weight: bold;
            }
        }

        .encart_3xcb_panier {
            background-color: $offWhite2;
            text-align: center;
            padding: 1.7rem 0 1.3rem;

            a {
                display: none;
            }

            span {
                font-weight: bold;
            }
        }
    }

    .bloc_question {
        margin-top: 1.8rem;

        .faq_tunnel_title {
            font-family: $secondaryFont;
            font-size: 1.25rem;
            line-height: 1;
            text-transform: uppercase;
            margin-bottom: 0.8rem;
            font-weight: bold;
        }

        #faqTunnelList {
            li {
                display: block;
                margin-bottom: .5rem;

                .faq_tunnel_question {
                    font-family: $primaryFont;
                    font-size: 1rem;
                    line-height: 1.1;
                    cursor: pointer;

                    &.actif {
                        font-weight: 700;
                    }
                }

                .faq_tunnel_answer {
                    font-family: $primaryFont;
                    font-size: .9375rem;
                    line-height: 1;
                    margin-top: 1rem;
                    display: none;
                    padding-left: .5rem;
                }

                &:last-child {
                    margin-top: 1.5rem;

                    h3 {
                        font-family: $secondaryFont;
                        font-size: 1.25rem;
                        line-height: 1;
                        text-transform: uppercase;
                        margin-bottom: 0.7rem;
                    }
                }

                &:last-child {
                    h3 {
                        margin-bottom: .7rem;
                        font-weight: bold;
                    }

                    p {
                        span {
                            display: block;
                            line-height: 1.2;
                            font-size: 1.0625rem;
                        }
                    }
                }

            }
        }
    }

    .bloc_livraison {
        border: 1px solid $borderColor;
        padding: 0 2.2rem 2rem 2.5rem;
        margin: 0 0 3rem;
        position: relative;

        &.active {
            border-color: $primaryColor;
            .checkbox {
                border-color: $primaryColor;
                &:after {
                    opacity: 1;
                }
            }
        }

        input[type="radio"] {
            position: absolute;
            z-index: -1;
            visibility: hidden;
            opacity: 0;
        }

        .checkbox {
            width: 14px;
            height: 14px;
            border: 1px solid $borderColor;
            border-radius: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -7px;
            margin: auto 0;
            background-color: $white;

            &:after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background-color: $primaryColor;
                border-radius: 50%;
                opacity: 0;
            }
        }

        .title_type_livraison {
            font-family: $secondaryFont;
            font-size: 1.5625rem;
            line-height: 1;
            text-transform: uppercase;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            top: -.9rem;
            display: inline-block;
            background-color: $white;
            padding: 0 3rem;
        }

        .transporteur_info {
            font-size: 0.9375rem;
            line-height: 1;
            text-align: center;
            margin: -0.7rem 0 1.8rem;
        }

        .show_modif_info {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $primaryColor;
            height: auto;
            width: auto;
            border-radius: 0;
            color: $primaryColor;
            text-transform: none;
            display: inline;
            font-weight: 400;

            span {
                color: inherit;
                display: inline;
            }
        }

        .choix_delivery {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .civilite,
            .shop_name {
                font-family: $secondaryFont;
                font-size: .875rem;
                line-height: 1;
                text-transform: uppercase;
                margin: 0 0 0.3rem;
            }

            .adresse {
                font-size: 0.9375rem;
                line-height: 1;
                margin: 0 0 2rem;
                font-weight: 300;
            }

            .right_delivery {
                .price_tag {
                    font-family: $secondaryFont;
                    font-size: 1.375rem;
                    line-height: 1;
                    font-weight: 600;
                    display: block;
                    letter-spacing: -1px;
                }
            }
        }

        .fdp_offered {
            font-family: $secondaryFont;
            font-size: 1.375rem;
            line-height: 1;
            text-transform: uppercase;
            color: $secondaryColor;
        }

        .transporteur_delay {
            font-size: 0.9375rem;
            line-height: 1;
            font-weight: 300;
            margin: .5rem 0 0;
        }

        .warning_point_relais {
            color: $red;
        }
    }

    .wrapper_locate_relais {
        .delivery_form_title.title_choix_relais {
            margin: 1rem 0 .5rem;
        }

        .displayed_result {
            margin: 1rem 0 0;
            display: flex;
            justify-content: space-between;

            #map_canvas {
                width: 75%;
                height: 25rem;
                margin-bottom: 1rem;

                .info_popup {
                    font-family: $secondaryFont;
                    .name {
                        text-transform: uppercase;
                        font-weight: 400;
                        font-family: $secondaryFont;
                        letter-spacing: 0;
                        font-size: 1.15rem;
                    }

                    .indication {
                        display: none;
                    }

                    a.button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                        font-size: 10px;
                        letter-spacing: .04rem;
                        margin-top: 1rem;
                        padding: 0 1rem;
                    }
                }
            }

            .sidebar-right {
                width: 25%;
                padding-left: 1.5rem;
                box-sizing: border-box;
                height: 25rem;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .lb-wrap {
                    -ms-overflow-style: none;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }

            .kp {
                .name {
                    font-family: $secondaryFont;
                    font-size: .975rem;
                    line-height: 1.1;
                    text-transform: uppercase;
                    margin: 0.5rem 0 0.5rem;

                    .kpname {
                        font-weight: 700;
                    }
                }

                .address,
                .distance {
                    font-size: 0.9375rem;
                    line-height: 1;
                    font-weight: 300;
                }

                .address {
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                .relayLink {
                    border-bottom: 1px solid $primaryColor;
                    font-weight: 400;
                    font-size: .875rem;
                    line-height: 1;
                }

                .distance {
                    display: none;
                }
            }
        }
    }
}

.gondole{
    width: 1440px;
    max-width: 96%;
    margin: 2.6rem auto 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#cart_slider_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 915px;
    opacity: 0;
    margin: 0;

    .full_w_sldr_title {
        font-family: $secondaryFont;
        font-size: 2.5rem;
        line-height: 1;
        text-transform: uppercase;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        width: 100%;

        &:before {
            content: "";
            width: 60%;
            height: 1px;
            background-color: $primaryColor;
            display: inline-block;
        }

        span{
            display: none;
        }
    }


    .swiper-slide {
        margin-right: 30px;

        .imgWrapper {
            .block_lnk {
                .association_prod_images_list {
                    position: relative;
                    li {
                        position: relative;

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }

                        &.holder_association_video {
                            .video_ctn {
                                position: relative;
                                overflow: hidden;
                                width: 100%;
                                padding-top: 147.5%;
                                background-color: rgba(0,0,0,0.05);

                                iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    width: 100%;
                                    height: 100%;
                                    margin: auto;
                                }
                            }
                        }

                        & + li {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            opacity: 0;
                            transition: opacity 0.5s ease-in-out;
                        }
                    }
                }

                &:hover {
                    .association_prod_images_list {
                        li {
                            & + li {
                                z-index: 2;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .blockInfosProd {
            .wrapper_description {
                .item_title {
                    display: block;
                    width: calc(100% - 1rem);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    span {
                        font-family: $primaryFont;
                        font-size: 17px;
                        font-weight: 600;
                        color: $black;
                        line-height: 1;

                        & + .item_subtitle {
                            font-weight: 200;
                        }
                    }
                }
            }

            .item_price {
                display: flex;
                align-items: flex-end;

                .pricetag {
                    font-family: $secondaryFont;
                    font-size: 15px;
                    color: $black;

                    & + .pricetag {
                        font-size: 14px;
                        margin-left: .25rem;
                        padding-bottom: 0.5px;
                        text-decoration: line-through;
                    }
                }
            }

            .wrap_rolloverproduit {
                min-height: 3.5rem;
                form {
                    .rollover_left {
                        &.achat_express {
                            .ligne_form {
                                &.productSizeFieldset {
                                    .size_list {
                                        .choices_list {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .wishlistBtn {
                                top: 9px;
                                right: 7px;
                            }
                        }
                    }
                }

                .bloc_add_color {
                    margin-top: -2px;
                    top: 93%;
                }
            }
        }

        &:hover {
            .blockInfosProd {
                .wrap_rolloverproduit {
                    opacity: 1;
                    z-index: 5;
                }
            }
        }
    }

    /*.item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &:nth-of-type(5) {
            margin-right: 0;
        }

        .gondolePictures{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 0;
            padding-bottom: calc((630 / 428) * 100%);
            position: relative;

            .block_lnk{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 1;
                z-index: 2;
                transition: opacity 0.3s ease-in-out;

                img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }

                &.hoverImg{
                    opacity: 0;
                    z-index: 1;
                }
            }

            &:hover{
                .block_lnk{
                    opacity: 0;
                    z-index: 1;

                    &.hoverImg{
                        opacity: 1;
                        z-index: 2;
                    }
                }
            }
        }

        .blockInfosProd{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 10px;
            background-color: $white;

            .eclat_rond {
                display: none !important;
            }

            .item_name{
                span{
                    font-family: $primaryFont;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 17px;
                    color: $primaryColor;

                    &.item_feat{
                        font-weight: 300;
                    }
                }
            }

            .description_prix_wrapper {
                min-height: 5rem;
                width: 100%;
            }
        }

        .wrap_rolloverproduit {
            .achat_express .ligne_form.productSizeFieldset .size_list .choices_list {
                width: 100%;
            }

            .bloc_add_color {
                top: calc(100% - 25px);
            }
        }

        &:hover{
            .wrap_rolloverproduit{
                z-index: 3;
            }
        }
    }*/
}

.thanksWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1500px) {
        padding: 0 1rem;
    }

    .banner {
        display: none;
    }

    .bankThanksTop {
        width: 61.8%;
        margin: 0;

        h1 {
            display: block;
            font-weight: 400;
            margin-bottom: 10px;

            &:before {
                display: none;
            }

            p {
                font-family: $secondaryFont;
                font-size: 2.5rem;
                line-height: 1.2;
                text-transform: uppercase;
                letter-spacing: 0;
            }

            span {
                letter-spacing: 0;
                strong {
                    font-family: $secondaryFont;
                    font-size: 1.25rem;
                    line-height: 1;
                    text-transform: uppercase;
                    display: inline-block;
                    text-indent: -7px;
                }

                b {
                    font-family: $primaryFont;
                    font-size: 1.0625rem;
                    line-height: 1;
                    display: inline-block;
                }
            }
        }

        #around_me_map {
            height: 524px;
        }
    }

    .thanksRecap {
        width: 29.7%;
        max-width: 100%;
        flex-direction: column-reverse;
        margin-left: 8.5%;
        justify-content: flex-end;

        .thanksLeft,
        .thanksRight {
            width: 100%;
        }

        .thanksRight {
            text-align: left;
            margin: 0 0 2rem;

            .thanksRightWrapper {
                padding: 2rem;
                margin-top: 30px;
                background-color: $offWhite2;
            }

            p {
                margin: 0 0 .3rem;
                font-size: 1.0625rem;
                line-height: 1;

                label {
                    font-family: $secondaryFont;

                    &:after {
                        margin: 0 3px;
                    }
                }

                span {
                    font-weight: 300;
                }

                &.txt_thanks_transaction {
                    text-transform: none;
                    font-weight: 300;
                    margin-top: 2rem;
                }
            }
        }

        .thanksLeft {
            .thanksLeftWrapper {
                display: flex;
                flex-direction: column;
            }

            .seeOrderEvo {
                margin-bottom: 1.1rem;
                line-height: 1.25rem;
            }

            .createPwdTxt {
                display: none;
            }

            #clientForm {
                margin: 0 0 1.5rem;
            }

            .thanksTrust {
                order: 1;
                text-transform: uppercase;
                font-family: $secondaryFont;
                font-size: 0.875rem;
                line-height: 1;
                margin-top: 32px;
            }

            .bankThanksBtn {
                max-width: 350px;
                height: 50px;
            }
        }
    }

    #around_me_map {
        .gm-style .gm-style-iw-c {
            border-radius: 0;
            box-shadow: none;
        }

        .gm-style .gm-style-iw-d {
            padding: 2.5rem 3rem 2.5rem 2.5rem;
            background: url("../img/maps/marker.png") no-repeat 90% 90%;
        }

        .gm-style .gm-style-iw-t {
            bottom: 5px !important;
            &::after {
                display: none;
            }
        }
    }

    .infoWindowContent {
        max-width: 145px;
        font-size: 1.125rem;
        line-height: 1.2;
        font-family: $primaryFont;

        strong {
            font-size: 1.125rem;
            line-height: 1;
            font-family: $secondaryFont;
            margin: 0 0 1rem;
        }

        .adresse {
            font-size: 1.125rem;
            line-height: 1.2;
            font-family: $primaryFont;

            .city {
                text-transform: uppercase;
            }
        }
    }
}

body.checkout {

    #bank_div {
        .displayNone {
            display: none;
        }

        .errorBank {
            display: block;
            margin-bottom: 1rem;
        }
    }

    #outsite_ue_notice {
        display: flex;
        justify-content: center;
        margin-bottom: 3vh;
        font-weight: 700;
    }

    form#hipay-3-form {
        padding: 0 0 1rem 0;

        .saveAlias {
            display: flex;
            align-items: center;

            label {
                margin-left: 5px;
            }
        }

        button.button.use {
            background-color: $ViolentViolet;
            color: $white;
            border: 1px solid $white;
            cursor: pointer;

            &:hover {
                background-color: $WhiteRock;
                color: $black;
            }
        }

        .hipay-loader {
            background: $WhiteRock url(../svg/three_dots_black.svg) no-repeat center;
            border: 1px solid $white;
            cursor: pointer;
            width: 240px;
            height: 40px;
            outline: 0;
            padding: 0;
            border-radius: 10px;
            box-sizing: border-box;
            background-size: 15%;
        }
    }

    .main_table {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2.5rem 0;
    }

    .wrapper_panier .right_side.elem_tunnel .main_table .bill_line.toggle {
        margin-bottom: 0.9rem;
    }

    .wrapper_panier .right_side.elem_tunnel .main_table .bill_line.total {
        margin-top: 0.9rem;
    }

    .wrapper_basket_content {
        .wrapper_bloc_banque {
            border: 1px solid $borderColor;
            padding: 0 2.5rem 2.5rem;
            margin-bottom: 3rem;
            position: relative;

            &.actif {
                border-color: $primaryColor;

                .radio {
                    border-color: $primaryColor;

                    &:after {
                        background-color: $primaryColor;
                    }
                }
            }

            .radio {
                width: 14px;
                height: 14px;
                position: absolute;
                left: -7px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                border: 1px solid $borderColor;
                border-radius: 50%;
                background-color: $white;

                &:after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }
            }

            .bank_title {
                font-family: $secondaryFont;
                font-size: 1.5625rem;
                line-height: 1;
                text-transform: uppercase;
                text-align: center;
                margin: -.8rem 0 2rem;

                span {
                    padding: 0 4rem;
                    background-color: $white;
                }
            }

            .bank_subtitle {
                font-family: $primaryFont;
                font-size: 0.9375rem;
                line-height: 1;

                strong + span {
                    width: 44px;
                    display: inline-flex;
                    align-items: center;
                }
            }

            .total_transaction {
                font-family: $secondaryFont;
                font-size: 0.875rem;
                line-height: 1;
                text-transform: uppercase;
                margin: 1.3rem 0 0.5rem;

                span {
                    font-weight: 300;
                }

                .pricetag {
                    font-weight: 600;
                }
            }

            .bloc_bank_contents {
                flex-direction: column;
            }

            &.paypal {

                .bank_title {
                    background-color: $white;
                    padding: 0 4rem;
                    margin: -0.9rem 0 2.5rem;

                    span {
                        padding: 0;
                    }

                    &:after {
                        content: "";
                        background: url("../img/paypay_logo_txt.png") no-repeat 50% 50%;
                        background-size: contain;
                        width: 78px;
                        height: 19px;
                        display: inline-block;
                        margin-left: 0.5rem;
                    }
                }

                .bank_subtitle {
                    text-align: center;

                    strong {
                        text-decoration: underline;

                        & + span {
                            display: none;
                        }
                    }
                }

                #inputPAYPAL {
                    position: absolute;
                    margin: -1px 0 0 -1px;
                    padding: 0;
                    display: block;
                    width: 1px;
                    height: 1px;
                    font-size: 1px;
                    line-height: 1px;
                    overflow: hidden;
                    clip: rect(0,0,0,0);
                    border: 0;
                    outline: 0;
                }
            }

            &.alma {
                .bank_title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &::after {
                        content: "";
                        display: block;
                        background: url('../svg/alma_logo_black.svg') no-repeat;
                        background-color: $white;
                        background-size: contain;
                        height: 20px;
                        width: 40px;
                        padding-left: 4rem;
                    }

                    span {
                        padding: 0 1rem 0 4rem;
                    }
                }

                .bank_subtitle {
                    font-family: $primaryFont;
                    font-size: .9375rem;
                    line-height: 1;
                    text-align: center;
                    color: $black;

                    strong {
                        text-decoration: underline;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        .cards-wrapper {
            img {
                width: 40px;
            }
        }

        .paye_par_3_fois {
            display: none;
        }

        .wrapper_payment {
            order: -1;
            border-bottom: 1px solid $primaryColor;
            margin: 0 0 1.8rem;

            .payment_addr_wrapper {
                margin: 0 0 2rem;
                position: relative;

                .title {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    line-height: 1;
                    text-transform: uppercase;
                    margin: 0 0 0.1rem;
                }

                .address {
                    p {
                        font-size: 1rem;
                        line-height: 1.3;

                        &:last-child{
                            width: 75%;
                        }
                    }
                }

                .address_mod {
                    font-size: .9375rem;
                    line-height: 1;
                    text-decoration: underline;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                &.billing {
                    margin-bottom: 12px;
                }
            }

            .addr_warning {
                font-size: 15px;
                font-weight: 200;
                font-family: $primaryFont;
                color: $red;
                line-height: 1;
                display: block;
                margin-bottom: 25px;
            }
        }
    }

    #wrapper_payement_secur {
        margin: 1.7rem 0 0;

        .recap_cart_title {
            font-size: 1.0625rem;
            line-height: 1;
            text-transform: uppercase;
            margin: 0 0 0.7rem;
        }

        .tunel_paiement_right_description {
            font-size: 1rem;
            line-height: 1.2;

            img {
                width: 38px;
            }
        }
    }

    #hipay_applepay_bloc {
        padding: 0.75rem;

        .title_wrapper_fold {
            margin-top: -1.2rem;

            .total_transaction {
                display: none;
            }

            .bank_title {
                padding: 0 4rem;
                margin: -1.6rem 0 2.5rem;
            }
        }

        #hipay-apple-pay-button {
            position: relative;
            height: 2.1rem;
            padding: 0;
            overflow: hidden;
            max-width: 20rem;
            margin: -0.8rem auto 1.25rem;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 5.4rem;
                height: 6.95rem;
                top: 0;
                z-index: 2;
                background-color: $white;
            }

            &:after {
                left: inherit;
                width: 5.3rem;
                right: 0;
            }

            iframe {
                position: absolute;
                top: -18px;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto 0.1rem;
                width: 100%;
                height: 2rem;
                border-bottom: 1px solid $gray;
            }
        }
    }
}

#modboxpromo {
    max-width: 600px;
    padding: 2rem;

    .texte {
        font-size: 1.1rem;
        line-height: 1;
        margin-bottom: 2rem;
        text-align: center;
    }

    #btns_panier {
        max-width: 254px;
        margin: 0 auto;
        .button {
            line-height: 40px;
        }
    }
}

/* Popup precommande */
#lightbox_preco {
    width: 772px;
    max-width: 100%;
    padding: 3rem;

    .t_lightbox_preco {
        font-size: 0.9375rem;
        line-height: 1;
        text-align: center;
        margin: 0 0 3rem;

        span {
            font-family: $secondaryFont;
            font-size: 1.25rem;
            line-height: 1;
            text-transform: uppercase;
            display: block;
            margin: 0 0 0.5rem;
        }
    }

    .blocPrecoWrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 0 2.2rem;

        .ico {
            width: 16px;
            height: 16px;
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border: 1px solid $borderColor;
            border-radius: 50%;
            background-color: $white;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }

        .bloc_lightbox_preco {
            border: 1px solid $borderColor;
            padding: 3rem 2rem;
            text-align: center;
            width: 47%;
            position: relative;
            cursor: pointer;
            opacity: .6;
            transition: opacity .3s ease-in-out;

            &.actif {
                border-color: $primaryColor;
                opacity: 1;

                .ico:after {
                    background-color: $primaryColor;
                }
            }

            p {
                font-size: .9375rem;
                line-height: 1;

                span {
                    font-family: $secondaryFont;
                    text-transform: uppercase;
                    display: block;
                    margin: 0 0 0.7rem;
                }
            }
        }

    }

    .btn_container {
        max-width: 350px;
        margin: 0 auto;
    }
}

.bankThanks{
    .tunnel_track_wrapper {
        margin-bottom: 3.9rem!important;
    }
}

body.cart {
    #lightbox_achat_express {
        .page_produit.achat_express .product_main_wrapper .product_infos .product_infos_content .product_infos_form {
            .more_details {
                display: none;
            }

            .item_infos .itm_form_group#product_addcart {
                display: block;
            }

            #addToWishlistButton {
                display: none;
            }
        }

        #product_addcart {
            .btnAddBasketWrapper {
                .form_submit {
                    button {
                        width: 100%;
                        max-width: 360px;
                        border: none;
                        padding: 0;
                        #btn_add_cart {
                            width: 100%;
                            background-color: $black;
                            height: 40px;
                            border-radius: 10px;
                            border: none;
                            color: $white;
                            text-align: center;
                            cursor: pointer;
                            display: block;
                            order: 2;
                            position: relative;
                            top: inherit;
                            right: inherit;
                            background-image: inherit;
                            font-family: $primaryFont;
                            font-size: 15px;
                            text-transform: uppercase;
                            font-weight: 200;
                            line-height: 40px;
                        }
                    }
                }
            }
        }
    }

    .errormsgadd {
        display: none;
    }

    &.step_2_0 #adresseForm {
        .errormsgadd {
            display: none;
            line-height: 1.1;
        }

        .inputErr#adresseCivilite .radio_item label::before {
            border: 1px solid $red;
        }
        
        .elem_global.bill .w-form-line.w-has-tel {
            display: flex;
            flex-direction: column;

            .w-input-note.w-input-error {
                top: 4rem;

                @media screen and (max-width: 1078px) {
                    top: 5rem;
                }
            }
        }
    }

    .w-form-line.w-has-tel .w-input-container:nth-child(2) .w-input-note.w-input-error {
        top: 92%;
    }

    &.step_2 {
        .w-form-line.w-has-tel .w-input-container:nth-child(2) {
            line-height: 1;

            .phone_countries {
                margin: 0.2rem 0;
            }
        }
        
        .left_side.elem_tunnel .wrapper_select_adr #adresseForm .elem_global.delivery ~.phone_countries {
            display: none;
        }
    }
}

#selection-offer {
    height: 460px;
    width: 815px;

    .close_pop {
        span {
            width: 15px;
            height: 15px;
            opacity: .6;
        }
    }

    .selection-offer-header {
        font-family: $secondaryFont;
        text-transform: uppercase;
        font-weight: 400;
        display: flex;
        justify-content: center;
        margin: 3.5rem 0 1.9rem;

        p {
            font-size: 1.25rem;
            line-height: 1;

            &.selection-offer-title {
                font-size: 1.25rem;
                font-weight: inherit;
                margin: 0 5px 0 0;
            }

            span#nb_prod_selection_offerte {
                font-size: inherit;
                font-weight: inherit;
            }
        }

    }

    .selection-offer-content {
        width: 88%;
        height: 240px;

        #wrap_lion {
            width: auto;
            padding-right: 1rem;

            .js-form-selection-offer {
                width: 47%;
                min-height: initial;
                margin: 0 0 2rem 1rem;
                opacity: 1;
                padding: 1rem 1.5rem;

                div[onclick^="selectionOffert"] {
                    width: 90px;
                    height: 135px;
                    margin-right: 1rem;
                    float: left;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: -5px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        background: transparent;
                        width: 2rem;
                        height: 100%;
                        z-index: 5;
                    }
                }

                &:before {
                    top: 50%;
                    left: 0;
                    border-radius: 0;
                    transform: translate(-50%, -50%);
                    background: $white;
                    width: 18px;
                    height: 18px;
                }

                &.actif:before {
                    background: $black;
                }

                &:nth-last-child(-n+2) {
                    margin-bottom: 0rem;
                }

                .color_list, .size_list {
                    padding: 0;
                    font-family: $secondaryFont;
                    font-size: .9rem;

                    .form_itm {
                        margin-right: 1rem;
                    }

                    input[type=radio]+label {
                        width: 15px;
                        height: 15px;
                    }
                }

                .prevColour, .prevSize,
                .nextColour, .nextSize {
                    display: none;
                }
            }

            p.titleOfferedProd {
                font-size: 1.2rem;
                line-height: 1;
                margin: 1rem 0 1rem;
                span {
                    display: inline;
                }
            }
        }
    }

    #errorLabel {
        height: auto;
        margin-bottom: .75rem;
    }

    .selection-offer-submit.btn_container {
        height: 50px;

        .button {
            height: 50px;
            width: 350px;
            max-width: 100%;
        }
    }
}

/* Safari only */
@media not all and (min-resolution:.001dpcm){
    @supports (-webkit-appearance:none) {
        .w-coupon-form .w-form-line.w-submit {
            top: 52%;
        }
    }
}

.lb-wrap {
    position: relative;
    z-index: 0;
    overflow: auto;
}
.lb-v-scrollbar {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 7px;
    height: 100%;
    background: $borderColor;
    border-radius: 7px;
    opacity: 0.3;
    z-index: 1;
}

.lb-v-scrollbar-slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    height: 50px;
    background: $black;
    border-radius: 7px;
}

.product_no_sfs_warning {
    width: 90%;
    padding: 1rem;
    color: $black;
    border: 1px solid $black;
    margin: 20px 0 0 0;
}
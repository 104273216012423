.home-module{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    &:not(:last-child){
        margin-bottom: 94px;

        @media screen and (max-width: 1024px){
            margin-bottom: 98px;
        }
    }

    /*START INACTIVE*/
    &.images_signup_module{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 23px;

        .home-columns-container{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 96%;
            max-width: 1680px;
            margin: 0 auto;

            .visualSide{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 61.6%;
                margin-right: 4.2%;

                img{
                    width: 100%;
                }
            }

            .home-module-contents{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 34.2%;
                margin-top: 11.5%;

                .home-module-titles{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                    margin-bottom: 20px;

                    .home-module-title{
                        font-family: $secondaryFont;
                        font-weight: 400;
                        font-size: 40px;
                        line-height: 40px;
                        color: $primaryColor;
                        text-transform: uppercase;
                        margin-bottom: 9px;

                        @media screen and (max-width: 1495px){
                            font-size: 35px;
                            line-height: 35px;
                        }

                        @media screen and (max-width: 1310px){
                            font-size: 30px;
                            line-height: 30px;
                        }

                        @media screen and (max-width: 1125px){
                            font-size: 28px;
                            line-height: 28px;
                        }

                        @media screen and (max-width: 1050px){
                            font-size: 26px;
                            line-height: 26px;
                        }
                    }

                    .home-module-subtitle,
                    .home-module-pretitle{
                        font-family: $secondaryFont;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 20px;
                        color: $primaryColor;
                        text-transform: uppercase;

                        @media screen and (max-width: 1495px){
                            font-size: 18px;
                            line-height: 18px;
                        }

                        @media screen and (max-width: 1310px){
                            font-size: 15px;
                            line-height: 15px;
                        }

                        @media screen and (max-width: 1050px){
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }
                }

                .home-module-text{
                    font-weight: 300;
                    font-size: 16px;
                    line-height: normal;
                    color: $primaryColor;

                    @media screen and (max-width: 1495px){
                        font-size: 15px;
                    }

                    @media screen and (max-width: 1050px){
                        font-size: 14px;
                    }
                }

                .w-newsletter-form{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                    margin-top: 22px;
                    overflow-x: hidden;

                    .w-form-line{
                        &:first-of-type{
                            margin-bottom: 2rem;
                        }

                        .w-input-element{
                            border-radius: 0;
                        }

                        &.w-submit{
                            position: relative;
                            top: auto;
                            right: auto;
                            width: 100%;
                            max-width: 333px;
                            height: 40px;

                            .w-submit-button{
                                width: 100%;
                                max-width: 333px;
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    /*END INACTIVE*/

    /*START COVER*/
    &.cover-module{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        #super_home_swiper{
            display: flex;
            width: 100%;

            .swiper-wrapper{
                display: flex;
                width: 100%;

                .swiper-slide{
                    display: flex;
                    width: 100%;

                    .home-column{
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        width: 100%;
                        position: relative;
                        overflow: hidden;

                        .slide_video {
                            display: block;
                            position: relative;
                            padding-bottom: calc((900 / 1920) * 100%);
                            width: 100%;
                             height: 0;

                            iframe {
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: -1;
                                width: 100% !important;
                                height: 100% !important;
                            }

                            &:has(:not(.block_redirect)) {
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .block_redirect {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                            top: 0;
                            left: 0;
                        }

                        .home-module-bg-lnk{
                            display: flex;

                            .home-module-bg{
                                display: flex;
                            }
                        }

                        .home-module-contents{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            position: absolute;
                            right: 24.4%;
                            top: 40.875%;
                            z-index: 1;

                            @media screen and (max-width: 1024px){
                                right: 34%;
                                top: 45.5%;
                            }

                            .home-module-title{
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 50px;
                                line-height: 50px;
                                color: $primaryColor;
                                text-transform: uppercase;
                            }

                            .home-module-subtitle{
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 25px;
                                line-height: 25px;
                                color: $primaryColor;
                                text-transform: uppercase;
                                margin-left: auto;
                                margin-right: 0;
                            }

                            .home-module-cta{
                                font-weight: 300;
                                font-size: 16px;
                                color: $primaryColor;
                                text-decoration: underline;
                                text-underline-offset: 2px;
                                margin-left: auto;
                                margin-right: 3.25%;
                                margin-top: 4%;

                                @media screen and (max-width: 1024px){
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .swiper-pagination{
                bottom: 17px;

                .swiper-pagination-bullet{
                    width: 31px;
                    height: 2px;
                    border-radius: 0;
                    margin: 0 7.5px;

                    &.swiper-pagination-bullet-active{
                        background: $primaryColor;
                    }
                }
            }
        }

        &:not(:first-of-type) {
            height: auto;

            #super_home_swiper {
                .swiper-wrapper {
                    .swiper-slide {
                        .home-column {
                            .home-module-bg-lnk {
                                width: 100%;

                                .home-module-bg {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*END COVER*/

    /*START FIVE CATEGORIES*/
    &.five-categories{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .home-module-body{
            display: flex;
            width: 100%;

            .home-columns-container{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 96%;
                max-width: 1680px;
                margin: 0 auto;

                @media screen and (max-width: 1024px){
                    max-width: 945px;
                }

                .home-column{
                    display: flex;

                    &:not(:last-child){
                        margin-right: 6.25%;

                        @media screen and (max-width: 1024px){
                            margin-right: 10.26%;
                        }
                    }

                    .home-module-title{
                        display: none; /*PAS SUR LA MAQUETTE*/
                    }

                    .home-module-cta{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 300;
                        font-size: 30px;
                        line-height: 30px;
                        color: $primaryColor;
                        text-transform: uppercase;

                        h2{
                            font-family: $primaryFont;
                            font-weight: 300;
                            font-size: 30px;
                            line-height: 30px;
                            color: $primaryColor;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
    /*END FIVE CATEGORIES*/

    /*START TWO IMAGES*/
    &.two-images{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .home-module-body{
            display: flex;
            width: 100%;

            .home-columns-container{
                display: flex;
                width: 70.5%;
                margin: 0 auto;

                @media screen and (max-width: 1024px){
                    width: 95.75%;
                    max-width: 960px;
                }

                .home-column{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48.8442%;

                    &:first-of-type{
                        margin-right: 2.3%;
                    }

                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .holder_content{
                        display: none;

                        & + iframe{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    /*END TWO IMAGES*/

    /*START GONDOLE*/
    &.gondole-module{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96%;
        max-width: 1024px;
        margin: 0 auto 89px;

        @media screen and (max-width: 1024px){
            max-width: 960px;
        }

        .home-columns-container{
            display: flex;
            width: 100%;

            .home-column{
                display: flex;
                flex-direction: column;
                width: 55.37%;
                margin-top: 42px;

                .home-module-cta{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .wrap_title{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-top: 91px;

                    @media screen and (max-width: 1024px){
                        margin-top: 84px;
                    }

                    .home-module-title{
                        margin: 0 70px;

                        .lnk_title{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;

                            .title{
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 50px;
                                line-height: 40px;
                                color: $primaryColor;
                                text-transform: uppercase;
                                display: block;
                            }
                        }
                    }

                    .swiper-button-next,
                    .swiper-button-prev{
                        display: flex;
                        background: url('../svg/spritesheet.svg');
                        background-size: 1000%;
                        background-position: 93% 0%;
                        width: 9px;
                        height: 17px;
                        position: relative;
                        top: auto;
                        right: auto;
                        left: auto;
                        bottom: auto;
                        margin-top: 0;
                    }

                    .swiper-button-prev{
                        transform: rotate(180deg);
                    }
                }
            }

            .swiperTg{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 41.8%;
                margin-left: 2.6%;

                .swiper-wrapper{
                    .item{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        width: 100%;

                        .gondolePictures{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 0;
                            padding-bottom: calc((630 / 428) * 100%);
                            position: relative;

                            .block_lnk{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 1;
                                z-index: 2;
                                transition: opacity 0.3s ease-in-out;

                                img{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 100%;
                                }

                                &.hoverImg{
                                    opacity: 0;
                                    z-index: 1;
                                }
                            }

                            &.hover_img{
                                &:hover{
                                    .block_lnk{
                                        opacity: 0;
                                        z-index: 1;

                                        &.hoverImg{
                                            opacity: 1;
                                            z-index: 2;
                                        }
                                    }
                                }
                            }

                            .ecalt_rect_place, .eclat_rect {
                                img {
                                    width: 50%;
                                    height: auto;
                                }
                            }
                        }

                        .blockInfosProd{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            margin-top: 5px;
                            width: 100%;

                            .item_name{
                                span{
                                    font-family: $primaryFont;
                                    font-weight: 600;
                                    font-size: 17px;
                                    line-height: 17px;
                                    color: $primaryColor;

                                    &.item_feat{
                                        font-weight: 300;
                                    }
                                }
                            }
                        }

                        &:hover{
                            .wrap_rolloverproduit{
                                z-index: 3;
                            }
                        }
                    }
                }
            }
        }
    }
    /*END GONDOLE*/

    /*NEW GONDOLE*/
    &.tg-module {
        width: 96%;
        max-width: 1440px;
        margin: 0 auto 5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .home-columns-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            svg {
                color: red;
            }

            .home-module-title-container {
                display: inline-flex;
                flex-direction: revert;
                justify-content: flex-start;
                margin-bottom: 1.5rem;
                justify-content: center;
                text-align: center;

                .home-module-title {
                    font-family: $secondaryFont;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 30px;
                    color: #000;
                    text-transform: uppercase;
                    margin-right: 30px;
                    letter-spacing: 1px;
                }

                .home-module-subtitle {
                    font-family: $secondaryFont;
                    font-weight: 300;
                    font-size: 30px;
                    line-height: 37px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            #moduleSwiper {
                width: 100%;
                margin: 0 auto;

                .swiper-wrapper {

                    &.wrapper-centered {
                        justify-content: center;

                        .item:last-child {
                            margin-right: 0 !important;
                        }
                    }

                    .item {
                        max-width: 263px;
                        width: 100%;
                        
                        .gondolePictures {
                            margin-bottom: 0.5rem;
                            position: relative;

                            .block_lnk {
                                width: 100%;
                                position: relative;

                                .ill_img {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: center;
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: calc((387 / 263) * 100%);
                                    position: relative;
                                    overflow: hidden;

                                    .imgProd {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                    }
                                }

                                + .block_lnk {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 0;
                                    opacity: 0;
                                    width: 100%;
                                }
                            }

                            &:hover {
                                .block_lnk {
                                    + .block_lnk {
                                        opacity: 1;
                                    }
                                }
                            }

                        }

                        // Au changement de couleur
                        .imgWrapper {
                            margin-bottom: 0.5rem;
                            position: relative;

                            .productVisualMulti {
                                .productBigPictures {

                                    .block_lnk {
                                        width: 100%;
                                        position: relative;

                                        .ill_img {
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: center;
                                            width: 100%;
                                            height: 0;
                                            padding-bottom: calc((387 / 263) * 100%);
                                            position: relative;
                                            overflow: hidden;

                                            .imgProd {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                            }

                                            + .ill_img {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                z-index: 0;
                                                opacity: 0;
                                                width: 100%;
                                            }
                                        }                                       
                                    }

                                    &:hover {
                                        .ill_img {
                                            + .ill_img {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .blockInfosProd {
                            position: relative;

                            .wrapper_description {
                               
                                .item_name, .item_title {
                                    display: flex;
                                    width: 100%;
                                    overflow: hidden;

                                    span {
                                        font-size: 17px;
                                        font-family: $primaryFont;
                                        font-weight: 700;

                                        &:not(:last-child) {
                                            text-transform: uppercase;
                                        }
                                    }

                                    .item_feat, .item_subtitle {
                                        font-weight: 200;
                                        padding-left: 0.35rem;
                                        display: block;

                                        &::first-letter {
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }

                            .eclat_gondole_home {
                                &.eclat_rond {
                                    /*valeur pour ajuster la marge sur firefox*/
                                    margin-top: 1.17rem;
                                }
                            } 
                        }

                        // relative positioning of blockInfosProd
                        // moves wishlistBtn at bottom of FP
                        .wrap_rolloverproduit {
                            .achat_express {
                                .wishlistBtn {
                                    transform: translateY(-395px);

                                    @media screen and (max-width: 1510px){
                                        transform: translateY(-26vw);
                                    }
                                }
                            }
                        }  
                    }
                }
            }
        }

        .tgPrev, .tgNext {
            width: 30px;
            height: 20px;
            fill: $black;
            z-index: 1;

            &.swiper-button-disabled {
                fill: $grey !important;
                color: $grey !important;
            }
        }

        .tgPrev {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
            left: -20px !important;
        }

        .tgNext {
            right: -20px !important;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
        }
    }
    /*NEW GONDOLE END*/

    /*START INSTA*/
    &.social-module{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96%;
        max-width: 1440px;
        margin: 0 auto;

        @media screen and (max-width: 1024px){
            max-width: 960px;
        }

        .home-module-body{
            width: 100%;

            .home-columns-container{
                width: 100%;

                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .home-column{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: flex-start;
                        width: 100%;

                        .text_content{
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            margin-bottom: 23px;

                            .home-module-subtitle{
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 40px;
                                line-height: 30px;
                                color: $primaryColor;
                                text-transform: uppercase;
                                margin-right: 12px;

                                span{
                                    display: block;
                                }
                            }

                            .home-module-title{
                                font-family: $primaryFont;
                                font-weight: 300;
                                font-size: 30px;
                                line-height: 18px;
                                text-transform: uppercase;
                            }
                        }

                        .content{
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            width: 100%;
                            .wrap_image,
                            .wrap_video{
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                width: 100%;
                                height: 0;
                                padding-bottom: calc((305 / 1440) * 100%);
                                position: relative;
                                overflow: hidden;

                                img,
                                video{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                }

                                &:not(:last-child){
                                    margin-right: 2%;
                                }
                            }

                            .wrap_video video {
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
    /*END INSTA*/
}

// fix product description on TG module on homepage
body.homepage {
    .home-module.tg-module #moduleSwiper .blockInfosProd .description_prix_wrapper {
        width: 100%;
    }
}
@import '../../_app/_src/scss/desktop/mixins/typeFace';

$opensans: (
    normal: (light, regular, semibold, bold, extrabold)
);

@include typeFace('OpenSans', $opensans);

/* Fonts called in views/main.php (Google) */
$primaryFont: 'Rokkitt', sans-serif;
$secondaryFont: 'pt-sans-pro-narrow', sans-serif;
$tertiaryFont: Arial, sans-serif;
$quaternaryFont: 'PT Sans Narrow', sans-serif;
$quinaryFont: 'OpenSans', sans-serif;
.item {
    .eclat_rect {
        display: inline-block;
        left: 9px;
        top: 10px;
        position: absolute;
        z-index: 3;

        img {
            width: 50%;
            height: auto;
        }
    }

    .blockInfosProd {
        .description_prix_wrapper {
            background-color: $white;
            position: absolute;
            z-index: 3;
            min-height: 3.5rem;
            width: 100%;
            padding-top: 10px;
            width: calc(100% - 60px);

            .wrapper_description {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .eclat_rond {
            display: inline-block;
            position: absolute;
            z-index: 3;
            right: 9px;
            margin-top: 12px;

            img {
                width: auto;
                height: auto;
                max-height: 30px;
            }
        }
    }

    .wrap_rolloverproduit {
        background-color: $white;
        width: 100%;
        padding-top: 10px;

        .achat_express {
            display: flex;
            flex-direction: column-reverse;

            .wishlistBtn {
                width: 1.5rem;
                height: 1.5rem;
                background: url('../svg/spritesheet.svg');
                background-repeat: no-repeat;
                background-size: 500%;
                background-position: 75% 0%;
                display: block;
                position: absolute;
                top: 12px;
                right: 10px;
                cursor: pointer;
                z-index: 5;
                opacity: 1;

                span {
                    font-size: 0;
                }
            }

            .ligne_form {
                opacity: 0;

                .prod_listes {
                    .titre_choices_list {
                        display: none;
                    }
                }

                /* COLOR */
                &.productColorFieldset {
                    display: block !important;
                    box-sizing: border-box;
                    padding-left: 2px;

                    .prod_listes {
                        .choices_list {
                            display: flex;
                            align-items: center;

                            .form_itm {
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: 10px;

                                input[type=radio] {
                                    display: none;

                                    &+label {
                                        display: block;
                                        background-size: cover !important;
                                        border-radius: 100%;
                                        width: 14px;
                                        height: 14px;
                                        cursor: pointer;
                                        position: relative;
                                        border: 1px solid $white;
                                        box-sizing: border-box;

                                        &:before {
                                            content: '';
                                            position: absolute;
                                            width: 14px;
                                            height: 14px;
                                            border-radius: 100%;
                                            position: absolute;
                                            top: -2px;
                                            left: -2px;
                                            margin: auto;
                                            border: 1px solid rgba(0, 0, 0, 0.075);

                                        }
                                    }

                                    &:checked+label {
                                        &:before {
                                            border-color: $black;
                                        }
                                    }
                                }

                                .color_bullet {
                                    width: 1.4375rem;
                                    height: 1.4375rem;
                                    margin-right: 10px;
                                    border-radius: 100%;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }

                /* SIZE */
                &.productSizeFieldset {
                    display: block !important;

                    .size_list {
                        .choices_list {
                            display: flex;
                            flex-wrap: wrap;
                            width: 70%;

                            .form_itm.size {
                                position: relative;
                                width: auto;
                                height: auto;
                                text-align: center;
                                margin-right: 20px;
                                margin-bottom: 6px;
                                font-size: 16px;
                                line-height: 1;

                                input {
                                    display: none;

                                    &+label {
                                        font-size: 16px;
                                        margin: auto;
                                        width: auto;
                                        height: auto;
                                        line-height: 1;
                                        display: inline-flex;
                                        justify-content: center;
                                        text-align: center;
                                        color: $primaryColor;
                                        font-weight: 500;
                                        cursor: pointer;
                                        font-family: $secondaryFont;

                                        span {
                                            text-align: center;
                                        }
                                    }

                                    &:checked {
                                        &+label {
                                            font-weight: 900;

                                            &:before {
                                                background-color: transparent;
                                                border: 1px solid rgba(0, 0, 0, .65);
                                            }

                                            &:after {
                                                display: none;
                                            }
                                        }
                                    }

                                    &.disabled {
                                        &+label {
                                            color: $disabledColor;
                                            position: relative;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                height: 90%;
                                                width: 1px;
                                                background-color: $disabledColor;
                                                min-width: 1px;
                                                border-radius: 0;
                                                top: 0;
                                                bottom: 0;
                                                left: .35rem;
                                                margin: auto;
                                                transform: rotate(50deg);
                                            }
                                        }

                                        &:checked {
                                            &+label {
                                                color: $black;

                                                &:before {
                                                    border: none;
                                                    background-color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .productErrorFieldset {
                        text-align: left;
                        color: red;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 200;
                        font-style: italic;
                    }
                }
            }
        }

        .form_submit,
        .bloc_add_color {
            display: inline-block;
            width: auto;
            height: auto;
            position: absolute;
            top: calc(100% - 71px);
            right: 0;
            opacity: 0;

            .load_product {
                display: none;
            }

            .button,
            button {
                height: auto;
                border: none;
                border-bottom: 1px solid $black;
                border-radius: 0;
                background-color: transparent;
                display: inline-block;
                width: auto;
                text-transform: inherit;
                line-height: 1;

                &:hover,
                &:active,
                &:focus {
                    background-color: $white !important;
                    color: $black !important;
                }

                span {
                    font-size: 16px;
                    font-family: $primaryFont;
                    font-weight: 100;
                    color: $black !important;
                    width: auto;
                    display: inline;
                }

                &.loader {
                    opacity: 0;
                    z-index: -1;
                    border: none;
                    background-color: $white;
                    width: 100%;

                    span {
                        background: $white url(../svg/three_dots.svg) no-repeat center center;
                        font-size: 0;
                        width: 100%;
                        height: 10px;
                        display: inline-block;
                    }

                    &.loading {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }

        /* ALERTE STOCK */
        .expressAlertStock {
            background-color: $white;
            position: relative;
            z-index: 4;

            .alert_stock {
                display: flex;
                width: 90%;
                background: $white;

                span {
                    display: flex;
                    width: 100%;
                    text-align: left;
                }
            }

            .bloc_add_alert_form {
                padding-top: 1.5rem;

                .w-form-line {
                    .w-input-container {
                        .w-text-input {
                            input.w-input-element {
                                height: 40px;
                                border: 1px solid $black;
                                border-radius: 10px;
                                background-color: $white;

                                &.inputErr {
                                    border-color: red;
                                }

                                &:-webkit-autofill,
                                &:-webkit-autofill:hover,
                                &:-webkit-autofill:focus,
                                &:-webkit-autofill:active {
                                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                                }

                            }
                        }
                    }

                    .form_submit {
                        top: calc(100% - 39px);
                        right: 1px;

                        button {
                            padding: 0;
                            border: none;
                            background-color: $white;
                            height: 38px;
                            width: 40px;
                            border-radius: 10px;
                            cursor: pointer;

                            span {
                                font-size: 0;
                                position: relative;
                                display: inline-block;
                                height: 38px;
                                width: 40px;

                                &:before {
                                    content: "OK";
                                    height: 38px;
                                    width: 40px;
                                    line-height: 38px;
                                    text-align: center;
                                    display: inline-block;
                                    position: relative;
                                    color: $black;
                                    font-size: 13px;
                                    font-family: $tertiaryFont;
                                }
                            }
                        }
                    }
                }

                .form_submit {
                    opacity: 1;
                }
            }

            .alert_return {
                position: absolute;
                right: 3px;
                top: 3px;
                height: 1rem;
                width: 1rem;
                display: block;
                background: url('../svg/spritesheet.svg');
                background-repeat: no-repeat;
                background-size: 500%;
                background-position: 75% 75%;
                z-index: 2;

                span {
                    display: none;
                }
            }

            .bloc_add_alert_confirmation {
                position: relative;
                z-index: 2;
                background-color: $white;
                padding-top: .8rem;
            }
        }
    }

    &:hover {
        .blockInfosProd {

            .eclat_rond,
            .description_prix_wrapper {
                z-index: -1;
                opacity: 0;
            }
        }

        .wrap_rolloverproduit,
        .bloc_add_color,
        .form_submit {
            opacity: 1;
            z-index: 3;

            .ligne_form {
                opacity: 1;
            }
        }
    }
}

#abox.lightbox {
    background-color: $white;
    width: 467px;
    box-sizing: border-box;
    padding: 45px 50px;

    .header_lightbox {
        .txt_alert {
            .light_title {
                font-size: 30px;
                font-family: $secondaryFont;
                color: $black;
                line-height: 33px;
                margin-bottom: 1rem;
                text-transform: uppercase;
            }

            p {
                font-family: $primaryFont;
                font-size: 1.065rem;
                line-height: 1;
                margin-bottom: 1rem;
            }

            .wrapper_btn {
                .button {
                    display: block;
                    line-height: 40px;
                    border: none;
                    background-color: $ViolentViolet;
                    color: $white;

                    &:hover {
                        background-color: $WhiteRock;
                        color: $black;
                    }
                }
            }
        }
    }
}

/* Home */
.home-module {
    .item {
        .wrap_rolloverproduit {
            padding-top: 3px;

            .bloc_add_color,
            .form_submit {
                top: 34%;
            }
        }

        .blockInfosProd {
            .description_prix_wrapper {
                padding-top: 0;
            }

            .eclat_rond {
                margin-top: inherit;
            }
        }
    }
}
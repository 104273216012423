#copyright{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1920px;
    min-width: 990px;
    margin: 80px auto 0;

    .reassurance{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 150px;
        border-top: solid 1px $black;
        border-bottom: solid 1px $black;

        @media screen and (max-width: 1024px){
            height: 139px;
        }

        .content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 96%;
            max-width: 1285px;
            column-gap: 10.4%;

            @media screen and (max-width: 1060px){
                column-gap: 8.8%;
            }

            @media screen and (max-width: 1024px){
                max-width: 960;
            }

            .icon_reassurance {
                margin-bottom: 10px;
                width: 40px;
                height: auto;
            }

            .reassu_item{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span,
                h2{
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;

                    @media screen and (max-width: 1024px){
                        line-height: 24px;
                    }
                }

                .reassurance_title {
                    font-family: $primaryFont;
                    font-weight: 300;
                    color: $primaryColor;
                    margin-bottom: 3px;
                }
            }

            .contact_email {
                font-family: $primaryFont;
                font-weight: 300;
                font-size: 20px;
                line-height: 20px;
                text-align: center;
                display: block;
            }
        }
    }

    .footer_content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 96%;
        max-width: 1440px;
        margin: 35px auto 0;

        @media screen and (max-width: 1024px){
            flex-wrap: wrap;
            max-width: 880px;
            margin: 38px auto 0;
        }

        .footer_seo{
            display: flex;
            width: 48.75%;
            max-width: 630px;

            @media screen and (max-width: 1024px){
                width: 100%;
                max-width: none;
                order: 4;
                margin-top: 48px;
            }

            .SEO{
                display: flex;
                justify-content: space-between;
                width: 100%;

                @media screen and (max-width: 1024px){
                    max-width: 770px;
                }

                .col{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .col_title{
                        font-family: $secondaryFont;
                        font-weight: 400;
                        font-size: 15px;
                        color: $primaryColor;
                        text-transform: uppercase;
                        margin-bottom: 9px;
                    }

                    .col_content{
                        display: flex;
                        flex-direction: column;

                        .col_links{
                            font-size: 17px;
                            line-height: 17px;

                            &:not(:last-child){
                                margin-bottom: 13px;
                            }
                        }
                    }
                }
            }
        }

        .socialWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .title{
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 15px;
                color: $primaryColor;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .wrap_icones{
                display: flex;
                align-items: center;
                justify-content: center;

                .social_network{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    background-size: 555%;
                    background-position: 0% 0%;
                    width: 22px;
                    height: 22px;

                    &.ig{
                        background-position: 0% 25%;
                    }

                    &.fb{
                        background-position: 25% 25%;
                        margin: 0 15px;
                    }

                    &.pin{
                        background-position: 50% 25%;
                    }
                }
            }
        }

        .arbre_footer{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 0;

            @media screen and (max-width: 1024px){
                margin: 0;
            }
        }

        .bloc_inscription{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 22.6%;
            overflow-x: hidden;

            @media screen and (max-width: 1024px){
                width: 326px;
            }

            .inscription_title{
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 30px;
                line-height: 30px;
                color: $primaryColor;
                text-transform: uppercase;
            }

            .w-newsletter-form{
                width: 100%;
                margin-top: 10px;

                .w-form-line{
                    margin-bottom: 19px;

                    .w-email-input{
                        .w-input-element{
                            height: 30px;
                            padding-left: 0;
                            border-radius: 0;
                            border: 0 none;
                            border-bottom: 1px solid $borderColor;

                            &:focus{
                                box-shadow: none;
                            }

                            & + .w-input-label{
                                left: 0;
                            }
                        }

                        &.w-nonempty{
                            .w-input-element{
                                padding-bottom: 14px;
                            }
                            .w-input-label{
                                transform: translateY(-150%);
                            }
                        }

                        &.w-has-error{
                            .w-input-element{
                                border-bottom: solid 1px $alert;
                                box-shadow: none;
                            }
                        }
                    }

                    .w-input-note{
                        margin-top: 0.15em;
                    }

                    &.w-submit{
                        position: relative;
                        top: auto;
                        right: auto;
                        width: 100%;
                        max-width: 100%;
                        height: 50px;
                        margin-bottom: 0;

                        .w-submit-button{
                            background-color: $ViolentViolet;
                            color: $white;
                            width: 100%;

                            span{
                                color: $white;
                            }

                            &:hover{
                                background-color: $WhiteRock;
                                color: $black;

                                span{
                                    // color: $white;
                                    color: $black;
                                }
                            }

                            &.w-loader{
                                &.loading{
                                    background-size: 15%;
                                    background-color: $WhiteRock;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright_mention{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 15px;
        line-height: 12px;
        margin-bottom: 27px;

        @media screen and (max-width: 1024px){
            margin: 50px 0;
        }

        .sep{
            margin: 0 10px;
        }

        #tarification_load {
            display: flex;
            justify-content:center;

            .custom-select-footer{
                position: relative;

                .select-selected{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 12px;
                    text-transform: none;
                    text-decoration: none;
                }

                .select-items {
                    position: absolute;
                    background-color: $white;
                    border: solid 1px $borderColorLight;
                    top: auto;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                    z-index: 99;
                    width: 150px;
                    max-height: 200px;
                    overflow: auto;

                    .select-options{
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        text-transform: none;
                        text-decoration: none;
                        padding-left: 11px;

                        &:hover {
                            background-color: rgba(0, 0 , 0 ,0.10);
                        }

                        &.same-as-selected {
                            background-color: rgba(0, 0 , 0 ,0.20);
                        }
                    }
                }
            }
        }

        .footer_lang_switch{
            position: relative;
            text-transform: uppercase;

            .lang_switch{
                cursor: default;
            }

            .langRollover{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                position: absolute;
                left: 0;
                bottom: 75%;
                opacity: 0;
            }

            &:hover{
                .langRollover{
                    opacity: 1;
                }
            }
        }
    }
}

body.category {
    #back_to_top {
        display: flex!important;
        background: url('../svg/spritesheet.svg');
        background-repeat: no-repeat;
        background-size: 650%;
        background-position: 27% 93%;
        width: 54px;
        height: 17px;
        position: fixed;
        bottom: 70px;
        right: 50px;
        padding: 0;
        border: 0 none;
        transform: rotate(-90deg);
        appearance: none;
        cursor: pointer;
        z-index: 5;

        &:focus {
            outline: none;
        }
    }
}

/* The container must be positioned relative: */
.custom-select {
    position: relative;
    font-weight: 400;
    font-size: 15px;
    line-height: 12px;
    text-transform: none;
    text-decoration: none;
}

.custom-select select {
    display: none; /* hide original SELECT element: */
}

.select-selected {
    background-color: transparent;
    padding-right: 20px;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    display: block;
    background: url('../svg/spritesheet.svg');
    background-size: 700%;
    background-position: 95% 0%;
    background-repeat: no-repeat;
    width: 10px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    transform: rotate(90deg);
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    transform: rotate(270deg);
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: $white;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 6;
    width: 115px;
    border: solid 1px $borderColorLight;

    .select-options{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-transform: none;
        text-decoration: none;
        padding-left: 11px;
    }
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items .select-options:hover {
    background-color: rgba(0, 0, 0, 0.0275);
}

.same-as-selected {
    background-color: rgba(0, 0, 0, 0.025);
}
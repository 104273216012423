.wrapper_store {
    .store_locator_page {
        width: 1440px;
        max-width: 100%;
        margin: 0 auto 0;

        @media screen and (max-width: 1480px) {
            padding: 0 1rem;
        }

        .banner_title {
            font-family: $secondaryFont;
            font-size: 3.75rem;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            margin-bottom: 3rem;
        }

        .banner {
            height: 100%;
            max-height: 589px;
            .image-wrap {
                max-width: 100%;
                height: inherit;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .top-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .left-wrap {
            width: 27%;
        }

        .right-wrap {
            width: 71%;
            margin-left: auto;
            align-self: flex-end;
        }

        #retailers_map {
            height: 511px;

            .gm-style .gm-style-iw-c {
                border-radius: 0;
                box-shadow: none;
            }

            .gm-style .gm-style-iw-d {
                padding: .8rem 3rem .8rem 2rem;
                background: url("../img/maps/marker.png") no-repeat 90% 90%;
            }

            .gm-style .gm-style-iw-t {
                bottom: 15px !important;
                &::after {
                    display: none;
                }
            }

            .info_popup {
                max-width: 145px;
                .name {
                    font-size: 1.125rem;
                    line-height: 1;
                    font-family: $secondaryFont;
                    margin: 0 0 1rem;
                }

                .adresse {
                    font-size: 1.125rem;
                    line-height: 1.2;
                    font-family: $primaryFont;

                    .city {
                        text-transform: uppercase;
                    }
                }

                .cta_row {
                    font-family: $primaryFont;
                    font-size: 1rem;
                    line-height: 1;
                    margin-top: 0.8rem;

                    a {
                        text-decoration: underline;
                        display: block;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .desc {
            display: none;
        }

        .storeloc_form {
            margin-bottom: 2.3rem;

            .wrap_fields {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .w-input-container {
                width: 14.8%;
                max-width: 149px;
            }

            .field_address {
                width: 47.1%;
                position: relative;

                button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: none;
                    background: none;

                    &:after {
                        content: "";
                        width: 18px;
                        height: 18px;
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(3,5,4);
                        background-color: $black;
                        display: block;
                        margin-top: 11px;
                    }

                    span {
                        display: none;
                    }
                }
            }

            .btn_container {
                width: 28.7%;
                max-width: 289px;
                margin-top: 3px;
            }

            label.w-dropdown {
                border: none;
                border-bottom: 1px solid $greyForm;
                border-radius: 0;
                height: 35px;

                .w-input-label {
                    font-style: italic;
                    font-size: .75rem;
                    color: $disabledColor;
                    left: 6px;
                }

                &:after {
                    border: none;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(5,5,4);
                    background-color: $black;
                    width: 13px;
                    height: 13px;
                    transform: translateY(-50%) rotate(90deg);
                }
            }

            select.w-input-element {
                border: none;
                height: 100%;
            }

            input[type=text] {
                border: none;
                border-bottom: 1px solid $greyForm;
                width: 100%;
                height: 35px;
                padding: 0;
                font-size: .8125rem;

                &::-webkit-input-placeholder {
                    font-style: italic;
                    color: $disabledColor;
                }

                &::-moz-placeholder {
                    font-style: italic;
                    color: $disabledColor;
                }

                &:-ms-input-placeholder {
                    font-style: italic;
                    color: $disabledColor;
                }

                &:-moz-placeholder {
                    font-style: italic;
                    color: $disabledColor;
                }

            }

            .ou {
                font-size: 1rem;
                line-height: 1;
                font-family: $primaryFont;
                text-transform: uppercase;
                padding: 0 1rem;
            }
        }

        .resultat_recherche {
            font-size: 1.125rem;
            font-family: $secondaryFont;
            text-align: center;
            padding: 1.5rem 0 2rem;
            text-transform: uppercase;

            b {
                font-size: 1.25rem;
                font-family: $primaryFont;
                text-transform: capitalize;
            }
        }

        .liste_distributeur_wrapper {
            .liste_distributeur {
                .elem_liste_distributeur {
                    border-top: 1px solid $black;
                    padding: 2rem 0;

                    &:last-child {
                        border-bottom: 1px solid $black;
                    }

                    .elem_list_contents {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        font-family: $primaryFont;
                        padding-left: 4%;

                        li {
                            padding: 0 1rem;
                            box-sizing: border-box;
                        }

                        .store_name {
                            width: 20%;
                            font-size: 1.125rem;
                            line-height: 1.2;

                            .title {
                                text-transform: uppercase;
                                font-family: $secondaryFont;
                                font-weight: 400;
                            }
                        }

                        .store_details {
                            width: 40%;
                            font-size: 1.125rem;
                            line-height: 1.2;

                            .store_address {
                                span {
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .store_schedule {
                            width: 23%;
                            font-size: 1rem;
                            line-height: 1.2;

                            .txt_store_open,
                            .txt_store_close {
                                font-family: $secondaryFont;
                                color: $primaryColor;

                                &:before {
                                    content: "";
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    background-color: $primaryColor;
                                    display: inline-block;
                                    position: relative;
                                    top: -1px;
                                    margin-right: 5px;
                                }
                            }

                            .txt_store_open {
                                color: $green;

                                &:before {
                                    background-color: $green;
                                }
                            }

                            .txt_store_close {
                                color: $red;

                                &:before {
                                    background-color: $red;
                                }
                            }
                        }

                        .store_more {
                            width: 15%;
                            font-size: 1rem;
                            line-height: 1.2;
                            text-decoration: underline;

                            @media screen and (max-width: 1050px) {
                                width: 17%;
                            }

                            &:after {
                                content: "";
                                width: 14px;
                                height: 14px;
                                @include maskIconPath('../svg/spritesheet.svg');
                                @include maskIconCoord(5,5,4);
                                background-color: $greyForm;
                                display: inline-block;
                                transform: rotate(90deg);
                                margin: 0 0 0 10px;
                                position: relative;
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Detail magasin */
.store_details_page {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 0;
    width: 1680px;
    max-width: 100%;
    position: relative;

    @media screen and (max-width: 1720px) {
        padding: 0 1rem;
    }

    .title-wrap {
        width: 100%;
        .title {
            font-family: $secondaryFont;
            font-size: 2.5rem;
            line-height: 1;
            text-align: center;
            margin: 1.5rem 0 1.1rem;
        }

        .subtitle {
            font-family: $secondaryFont;
            font-size: 1.25rem;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            display: block;
        }
    }

    .btn_return {
        border: none;
        background: none;
        padding: 0;
        font-family: $primaryFont;
        font-size: .9375rem;
        line-height: 1;
        margin: .7rem 0 1rem;
    }

    .banner {
        width: 50%;
        max-height: 883px;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
            width: 40%;
            height: 900px;
            max-height: initial;
        }

        @media screen and (max-width: 1024px) {
            height: 650px;
        }
    }

    .content_wrap{
        width: 60.7%;
        position: absolute;
        right: 0;
        display: flex;
        top: 14.5rem;

        @media screen and (max-width: 1720px) {
            padding-right: 1rem;
        }

        @media screen and (max-width: 1500px) {
            width: 65%;
        }

        @media screen and (max-width: 1200px) {
            top: 12rem;
        }
    }

    .store_details_content {
        width: 50%;
        max-width: 488px;
        margin-right: 1rem;
    }

    .map-wrap {
        width: 50%;
    }

    .store_map {
        max-width: 522px;
        height: 511px;

        @media screen and (max-width: 1200px) {
            height: 450px;
        }
    }

    .descrip_avis_ctn {
        margin: 2rem 0 1rem;
        border: 1px solid $greyForm;
        padding: 1rem;
        display: none;
    }

    .product_associations_wrapper {
        display: none;
    }

    .store_details_infos {
        height: 511px;
        background-color: $offWhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1200px) {
            height: 450px;
        }

        .title {
            font-family: $secondaryFont;
            font-size: 1.25rem;
            line-height: 1;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    }

    .store_details_address {
        width: 250px;
        max-width: 100%;
        margin: 0 auto;
        font-family: $primaryFont;
        font-size: 1.125rem;
        line-height: 1;
        text-align: center;

        .adress {
            strong {
                text-transform: uppercase;
            }
        }

        .store_schedule {
            margin-top: 1.5rem;

            .store_open,
            .store_close {
                font-family: $primaryFont;
                font-size: 1rem;

                .txt_store_open,
                .txt_store_close {
                    font-family: $secondaryFont;

                    &::before {
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 3px;
                        position: relative;
                        top: -2px;
                    }
                }

                
            }

            .txt_store_open {
                color: $green;

                &:before {
                    background-color: $green;
                }
            }

            .txt_store_close {
                color: $red;

                &:before {
                    background-color: $red;
                }
            }

        }

        .store_details_desc {
            margin-top: 1.5rem;
            display: none;
        }
    }

    .store_details_schedule {
        width: 185px;
        max-width: 100%;
        margin: 1.2rem auto 0;

        .time_table {
            li {
                display: flex;
                justify-content: space-between;
                line-height: 1;
                margin-bottom: 2px;

                p {
                    width: 60%;

                    &.day {
                        width: 40%;
                    }

                    &:not(.day) {
                        text-align: right;
                    }
                }
            }
        }
    }

    .store_details_actions {
        margin-top: 5.2rem;

        @media screen and (max-width: 1200px) {
            margin-top: 2rem;
        }

        @media screen and (max-width: 1024px) {
            margin-top: 1rem;
        }

        .btn_container {
            width: 240px;
            max-width: 100%;
            margin: 1rem 2rem 0 auto;

            @media screen and (max-width: 1024px) {
                margin: 1rem 0 0 auto;
            }

            &:nth-of-type(1) {
                .button {
                    background-color: $ViolentViolet;

                    a {
                        color: $white;
                    }

                    &:hover,
                    &:focus {
                        background-color: $WhiteRock;

                        a {
                            color: $black;
                        }
                    }
                }
            }

            &:last-of-type {
                margin-top: 1.5rem;
                .button,
                .button:focus {
                    background-color: $white !important;
                    color: $primaryColor !important;
                    border: 1px solid #000 !important;
                }
            }

            #go_to_store {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}


#ligthbox_send_store_information {
    width: 100%;
    max-width: 600px;
    padding: 2rem;

    .close_pop {
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(19,5,5);
        background-color: $primaryColor;
        width: 20px;
        height: 20px;
    }

    .title {
        font-family: $secondaryFont;
        font-size: 1.5rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    form {
        p {
            text-align: center;
            margin: 0 0 1rem;
        }
    }

    .w-submit  {
        margin: 0 auto;
    }
}
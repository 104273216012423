/* GLOBAL STYLES */
body {
    background-color: $white;
    font-family: $primaryFont;
    font-size: 16px;
    min-height: 100vh;
    z-index: 30;
    box-sizing: border-box;
    width: 100%;
    max-width: 1920px;
    min-width: 768px;
    margin: auto;
    padding-top: 84px;
    color: $primaryColor;

    @media screen and (max-width: 1360px){
        &.reponsive_menu_open{
            padding-top: 124.5px;
        }
    }

    &.homepage{
        padding-top: 0;

        &.bandeauDelivery{
            padding-top: 40px;

            &.search_in, &.search_result_on {
                padding-top: 124px;

                &.scroll_on {
                    padding-top: 58px;
                }
            }
        }
    }

    &.bandeauDelivery{
        padding-top: 124px;

        @media screen and (max-width: 1360px){
            &.reponsive_menu_open{
                padding-top: 164.5px;
            }
        }
    }

    a {
        text-decoration: none;
        color: $primaryColor;
    }

    h1, h2, h3 {
        font-family: $secondaryFont;
    }

    img {
        width: 100%;
        height: auto;
        image-rendering: -webkit-optimize-contrast;
    }

    input {
        &:focus, &.active {
            outline: none;
        }
    }

    .ill_img > img {
        animation: none;
    }
}

div {
    box-sizing: border-box;
}

.cache{
    display: none;
}

.main_wrapper{
    min-width: 990px;
}

img {
    width: 100%;
    height: auto;

    &.img_error,
    &.valid_form {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        width: auto;
    }
}

// to fix alma widget images display on payment page and override the global img styles just above
#alma-in-page-modal-wrapper {
    img {
        width: initial;
    }
}

/* GLOBAL SHADES */
.shad {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .45);
    overflow-y: auto;

    &.actif {
        display: block;
    }

    &#shad_abox {
        z-index: 99;
    }
}

#shad_menu,
#shad_link {
    display: block !important;
    background-color: rgba($black, .5);
    opacity: 0;
    z-index: -10;
    margin-top: 40px;
    transition: opacity .3s linear, z-index 0s linear .3s, margin 0s linear .3s;

    &.sticky {
        margin-top: 0;
    }

    &.actif {
        opacity: 1;
        z-index: 8;
        transition: opacity .3s linear 0.2s, z-index 0s linear;
    }

    &.filter_on  {
        z-index: 5;
    }
}

/* BREADCRUMG */
body {
    #breadcrumbs{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;

        @media screen and (max-width: 1680px) {
            padding: 0 20px;
        }

        nav {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            position: relative;

            .breadcrumb{
                display: flex;
                align-items: center;
                justify-content: center;

                &:last-child,
                &:only-child{
                    a{
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            a,
            span {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-family: $primaryFont;
                font-weight: 300;
                font-size: 13px;
                line-height: 22px;
                color: $primaryColor;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
            }

            a {
                &:after {
                    content: '|';
                    font-family: $primaryFont;
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 22px;
                    color: $black;
                    margin: 0 12px;
                }
            }

            .current{
                cursor: default;
            }
        }
    }

}

/*  Vue.js Transitions */

.pagefade-enter-active, .pagefade-leave-active {
  transition: opacity 0.5s linear;
}

.pagefade-enter, .pagefade-leave-to {
  opacity: 0;
}
[v-cloak] {
    display:none;
}

/* IE "X" from search input field */

input::-ms-clear,
select::-ms-expand,
input::-ms-reveal  {
    display: none;
}

.swiper-lazy-preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none!important;
    background-color: $white;
    width: 100%;
    height: 100%;
    min-height: 339px;
    z-index: 10;
    animation: none;
    position: absolute;
    left: 0;
    top: 0;

    &:after {
        position: absolute;
        width: 42px;
        height: 42px;
        margin-left: -21px;
        margin-top: -21px;
        transform-origin: 50%;
    }
}
.item_price,
.wrapper_price{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
} 

.pricetag,
.price_tag {
    font-family: $secondaryFont;
    font-weight: 400;
    color: $primaryColor;
    position: relative;
    font-size: 15px;
    line-height: 15px;

    + .pricetag,
    + .price_tag{
        font-size: 13px;
        line-height: 13px;
        margin-left: 5px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $primaryColor;
            position: absolute;
            top: 50%;
            margin-top: -0.5px;
            left: 0;
        }
    }
}

.cents.no_cents{
    display: none!important;
}
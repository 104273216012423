.product_page {
    padding-top: 80px;

    &.bandeauDelivery {
        padding-top: 120px;
    }

    &.scroll_on {
        .page_produit{
            .product_main_wrapper {
                .product_infos {
                    .btn_return{
                        z-index: 1;
                    }
                }
            }
        }
    }

    .page_produit {
        width: 100%;
        max-width: 1680px;
        margin: auto;
        box-sizing: border-box;

        @media only screen and (max-width: 1720px) {
            padding: 0 1.5rem;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .product_main_wrapper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 100px;

            .product_images {
                max-width: 1180px;
                width: 70.238%;
                position: relative;

                .eclat_rect {
                    position: absolute;
                    z-index: 4;
                    top: 10px;
                    left: 51.28%;
                    opacity: 0;

                    img {
                        width: 50%;
                        height: auto;
                    }
                }

                &.only_one {
                    .eclat_rect {
                        left: 25.28%;
                    }
                }

                .eclat_rect + .swiper-container .swiper-wrapper {
                    &:has(> *:only-child) {
                        justify-content: center;
                    }
                }

                .main_fp_swiper {
                    width: 100%;
                    display: block;

                    .swiper-slide {
                        cursor: pointer;
                        max-width: 583px;
                        width: 49.4%;
                        margin-right: 12px;

                        &.has_video {
                            position: relative;
                            overflow: hidden;
                            width: 100%;
                            padding-top: 73%;
                            background-color: rgba(0,0,0,0.05);
                            // Make video slide to be at last position
                            order: 1;

                            iframe {
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                                margin: auto;
                            }
                        }

                        img {
                            display: block;
                        }
                    }

                    .main_fp_prev,
                    .main_fp_next {
                        width: 1rem;
                        height: 1rem;
                        background: url('../svg/spritesheet.svg');
                        background-repeat: no-repeat;
                        background-size: 550%;
                        background-position: 100% 0%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 1rem;
                        z-index: 8;
                        cursor: pointer;

                        &.swiper-button-disabled {
                            opacity: .35;
                            cursor: default;
                        }
                    }

                    .swiper-wrapper {
                        &:has(> *:only-child) {
                            justify-content: center;
                        }
                    }

                    .main_fp_prev {
                        right: auto;
                        left: 1rem;
                        transform: rotate(180deg);
                    }

                    &.no_swiper {
                        .main_fp_prev,
                        .main_fp_next {
                            display: none;
                        }

                        .swiper-wrapper {
                            display: flex;
                            justify-content: space-between;

                            .swiper-slide {
                                width: calc(50% - 6px);
                            }
                        }

                        &.only_one {
                            .swiper-wrapper {
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .product_infos {
                margin-left: 48px;
                width: calc(29.762% - 48px);

                @media only screen and (max-width: 1560px) {
                    margin-left: 20px;
                    width: calc(29.762% - 20px);
                }

                .btn_return {
                    position: relative;
                    display: block;
                    padding-left: 12px;
                    margin-bottom: 39px;
                    margin-top: 4px;
                    line-height: 1;
                    top: 0;
                    z-index: 5;

                    @media only screen and (max-width: 1360px) {
                        margin-bottom: 25px;
                    }

                    @media only screen and (max-width: 1360px) {
                        margin-bottom: 15px;
                    }

                    span {
                        font-family: $primaryFont;
                        font-size: 15px;
                        letter-spacing: 0;
                        color: $black;
                    }

                    &:before {
                        content: "<";
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 1rem;
                        height: 1rem;
                        line-height: 1;
                        font-family: $primaryFont;
                        font-size: 15px;
                    }
                }

                .product_infos_content {
                    height: calc(100% - 3.4rem);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;

                    @media only screen and (max-width: 1460px) {
                        height: calc(100% - 1.9rem);
                    }

                    /* INFO, PRIX, CTA, COULEUR */
                    .product_infos_form {
                        .item_name {
                            display: flex;
                            justify-content: space-between;
                            width: calc(100% - 2rem);

                            #itm_name {
                                font-family: $primaryFont;
                                font-weight: 600;
                                font-size: 28px;
                                margin-bottom: 9px;
                                line-height: 1;
                                width: 100%;

                                span {
                                    display: inline;
                                    margin-left: 6px;
                                    font-weight: 200;
                                    font-size: 18px;
                                }
                            }
                        }

                        .item_infos {
                            margin-bottom: 1.5rem;

                            #addToWishlistButton {
                                width: 1.5rem;
                                height: 1.5rem;
                                background: url('../svg/spritesheet.svg');
                                background-repeat: no-repeat;
                                background-size: 550%;
                                background-position: 75% 0%;
                                display: block;
                                position: absolute;
                                top: -2px;
                                right: -2px;

                                span {
                                    font-size: 0;
                                }

                                &.existToWishlistButton {
                                    background-position: 75% 25%;
                                }
                            }

                            .item_price_wrapper {
                                margin-bottom: 34px;

                                .item_price {
                                     display: flex;
                                     align-items: flex-end;
                                     flex-wrap: wrap;

                                    .price_tag {
                                        .price, span {
                                            font-size: 17px;
                                            font-family: $secondaryFont;
                                            font-weight: 400;

                                            span {
                                                &.no_cents {
                                                    display: none;
                                                }
                                            }
                                        }

                                        & + .price_tag {
                                            margin-left: .25rem;
                                            position: relative;

                                            .price, span {
                                                font-size: 15px;
                                            }
                                        }
                                    }

                                    .eclat {
                                        display: block;
                                        width: 100%;
                                        margin-top: 14px;

                                        img {
                                            width: 50px;
                                            height: 30px;
                                            display: inline-block;
                                        }
                                    }
                                }
                            }

                            .itm_form_group {
                                position: relative;

                                .ligne_form {
                                    &.colors {
                                        margin-bottom: 37px;

                                        .right_element_prod {
                                            .choices_list{
                                                display: flex;
                                                flex-wrap: wrap;

                                                .form_itm {
                                                    width: 1.4375rem;
                                                    height: 1.4375rem;
                                                    margin-right: 1rem;

                                                    input[type=radio] {
                                                        display: none;

                                                        & + label {
                                                            display: block;
                                                            background-size: contain;
                                                            border-radius: 100%;
                                                            width: 1.8125rem;
                                                            height: 1.8125rem;
                                                            cursor: pointer;
                                                            position: relative;
                                                            border: 2px solid $white;
                                                            box-sizing: border-box;

                                                            &:before {
                                                                content: '';
                                                                position: absolute;
                                                                width: 1.45rem;
                                                                height: 1.45rem;
                                                                border-radius: 100%;
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                margin: auto;
                                                                border: 1px solid rgba(0,0,0,0.1);
                                                            }
                                                        }

                                                        &:checked + label {
                                                            box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.25);

                                                            &:after {
                                                                content:attr(title);
                                                                position: absolute;
                                                                display: inline-block;
                                                                width: auto;
                                                                text-align: center;
                                                                height: 1rem;
                                                                top: -1.75rem;
                                                                left: -12.5px;
                                                                font-family: $primaryFont;
                                                                font-size: 13px;
                                                                min-width: 2.75rem;
                                                                line-height: 12px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.sizes {
                                        margin-bottom: 41px;
                                        transition: all linear .3s;

                                        @media only screen and (max-width: 1540px) {
                                            margin-bottom: 30px;
                                        }

                                        @media only screen and (max-width: 1320px) {
                                            margin-bottom: 15px;
                                        }

                                        .size_wrapper {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;

                                            .choices_list  {
                                                display: flex;
                                                flex-wrap: wrap;
                                                max-width: 320px;

                                                .form_itm.size {
                                                    position: relative;
                                                    width: auto;
                                                    height: 1rem;
                                                    text-align: center;
                                                    margin-right: 11px;
                                                    margin-bottom: 1rem;

                                                    input {
                                                        display: none;

                                                        & + label {
                                                            font-size: 17px;
                                                            margin: auto;
                                                            width: auto;
                                                            min-width: 1.8125rem;
                                                            height: 1.8125rem;
                                                            line-height: 1.8125rem;
                                                            display: inline-flex;
                                                            justify-content: center;
                                                            text-align: center;
                                                            color: $primaryColor;
                                                            font-weight: 500;
                                                            cursor: pointer;
                                                            font-family: $secondaryFont;

                                                            &:before {
                                                                content:'';
                                                                position: absolute;
                                                                bottom: -13px;
                                                                left: 0;
                                                                right: 0;
                                                                margin: auto;
                                                                width: 100%;
                                                                height: 1px;
                                                                min-width: 1.8125rem;
                                                                height: 1.8125rem;
                                                                background-color: transparent;
                                                                border-radius: 100%;
                                                            }

                                                            span {
                                                                text-align: center;
                                                                padding-left: 3px;
                                                            }
                                                        }

                                                        &:checked {
                                                            & + label {
                                                                &:before {
                                                                     background-color: transparent;
                                                                     border: 1px solid rgba(0,0,0,.75);
                                                                }

                                                                &:after {
                                                                    display: none;
                                                                }
                                                            }
                                                        }

                                                        &.disabled {
                                                            & + label {
                                                                color: $disabledColor;
                                                                position: relative;

                                                                &:before {
                                                                    content:'';
                                                                    position: absolute;
                                                                    height: 90%;
                                                                    width: 1px;
                                                                    background-color: $disabledColor;
                                                                    min-width: 1px;
                                                                    border-radius: 0;
                                                                    top: 0;
                                                                    bottom: 0;
                                                                    left: .35rem;
                                                                    margin: auto;
                                                                    transform: rotate(50deg);
                                                                }
                                                            }

                                                            &:checked {
                                                                & + label {
                                                                    color: $disabledColor;

                                                                    &:before {
                                                                        border: none;
                                                                        background-color: $disabledColor;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }

                                            .block_sizeguide {
                                                a {
                                                    display: inline-block;
                                                    font-size: 16px;
                                                    font-family: $primaryFont;
                                                    border-bottom: 1px solid $black;
                                                    line-height: 1;
                                                }
                                            }
                                        }
                                    }
                                }

                                #sizeerror {
                                    font-family: $primaryFont;
                                    font-size: 15px;
                                    color: $alert;
                                    position: absolute;
                                    bottom: .85rem;

                                    @media only screen and (max-width: 1360px) {
                                        bottom: 0rem;
                                    }
                                }

                                /* CTA FP */
                                &#product_addcart {
                                    .prod_btn_wrapper {
                                        display: flex;
                                        justify-content: space-between;

                                        #bloc_add_basket {
                                            min-width: calc(50% - 5px);
                                            flex-grow: 1;

                                            .btn_container {
                                                button {
                                                    width: 100%;
                                                    padding: 0;
                                                    border: none;
                                                    box-shadow: none;
                                                    background: none;
                                                    cursor: pointer;
                                                    margin: 0;

                                                    #btn_add_cart {
                                                        width: 100%;
                                                        background-color: $ViolentViolet;
                                                        height: 40px;
                                                        border-radius: 10px;
                                                        border: 1px solid $white;
                                                        border-radius: 10px;
                                                        color: $white;
                                                        text-align: center;
                                                        font-family: $primaryFont;
                                                        font-size: 15px;
                                                        text-transform: uppercase;
                                                        font-weight: 200;
                                                        cursor: pointer;

                                                        @media only screen and (max-width: 1220px) {
                                                            font-size: 12px;
                                                        }

                                                        &:hover {
                                                            background-color: $WhiteRock;
                                                            color: $black;
                                                        }
                                                    }

                                                    & + .loader {
                                                        width: 100%;
                                                        height: 40px;
                                                        border: 1px solid $white;
                                                        background:  $WhiteRock url(../svg/three_dots_black.svg) center no-repeat;
                                                        background-size: 15%;
                                                        border-radius: 10px;
                                                        top: inherit;
                                                        bottom: 0;
                                                    }
                                                }
                                            }

                                            .roll_preco {
                                                display: none;
                                            }
                                        }

                                        /* ALERTE STOCK */
                                        #bloc_add_alert {
                                            min-width: calc(50% - 5px);
                                            flex-grow: 1;
                                            min-height: 40px;
                                            height: auto;

                                            #keep_alive_stock {
                                                .w-alertestock-form {
                                                    width: 100%;

                                                    .w-form-line {
                                                        .w-input-container {
                                                            .w-input {
                                                                .w-input-element {
                                                                    width: 100%;
                                                                    height: 40px;
                                                                    border-radius: 10px;
                                                                    border: 1px solid $black;
                                                                    font-family: $tertiaryFont;
                                                                    font-size: 13px;

                                                                    &:-webkit-autofill,
                                                                    &:-webkit-autofill:hover,
                                                                    &:-webkit-autofill:focus,
                                                                    &:-webkit-autofill:active  {
                                                                        box-shadow: 0 0 0 30px white inset !important;
                                                                    }
                                                                }

                                                                .w-input-label{
                                                                    span {
                                                                        font-family: $tertiaryFont;
                                                                        font-size: 13px;
                                                                    }
                                                                }

                                                                &.w-nonempty {
                                                                    .w-input-label{
                                                                        transform: translateY(-100%);
                                                                        span {
                                                                            font-size: 11px;
                                                                        }
                                                                    }
                                                                }

                                                                &.w-has-error {
                                                                    .w-input-element {
                                                                        border-color: red;
                                                                        box-shadow: inherit;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &.w-submit {
                                                            width: auto;
                                                            min-width: inherit;
                                                            position: absolute;
                                                            top: 2px;
                                                            right: 2px;

                                                            .w-submit-button {
                                                                width: 2.2rem;
                                                                background-color: $white;
                                                                height: 35px;
                                                                border: none;
                                                                font-family: $tertiaryFont;
                                                                font-weight: 600;
                                                                color: $black;
                                                                font-size: 13px;

                                                                &:active, &:hover, &:focus {
                                                                    background-color: $white !important;
                                                                    border: none;
                                                                    color: $black;
                                                                }

                                                                span {
                                                                    color: $black;
                                                                    font-size: 13px;
                                                                }

                                                                &.w-loader {
                                                                    background: $white url(../svg/three_dots.svg) center no-repeat;
                                                                    background-size: 25%;

                                                                    &.loading, &:active, &:hover, &:focus {
                                                                        background-color: $white !important;
                                                                        border: none;
                                                                        color: $black;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .cta_availability_wrapper {
                                            min-width: calc(50% - 5px);
                                            flex-grow: 1;
                                            margin-left: 10px;

                                            #ctaAvailabilityInStore {
                                                width: 100%;
                                                background-color: $white;
                                                height: 40px;
                                                border-radius: 10px;
                                                border: 1px solid $black;
                                                color: $black;
                                                text-align: center;
                                                font-family: $primaryFont;
                                                font-size: 15px;
                                                text-transform: uppercase;
                                                font-weight: 200;
                                                cursor: pointer;

                                                @media only screen and (max-width: 1220px) {
                                                    font-size: 12px;
                                                }

                                                span {
                                                    line-height: 1
                                                }

                                                & + .loader {
                                                    width: 100%;
                                                    height: 40px;
                                                    background: $white url(../svg/three_dots.svg) center center no-repeat;
                                                    background-size: 15%;
                                                    border-radius: 10px;
                                                    border: 1px solid $black;
                                                    box-sizing: border-box;

                                                    @media only screen and (max-width: 1335px) {
                                                        height: 41px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    /* MENTION PAYER PAR 3 FOIS */
                    .paye_par_3_fois {
                        width: 100%;
                        text-align: center;
                        margin: 1rem auto;
                        font-family: $primaryFont;
                        font-size: 15px;
                        line-height: 1;
                        display: none;

                        a {
                            display: inline-block;
                            line-height: 1;
                            border-bottom: 1px solid $black;
                        }
                    }

                    /* BLOC ACCCORDEON DESCRIPTION */
                    .product_feats_wrapper_accordion {
                        min-height: 90px;

                        li {
                            display: block;
                            margin-bottom: 10px;

                            .title {
                                font-family: $secondaryFont;
                                font-size: 16px;
                                letter-spacing: 0;
                                text-transform: uppercase;
                                display: inline-block;
                                font-weight: 400;
                                cursor: pointer;

                                &:after {
                                    content:"+";
                                    width: 1rem;
                                    height: 1rem;
                                    color: $black;
                                    font-family: $secondaryFont;
                                    margin-left: .5rem;
                                    font-size: 1rem;
                                }

                                &.is-clicked {
                                    border-bottom: 1px solid $black;

                                    &:after {
                                        content:"-";
                                    }
                                }
                            }

                            .content {
                                font-family: $primaryFont;
                                font-size: 16px;
                                line-height: 1;
                                margin-top: 1rem;
                                display: none;
                                min-height: inherit;
                                opacity: 0;

                                p {
                                    margin-bottom: 1.25rem;

                                    &.ref {
                                        span {
                                            position: relative;

                                            &:before {
                                                content:"-";
                                                width: 12px;
                                                height: 1rem;
                                                padding-right: .5rem;
                                            }
                                        }
                                    }
                                }

                                &.is-open {
                                    min-height: 35px;
                                    opacity: 1;

                                    &.os-host-overflow-y {
                                        padding-right: .5rem;
                                    }
                                }
                            }
                        }
                    }

                    /* BLOC REASSURANCE */
                    .bloc_reassurances_bottom {
                        padding: 1rem;
                        border-top: 1px solid $black;
                        border-bottom: 1px solid $black;
                        display: flex;
                        justify-content: space-around;
                        margin: 2rem 0 0;

                        .item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            span.ico {
                                display: inline-block;
                                width: 2.5rem;
                                height: 1.75rem;
                                background: url('../svg/spritesheet.svg');
                                background-repeat: no-repeat;
                                background-size: 550%;
                                background-position: 75% 0%;
                                position: relative;
                                margin-bottom: 9px;
                            }

                            .txt {
                                font-family: $secondaryFont;
                                font-size: 13px;
                                text-transform: uppercase;
                                color: $black;
                                text-align: center;
                                line-height: 1.1;
                            }

                            &:nth-child(1) {
                                span.ico {
                                    background-position: 98% 27%;
                                    background-size: 600%;
                                }
                            }

                            &:nth-child(2) {
                                span.ico {
                                     width: 1.75rem;
                                    height: 1.75rem;
                                    background-position: 50% 50%
                                }
                            }

                            &:nth-child(3) {
                                span.ico {
                                    background-position: 26% 50%;
                                    background-size: 500%;
                                    width: 1.75rem;
                                }
                            }

                            &:nth-child(4) {
                                span.ico {
                                    background-position: 1% 50%;
                                    background-size: 500%;
                                    width: 2rem;
                                }
                            }
                        }

                        @media only screen and (max-width: 1160px) {
                            padding: 1rem 0;
                            .item {
                                margin: 0 .5rem 1rem;

                                .txt {
                                    font-size: 12px;
                                }
                            }
                        }

                        @media only screen and (max-width: 1090px) {
                            .item {

                                .txt {
                                    font-size: 11px;
                                }
                            }
                        }

                    }

                    &.desc_close {
                       .product_infos_form {
                            .item_infos {
                                .itm_form_group {
                                    .ligne_form.sizes {
                                        margin-bottom: 82px;
                                        transition: all linear .3s;
                                    }
                                }
                            }
                       }
                    }
                }
            }
        }
        .associations_section {
            max-width: 100%;

            .complete_the_look_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 63px;
                max-width: 100%;
                height: 444px;

                #completeLookTitle {
                    color: $black;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                    font-size: 80px;
                    line-height: 1.05;
                    padding-left: 5.9%;
                    box-sizing: border-box;
                    width: 32.5%;
                    position: relative;
                    top: -1.75rem;

                    @media only screen and (max-width: 1280px) {
                        font-size: 60px;
                    }
                }

                .product_associations_wrapper {
                    width: 68%;
                    max-width: 1150px;

                    #gondole_look {
                        width: 100%;

                        .choices_list {
                            width: 100%;
                        }

                        .form_submit.bloc_add_color {
                            top: 93%;
                        }
                    }
                }
            }

            .might_like_wrapper {
                width: 86.5%;
                max-width: 1450px;
                margin: auto;

                #mightLikeTitle {
                    color: $black;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                    font-size: 40px;
                    line-height: 1;
                    text-align: right;
                    margin-bottom: 24px;
                    position: relative;

                    span {
                        display: inline-block;
                        padding: 0 3px 0 25px;
                        background-color: $white;
                        position: relative;
                        z-index: 2;
                    }

                    &:before {
                        content: "";
                        height: 1px;
                        width: 100%;
                        background-color: $black;
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

                .product_associations_wrapper {
                    #gondole_aimerez {
                        width: 100%;

                        .imgWrapper {
                            .eclat_rect {
                                img {
                                    width: 50%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }

            .product_associations_wrapper {
                .swiper-slide {
                    margin-right: 30px;

                    .imgWrapper {
                        .block_lnk {
                            .association_prod_images_list {
                                position: relative;
                                li {
                                    position: relative;
                                    width: 100%;

                                    img {
                                        width: 100%;
                                        height: auto;
                                        display: block;
                                    }

                                    &.holder_association_video {
                                        .video_ctn {
                                            position: relative;
                                            overflow: hidden;
                                            width: 100%;
                                            padding-top: 147.5%;
                                            background-color: rgba(0,0,0,0.05);

                                            iframe {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                bottom: 0;
                                                right: 0;
                                                width: 100%;
                                                height: 100%;
                                                margin: auto;
                                            }
                                        }
                                    }

                                    & + li {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 0;
                                        opacity: 0;
                                    }
                                }
                            }

                            &:hover {
                                .association_prod_images_list {
                                    li {
                                        opacity: 0;
                                        & + li {
                                            z-index: 2;
                                            opacity: 1;
                                        }

                                        &:only-child {
                                            opacity: 1 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .blockInfosProd {
                        .wrapper_description {
                            .item_title {
                                display: block;
                                width: calc(100% - 1rem);
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;

                                span {
                                    font-family: $primaryFont;
                                    font-size: 17px;
                                    font-weight: 600;
                                    color: $black;
                                    line-height: 1;

                                    & + .item_subtitle {
                                        font-weight: 200;
                                    }
                                }
                            }
                        }

                        .item_price {
                            display: flex;
                            align-items: flex-end;

                            .pricetag {
                                font-family: $secondaryFont;
                                font-size: 15px;
                                color: $black;

                                & + .pricetag {
                                    font-size: 14px;
                                    margin-left: .25rem;
                                    padding-bottom: 0.5px;
                                    text-decoration: line-through;
                                }
                            }
                        }

                        .wrap_rolloverproduit {
                            min-height: 3.5rem;

                            form {
                                .rollover_left {
                                    &.achat_express {
                                        .ligne_form {
                                            &.productSizeFieldset {
                                                .size_list {
                                                    .choices_list {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }

                                        .wishlistBtn {
                                            top: 9px;
                                            right: 7px;
                                        }
                                    }
                                }
                            }

                            .bloc_add_color {
                                margin-top: -2px;
                                top: 93.5%;
                            }
                        }
                    }

                    &:hover {
                        .blockInfosProd {
                            .wrap_rolloverproduit {
                                opacity: 1;
                                z-index: 5;
                            }
                        }
                    }
                }
            }
        }
    }

    #abox {
        background-color: $white;
        width: 467px;
        box-sizing: border-box;
        padding: 45px 50px;

        .header_lightbox {
            .txt_alert {
                .box_wishlist {
                    .light_title {
                        font-size: 30px;
                        font-family: $secondaryFont;
                        color: $black;
                        line-height: 33px;
                        margin-bottom: 1rem;
                        text-transform: uppercase;
                    }

                    p {
                        font-family: $primaryFont;
                        font-size: 1.065rem;
                        line-height: 1;
                        margin-bottom: 1rem;
                    }

                    .wrapper_btn {
                        &.form_submit {
                            .button  {
                                display: block;
                                line-height: 40px;
                                border: none;
                                background-color: $ViolentViolet;
                                color: $white;

                                &:hover {
                                    background-color: $WhiteRock;
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .alma_payment_wrapper {
        text-align: center;
        padding-top: 20px;

        .text_alma{
            &:first-child {
                margin-bottom: 10px;

                .encart_3x_alma_custom a {
                    text-decoration: underline;
                }
            } 
            

            &:last-child .encart_3x_alma_custom {
                display: flex;
                justify-content: center;
                font-family: $primaryFont;
                font-size: clamp(14px,fs-vw(16,1920),16px);
                color: $black;

                p {
                    font-family: $quinaryFont;
                    font-size: clamp(10px, fs-vw(13,1920),13px);
                    font-weight: 600;
                    color: $black;
                    width: fit-content;
                    padding: 2.5% 6%;
                    border: 1px solid $grey;
                }
            } 
            
        }
    }
}
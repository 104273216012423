.page_wrapper {
    .wrapper_moncompte {
        .site_search{
            margin: 0 auto;
            padding-bottom: 5vmin;

            .w-input-container {
                max-width: 1398px;
                margin: 0 auto;

                .w-input {
                    width: 50%;
                    margin: 0 auto;

                    &.w-email-input {
                        &:before {
                            content: '';
                            position: absolute;
                            width: 19px;
                            height: 19px;
                            right: 2%;
                            top: 50%;
                            background-image: url(../svg/spritesheet.svg);
                            background-repeat: no-repeat;
                            background-size: 525%;
                            background-position: 49% 0%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            #faq_search {
                width: 100% !important;
                padding: 10px;
                border: 1px solid $grey!important;
            }

            .search_results {
                display: none;
                margin-left: 25%;

                .brand_item {
                    padding: 10px 0px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 1.063rem;
                    border-bottom: 1px solid $grey;
                    box-sizing: border-box;
                    font-weight: 500;
                    font-size: 18px;
                    width: 1040px;
                    align-items: center;

                    &:first-child {
                        border-top: 1px solid $grey;
                    }

                    &:last-child {
                        border-bottom: 0 none;
                    }

                    &::after {
                        transition: transform .3s ease-out;
                        content: "";
                        display: block;
                        width: 14px;
                        height: 14px;
                        background-image: url(../svg/spritesheet.svg);
                        background-size: 500%;
                        background-position: 100% 0%;
                        background-repeat: no-repeat;
                        transform: rotate(90deg);
                    }

                    &.active{
                        border-bottom: 0 none;

                        &:after {
                            transform: rotate(270deg);
                        }
                    }
                }
            }
        }

        .wrapper_moncompte_main.faq {
            max-width: 1137px;
            margin: 0 auto;

            .faq_content {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
                box-sizing: border-box;

                .theme_global {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    row-gap: 31px;
                    column-gap: 33px;

                    .theme {
                        margin: 0;
                        padding: 89px 1vmin 29px;
                        text-align: center;
                        background-position: 50% 34%;
                        border: 1px solid $black;
                        width: 22.779%;
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        background-size: 20%;

                        &.active {
                            border: 0 none;
                            background-color: $offWhite2;
                        }

                        &:nth-child(5) {
                            padding: 77px 8vmin 4px;
                            margin: 0;
                            background-position: 50% 25%;

                            h2 {
                                max-width: 216px;
                            }
                        }

                        h2 {
                            text-transform: uppercase;
                            font-size: 20px;
                        }
                    }
                }

                .theme_detail {
                    margin: 0 auto;
                    width: 100%;

                    .faq_title {
                        display: none!important;
                    }

                    .faq_questions{
                        display: none;
                    }

                    .faq_question {
                        padding: 22px 32px 18px 29px;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid $grey;
                        box-sizing: border-box;
                        cursor: pointer;

                        h3{
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 18px;
                        }

                        &:first-child {
                            border-top: 1px solid $grey;
                            margin-top: 61px;
                            width: 100%;
                        }

                        &::after {
                            transition: transform .3s ease-out;
                            content: "";
                            display: block;
                            width: 14px;
                            height: 14px;
                            background-image: url(../svg/spritesheet.svg);
                            background-size: 500%;
                            background-position: 100% 0%;
                            background-repeat: no-repeat;
                            transform: rotate(90deg);
                        }

                        &.active{
                            border-bottom: 0 none;

                            &:after {
                                transform: rotate(270deg);
                            }
                        }
                    }

                    .faq_reponse {
                        display: none;
                        border-bottom: 1px solid $grey;
                        padding-left: 30px;
                        padding-bottom: 13px;
                        font-weight: 300;
                        font-size: 17px;
                        font-family: $primaryFont;
                    }
                }

                table {
                    width:100%!important;
                    border: 1px solid $black;

                    tr,td {
                        border: 1px solid $black;
                        padding: 10px;
                    }
                }

                .theme_menu {
                    display: none!important;
                }
            }
        }
    }
}
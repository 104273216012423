/* SITE COLORS */
$white:             #FFFFFF;
$black:             #000000;
$offWhite:          #F8F8F8;
$offWhite2:         #F5F5F5;
$disabledColor:     #757575;
$grey:              #d9d9d9;
$greyLight:         #e5e5e5;
$greyDark:          #bab9ba;
$greyDarker:        #8b8b8b;
$labelColor:        #666666;
$greyForm:          #262626;
$borderColor:       #c5c5c5;
$borderColorLight:  #e4e0dd;
$cookiesCompulsory: #a9a9a9;
$cmdFacture:        #BB7960;
$optinBorder:       #999999;
$optinBg:           #f0efec;
$optinActiveBg:     #9f9f9e;
$fadeGreyline4:     #e6e6e6;
$greyTxt3:          #767676;
$greyLighter:       #F5F5F5;
$greyIcon:          #f3f3f3;
$ViolentViolet:     #2c1756;
$WhiteRock:         #EFE8CB;

$red:           #FC0005;
$alert:         #ff6d6d;
$green:         #30ad50;
$grey:          #d9d9d9;
$gray:          #888c8d;

$primaryColor: black;
$secondaryColor: #bda560;
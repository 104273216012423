/* RAYON */
body {
    &.category {
        .page_wrapper {
            .main_wrapper {
                #breadcrumbs {
                    margin: 0 auto;

                    .breadcrumb {
                        a {
                            display: inline-block;
                            text-transform: lowercase;

                            &:first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .wrapper_bandeau {
                    background-color: $white;

                    &.filter_on {
                        z-index: 6;
                        position: relative;
                    }

                    .holder_content {
                        .wrap_bg {
                            .page_title {
                                text-align: center;
                                font-size: 80px;
                                line-height: 44px;
                                font-family: $secondaryFont;
                                text-transform: uppercase;
                                padding-bottom: 40px;
                                padding-top: 5px;
                            }
                        }

                        .item.seo {
                            width: 100%;
                            max-width: 635px;
                            margin: 0 auto;
                            padding-bottom: 35px;
                            text-align: center;
                        }
                    }
                }

                .push.item .imgWrapper {
                    .block_push {
                        margin-bottom: 2.0725rem;
                        margin-top: 1.05rem;
                        display: block;
                        position: relative;

                        a.block_lnk {
                            &.video {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 96%;
                                height: 87%;
                                z-index: 2;
                            }

                            &.img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .video_container {
                            position: relative;
                            overflow: hidden;
                            width: 100%;
                            padding-top: 32.813%;

                            iframe {
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                                margin: auto;

                            }
                        }
                    }
                }

                .page_rayon {
                    .bloc_sub_categ {
                        background-color: $white;
                        position: relative;

                        .sub_categ {
                            .itm_name {
                                a {
                                    font-family: $secondaryFont;
                                    font-size: 15px;
                                    letter-spacing: 0;
                                    color: $black;
                                    text-decoration: none;
                                    text-transform: uppercase;
                                }
                            }

                            .bloc_itm {
                                #slideCateg {
                                    .swiper-wrapper {
                                        div {
                                            margin-right: 45px;

                                            a {
                                                font-family: $secondaryFont;
                                                font-size: 15px;
                                                letter-spacing: 0;
                                                color: $black;
                                                text-decoration: none;
                                                text-transform: uppercase;
                                            }

                                            &:last-of-type {
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.filter_on {
                            z-index: 6;
                        }
                    }

                    .pager_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        font-family: $secondaryFont;
                        font-size: 0;
                        font-weight: 100;
                        margin: 0 auto 125px;

                        .see_all_product {
                            font-size: 16px;
                            font-family: $primaryFont;
                            display: inline-block;
                            border-bottom: 1px solid $primaryColor;
                            order: 3;
                            color: $black;
                            font-weight: 400;
                            cursor: pointer;
                            line-height: 1;
                        }

                        .pagination {
                            order: 1;
                            margin-right: 0;
                            font-size: 15px;
                            display: flex;
                            align-items: flex-end;
                            color: $primaryColor;

                            span,
                            a {
                                margin-right: 25px;
                                position: relative;
                                display: inline-block;
                                cursor: pointer;
                                font-size: 17px;
                                line-height: 1;

                                &.vector_sprite {
                                    font-size: 0;

                                    &.next {
                                        transform: rotate(180deg);
                                        margin-right: 12px;
                                    }

                                    &:before {
                                        display: none;
                                    }
                                }

                                &.active {
                                    font-weight: bold;
                                    cursor: default;
                                    font-size: 22px;
                                }
                            }

                            span.hellip {
                                cursor: default;
                            }
                        }
                    }

                    .wrap_flitre_pager {
                        position: relative;

                        .pagerNav.end_pagi {
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            align-items: flex-end;
                            margin: 0 0 125px;

                            .pager_wrapper {
                                margin: 0;
                            }

                            .loader_scroll {
                                .button.primary:not(.loader),
                                .button.primary:not(.loader):focus {
                                    padding: 0;
                                    border: none;
                                    border-bottom: 1px solid $black;
                                    border-radius: unset;
                                    background: none !important;
                                    box-shadow: none;
                                    height: max-content;

                                    span {
                                        font-size: 16px;
                                        font-family: $primaryFont;
                                        color: $primaryColor !important;
                                        font-weight: 400;
                                        line-height: 1;
                                        text-transform: none;
                                    }
                                }

                                .button.primary.loader {
                                    display: none;
                                    opacity: 0;
                                }
                            }

                            .page_loader {
                                width: 100%;
                                margin-top: 30px;
                                text-align: center;
                            }

                            #page_loader {
                                width: 100%;
                                margin-top: 30px;
                                text-align: center;
                                font-size: 0;
                        
                                &::before {
                                    content: '';
                                    display: block;
                                    background: url(../svg/three_dots.svg) center no-repeat;
                                    background-size: 100%;
                                    width: 4.3rem;
                                    height: 2.3rem;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.search_in {
        .dynasearchwrapper {
            #search_page {
                .search_recap {
                    .recap {
                        .nbr_result {
                            position: absolute;
                            right: 7%;
                            top: 43%;
                            font-weight: 300;
                            font-size: 17px;
                            line-height: 20px;
                            color: #272525;
                            text-transform: lowercase;
                            font-style: italic;
                        }
                    }
                }

                .pager_wrapper {
                    .see_all_product {
                        text-align: center;
                    }
                }
            }
        }
    }

    &.search_result_on {
        .pager_wrapper {
            .see_all_product {
                text-align: center;
            }
        }
    }
}

/* VIEW MODE */
#content_obj {
    #scroll_items {
        margin-bottom: 1.5rem;

        #list_item {
            width: 100%;
            max-width: 1742px;
            margin: 2px auto;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            padding-left: 1.7795%;

            .item:not(.lookbook) {
                width: 23.3%;
                margin-right: 1.7%;
                margin-bottom: 15px;
                margin-left: 0 !important;
                position: relative;

                .imgWrapper {
                    position: relative;
                    min-height: 339px;

                    .noStock_img_container {
                        display: none;
                    }

                    .swiper-slide-off {
                        min-height: 339px;
                    }

                    .wrap_video {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        padding-top: 147%;
                        background-color: rgba(0, 0, 0, 0.05);
                        height: 0;

                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            margin: auto;
                        }

                        .item_video {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            margin: auto;
                        }
                    }

                    .productVisualMulti {
                        .swiper-slide {
                            .block_lnk {
                                background: inherit !important;
                            }

                            &.swiper-slide-next {
                                opacity: 0;
                            }
                        }
                    }

                    .block_lnk {
                        display: flex;
                        position: relative;
                        min-height: 339px;

                        img {
                            width: 100%;
                            height: auto;
                            display: block;

                            &+img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: -1;
                                opacity: 1;
                            }
                        }

                        &+.hoverImg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            opacity: 0;
                        }

                        /* IF LAZYLOAD */
                        .ill_img {
                            width: 100%;
                            display: block;
                            transition: opacity ease-in .35s;

                            &+.ill_img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: -1;
                                opacity: 1;
                            }
                        }
                    }

                    &:hover {
                        .block_lnk {
                            &+.hoverImg {
                                z-index: 1;
                                opacity: 1;
                            }

                            /* IF LAZYLOAD */
                            .ill_img {
                                opacity: 0;

                                &+.ill_img {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 2;
                                    opacity: 1;
                                }

                                &:only-child {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                .description_prix_wrapper {
                    background-color: $white;
                    position: absolute;
                    z-index: 2;
                    min-height: 3.5rem;
                    width: 100%;
                    padding-top: 10px;
                    width: calc(100% - 60px);

                    .item_title {
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .blockInfosProd {
                    width: 100%;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;

                    .wrapper_description {
                        line-height: 1;

                        .item_title {
                            span {
                                font-size: 17px;
                                font-family: $primaryFont;
                                font-weight: bold;

                                &.item_subtitle {
                                    font-weight: 200;
                                    padding-left: .35rem;
                                }
                            }
                        }
                    }

                    .item_price {
                        .pricetag {
                            line-height: 1;

                            span {
                                font-family: $secondaryFont;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 1;

                                &.no_cents {
                                    display: none;
                                }
                            }
                        }
                    }

                    .eclat_rond {
                        display: inline-block;
                        position: absolute;
                        z-index: 2;
                        right: 6px;

                        img {
                            width: auto;
                            height: auto;
                            max-height: 30px;
                        }
                    }

                    .bloc_add_color {
                        position: absolute;
                        top: calc(100% - 50px);
                        opacity: 0;
                        z-index: -1;

                        .load_product {
                            display: none;
                        }

                        .button {
                            line-height: 1;
                        }
                    }


                }

                .eclat_rect {
                    display: inline-block;
                    left: 9px;
                    top: 10px;
                    position: absolute;
                    z-index: 2;

                    img {
                        width: 50%;
                        height: auto;
                    }
                }

                &.push {
                    width: 48.24%;

                    img {
                        display: block;
                    }
                }

                &:hover {

                    .wrap_rolloverproduit,
                    .bloc_add_color {
                        z-index: 3;
                        opacity: 1;
                    }

                    .description_prix_wrapper {
                        opacity: 0;
                        z-index: -1;
                    }
                }
            }
        }
    }

    &:not(.in_lookbook) {
        #scroll_items {
            #list_item {
                &.byTwo {
                    max-width: 1024px;
                    margin: auto;
                    padding-left: 0;
                    justify-content: space-between;

                    @media only screen and (max-width: 1360px) {
                        width: calc(100% - 3rem);
                    }

                    .item:not(.lookbook) {
                        width: 48.53%;
                        max-width: 497px;
                        margin-right: 0;

                        &.push {
                            width: 100%;
                            max-width: 1024px;
                            margin-bottom: 70px;
                        }
                    }
                }
            }
        }
    }

    &.in_lookbook {
        #scroll_items {
            #list_item {
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                box-sizing: border-box;
                padding: 0 !important;
            }
        }
    }
}

/* FILTRES */
#rayon_sticky_block {
    background-color: $white;
    padding: 15px 0 12px;

    @media only screen and (max-width: 1720px) {
        padding: 15px 1.5rem;
    }

    .top_filters {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1680px;
        margin: auto;
        box-sizing: border-box;

        #trigger_filtre {
            font-size: 17px;
            font-family: $primaryFont;
            text-transform: uppercase;
            padding-right: 20px;
            position: relative;
            line-height: 1;
            cursor: pointer;
            margin-right: 25px;
            font-weight: 100;

            &:after {
                content: "";
                position: absolute;
                right: 1px;
                bottom: 2px;
                width: .75rem;
                height: .75rem;
                background: url('../svg/spritesheet.svg');
                background-repeat: no-repeat;
                background-size: 550%;
                background-position: 100% 0%;
                transform: rotate(90deg);
                transition: transform ease-in .35s;
            }

            &.toClose {
                &:after {
                    transform: rotate(-90deg);
                }
            }
        }

        .viewChanger_wrapper {
            display: flex;

            #viewChanger {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                padding-bottom: 4px;

                .viewOption {

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 10px;
                    cursor: pointer;

                    &.byTwo {
                        width: 25px;
                    }

                    &.byFour {
                        width: 52px;
                    }

                    .dot {
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        background-color: rgba(0, 0, 0, .5);
                        display: inline-block;
                    }

                    &.actif {
                        .dot {
                            background-color: rgba(0, 0, 0, 1);
                        }
                    }
                }

                .sep {
                    display: inline-block;
                    margin: 0 10px;
                    font-size: 10px;
                    line-height: 1;
                    font-family: $primaryFont;
                }
            }
        }
    }

    &.triggered {
        z-index: 6;
        position: relative;
    }

    .filters_dropdown {
        height: 0;
        opacity: 0;
        overflow: hidden;
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        box-sizing: border-box;

        .holder_filters {
            display: flex;
            width: calc(100% - 200px);

            .filter_wrapper {
                margin-right: 10.23%;
                line-height: 1;

                @media only screen and (max-width: 1360px) {
                    margin-right: 8%;
                }

                .filter_name {
                    font-family: $secondaryFont;
                    font-size: 15px;
                    text-transform: uppercase;
                    line-height: 1;
                    margin-bottom: 18px;
                }

                label {
                    cursor: pointer;
                }

                .selected_options {
                    .form_itm {
                        margin-bottom: 20px;
                    }

                    input {
                        display: none;

                        &[type=checkbox],
                        &[type=radio] {
                            &+label {
                                cursor: pointer;

                                &::before {
                                    display: none;
                                }
                            }

                            &:checked+label {
                                font-weight: 900;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                /* TAILLE */
                &#filter_sizes {
                    .filter_name {
                        padding-left: 10px;
                        margin-bottom: 14px;
                    }

                    .selected_options {
                        .form_itm {
                            position: relative;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            font-size: 17px;
                            margin-bottom: 10px;

                            input {
                                display: none;

                                &+label {
                                    font-size: 17px;
                                    margin: auto;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    display: inline-flex;
                                    justify-content: center;
                                    text-align: center;
                                    color: $primaryColor;
                                    font-weight: 500;
                                    cursor: pointer;
                                    font-family: $secondaryFont;
                                    border-radius: 100%;
                                    border: 1px solid transparent;

                                    span {
                                        text-align: center;
                                    }

                                }

                                &:checked {
                                    &+label {
                                        font-weight: 400;
                                        border-color: $black;
                                        text-decoration: none;

                                        &:after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* COULEUR */
                &#filter_color {
                    .filter_name {
                        margin-bottom: 14px;
                    }

                    .selected_options {
                        padding: 2px 0;

                        .form_itm {
                            margin-bottom: 10px;

                            input[type=checkbox] {
                                display: none;

                                &+label {
                                    display: flex;
                                    align-items: center;
                                    font-family: $primaryFont;
                                    font-size: 17px;
                                    white-space: nowrap;
                                    position: relative;
                                    cursor: pointer;

                                    .color_bullet {
                                        width: 28px;
                                        height: 28px;
                                        border-radius: 100%;
                                        border: 1px solid $white;
                                        margin-right: 11px;
                                        position: relative;
                                        margin-left: 2px;
                                        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.075);

                                        &:before {
                                            content: '';
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 100%;
                                            background: transparent;
                                            border: 1px solid rgba(0, 0, 0, 0);
                                            position: absolute;
                                            top: -2px;
                                            left: -2px;
                                        }

                                        &+span {
                                            text-transform: lowercase;

                                            &:first-letter {
                                                text-transform: uppercase;
                                            }
                                        }
                                    }

                                    .txt_color {
                                        width: 50px;
                                        white-space: nowrap;
                                    }
                                }

                                &:checked {
                                    &+label {
                                        text-decoration: none;
                                        font-weight: 400;

                                        .color_bullet {
                                            box-shadow: inherit;

                                            &:before {
                                                content: '';
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 100%;
                                                background: transparent;
                                                border: 1px solid rgba(0, 0, 0, .75);
                                                position: absolute;
                                                top: -2px;
                                                left: -2px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* TRIER PAR */
                &#filter_trier,
                &#filter_sort_by {
                    .selected_options {
                        display: inline-flex;
                        flex-direction: column;

                        input {
                            display: none;
                        }

                        a,
                        label {
                            line-height: 1;
                            display: inline-block;
                            width: auto;
                            margin-bottom: 20px;

                            &.actif {
                                text-decoration: underline;
                                font-weight: bold;
                            }
                        }
                    }
                }

                /* FILTER STOCK */
                &#filter_stock {
                    input {
                        display: none;

                        &+label {
                            font-family: $primaryFont;
                            font-size: 17px;
                            color: $black;
                            line-height: 1;
                            margin-bottom: 20px;
                        }

                        &:checked {
                            &+label {
                                font-weight: bold;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            &.more_filter {
                justify-content: space-between;

                .filter_wrapper {
                    margin-right: 3%;
                }
            }
        }

        .holder_submits {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;

            .form_submit {
                width: 200px;
                height: 40px;
                border-radius: 10px;
                margin-bottom: 21px;

                .button {
                    width: 100%;
                    height: 40px;
                    background-color: $white;
                    color: $black;
                    border: 1px solid $ViolentViolet;
                    box-sizing: border-box;
                    transition: all ease-in .1s;

                    span {
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 100;
                        color: $black;
                    }

                    &.reset {
                        &:focus {
                            background-color: $white !important;

                            span {
                                color: $black !important;
                            }
                        }
                    }
                }

                &.margBot {
                    .button {
                        background-color: $ViolentViolet;
                        color: $white;

                        span {
                            color: $white;
                        }

                        &:hover {
                            background-color: $WhiteRock;
                            border: 1px solid $WhiteRock;

                            span {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }

        &.show {
            height: auto;
            position: absolute;
            z-index: 6;
            opacity: 1;
            padding: 47px 11.875vw;
            top: 54px;
            left: 0;

            @media only screen and (max-width: 1360px) {
                padding: 47px 30px;
            }
        }
    }
}

/* RECHERCHE */
.dynasearchwrapper {
    min-height: 38rem;

    #search_page {
        .search_recap {
            margin: 0 auto;
            box-sizing: border-box;
            background-color: $white;
            position: relative;
            padding-top: 25px;

            &.filter_on {
                z-index: 6;
            }

            .recap {
                max-width: 1680px;
                width: 100%;
                margin: 0 auto;

                @media only screen and (max-width: 1720px) {
                    padding: 0 1.5rem;
                }

                .search_term {
                    display: flex;
                    width: 100%;
                    position: relative;
                    padding-bottom: 5px;
                    border-bottom: solid 1px $black;
                    font-family: $secondaryFont;
                    font-size: 40px;
                    color: $black;
                    padding-left: 30px;

                    .loupeDark {
                        background-image: url(../svg/spritesheet.svg);
                        background-position: 50% 0;
                        background-size: 600%;
                        width: 17px;
                        height: 17px;
                        opacity: .5;
                        position: absolute;
                        top: 38%;
                        left: 0;
                    }
                }

                .nbr_result {
                    font-family: $primaryFont;
                    font-size: 20px;
                    text-transform: lowercase;
                    display: inline-block;
                    margin: 16px 0 0 27px;
                    line-height: 1;
                    position: relative;

                    &.on_form {
                        z-index: 10;
                    }
                }
            }
        }

        #rayon_sticky_block {
            padding-bottom: 12px;
        }

        #scroll_items {
            #list_item {
                position: relative;
                top: -3px;
                margin: 3px auto;
            }
        }

        /* PAS DE RÉSULTAT */
        &.no_results_wrapper {
            .search_details_wrapper {

                .nbr_result {
                    margin-bottom: 23px;
                }

                .no_result_block {
                    width: 100%;
                    text-align: center;
                    color: $black;
                    margin: 85px 0;

                    .title_no_result {
                        font-size: 16px;
                        font-family: $secondaryFont;
                        text-transform: uppercase;
                        line-height: 1;
                        margin-bottom: 25px;
                        display: inline-block;
                    }

                    .tips_title {
                        font-family: $primaryFont;
                        font-size: 17px;
                        line-height: 1;
                        font-weight: 100;
                    }

                    .search_no {
                        text-align: center;

                        span {
                            display: block;
                            width: auto;
                            position: relative;
                            font-size: 17px;
                            font-family: $primaryFont;
                            line-height: 1;
                            margin-bottom: 2px;
                            font-weight: 100;
                            padding-left: 20px;

                            span {
                                padding-left: 0;
                                display: inline-block;
                                position: relative;
                                top: 1px;
                                left: -9px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .search_gondole {
                width: 100%;
                max-width: 1440px;
                margin: 0 auto;

                #scroll_items {
                    #list_item {
                        max-width: 100%;

                        .titre_gondole {
                            text-align: right;
                            width: 100%;
                            margin-bottom: 1.875rem;
                            position: relative;
                            line-height: 1;
                            height: 30px;

                            span {
                                text-transform: uppercase;
                                font-family: $secondaryFont;
                                font-size: 1.875rem;
                                line-height: 1;
                                display: inline-block;
                                position: relative;
                                z-index: 1;
                                background-color: $white;
                                padding-left: 30px;
                            }

                            &:before {
                                content: "";
                                height: 1px;
                                width: 100%;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                background-color: $black;
                                z-index: 0;
                            }
                        }

                        &.items_gondole {
                            .items_container {
                                width: 100%;
                                display: flex;

                                @media only screen and (min-width: 1360px) {
                                    transform: inherit !important;
                                }

                                .item.gondole_item {
                                    width: 18.2639% !important;
                                    max-width: 263px !important;
                                    margin-right: 2.22% !important;

                                    @media only screen and (max-width: 1360px) {
                                        width: 23.2639% !important;
                                        margin-right: 2.22% !important;
                                        max-width: 100% !important;
                                    }

                                    .imgWrapper {
                                        .gondolePictures {
                                            .eclat_rect {
                                                position: absolute;
                                                top: 10px;
                                                left: 10px;
                                                display: inline-block;
                                                width: auto;

                                                img {
                                                    width: 50%;
                                                    height: auto;
                                                }
                                            }
                                        }
                                    }

                                    .description_prix_wrapper {
                                        .description_price {
                                            .wrapper_description {
                                                width: calc(100% - 55px);
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                white-space: nowrap;

                                                .item_title {
                                                    text-overflow: ellipsis;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                }
                                            }
                                        }
                                    }

                                    /* ACHAT EXPRESS GONDOLE RECHERCHE */
                                    .wrap_rolloverproduit {
                                        .achat_express {
                                            .ligne_form {
                                                &.productSizeFieldset {
                                                    .size_list {
                                                        .choices_list {
                                                            max-width: calc(100% - 100px);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* ACHAT EXPRESS RECHERCHE */
        #list_item {
            .item {
                .description_price {
                    background-color: $white;
                    position: absolute;
                    z-index: 2;
                    min-height: 3.5rem;
                    opacity: 1;
                    width: 100%;
                }

                form {
                    width: 100%;
                    height: auto;
                    background: $white;
                    display: block;
                    min-height: 3.5rem;
                    text-align: right;

                    .rollover_left {
                        width: 100%;
                    }

                    .bloc_add_color {
                        position: absolute;
                        top: calc(100% - 58px);
                        opacity: 0;
                        z-index: -1;

                        .load_product {
                            display: none;
                        }

                        .button {
                            line-height: 1;
                        }
                    }
                }

                &:hover {
                    .description_price {
                        z-index: -1;
                        opacity: 0;
                    }

                    form {
                        opacity: 1;
                        z-index: 2;

                        .bloc_add_color {
                            opacity: 1;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}

/* LOADER RECHERCHE */
.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75vh;

    span {
        font-size: 0;
    }

    &:before {
        content: '';
        display: block;
        width: 80px;
        height: 50px;
        background: transparent url(../svg/three_dots.svg) center no-repeat;
        background-size: 100%;
    }
}
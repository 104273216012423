/* Connexion */
.body_login #breadcrumbs {
    display: none;
}

.w-input-element:not([type='radio']):not([type='checkbox']),
input[type='email'].w-input-element,
input[type='password'].w-input-element,
input[type='text'].w-input-element,
textarea.w-input-element {
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0;
    box-sizing: border-box;
}

.w-nonempty:not(.no-label) .w-input-element:not([type='radio']):not([type='checkbox']),
.w-nonempty:not(.no-label) input[type='email'].w-input-element {
    padding-top: 0;
}

.w-visibility-toggle {
    border: none;
}

.w-oneclick-connect {
    flex-direction: row-reverse;
    margin: 0 auto;

    .w-social-btn {
        border: none;
        width: 38px;
        height: 35px;

        & > div {
            width: 80%;
            height: 60%;
        }

        & + .w-social-btn {
            margin-left: 0;
        }

        &.go-connect {
            margin-right: 1.3rem;
        }
    }
}

.wrapper_tunnel {
    &.vp {
        .newlogin_page .login_bloc .title h2 {
            text-transform: none;
            font-size: 2.6rem;
            max-width: 95%;
        }
    }

    .newlogin_page {
        flex-direction: row;
        width: 100%;
        max-width: 1394px;
        margin: 0 auto 0;
        align-items: stretch;
        padding-top: 25px;

        @media screen and (max-width: 1400px) {
            padding: 0 1.5rem;
        }

        p {
            font-size: 1rem;
            line-height: 1.1;
            font-family: $primaryFont;
        }

        .intro {
            color: $primaryColor;
            text-align: left;
            margin-top: 1rem;

            p {
                font-size: 0.875rem;
                font-weight: 300;
            }
        }

        .w-input-label {
            font-family: Arial, sans-serif;
            font-size: 0.875rem;
        }

        .w-nonempty:not(.no-label) .w-input-element:not([type='radio']):not([type='checkbox']),
        .w-nonempty:not(.no-label) input[type='email'].w-input-element {
            padding-top: 0.8rem;
        }

        .w-nonempty {
            .w-input-label {
                font-size: 0.75rem;
                font-style: italic;
            }
        }

        .deja_compte {
            color: red;
            margin-bottom: 1.1rem;
            display: none !important;
        }

        .wrapper_bandeau_connexion {
            width: 50.1%;
            max-width: 670px;
            margin-left: 1rem;

            span {
                display: block;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .w-lines-group {
            margin-bottom: 0;
        }

        .login_wrapper {
            width: 43.1%;
            padding: 0 0 0 8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 730px;
            flex: 1;
        }

        .login_bloc {
            width: 100%;
        }

        .login_bloc {
            align-items: flex-start;
            height: auto;

            &:last-of-type {
                padding-top: 1.8rem;

                .title {
                    margin: 0 auto 1.7rem auto;
                }
            }

            &:first-of-type {
                padding-bottom: 0.2rem;
            }

            .w-oneclick-connect {
                .w-social-btn {
                    & > div {
                        height: 70%;

                        &:not(.bfr):not(.aft) {
                            background-size: cover;
                        }
                    }
                }
                
                #googleConnect {
                    width: 38px;
                    height: 35px;
                    background-color: $greyIcon !important;
                    background: none;

                    #googleConnectLabel {
                        &::before {
                            height: 55%;
                            width: 50%;
                        }
                    }
                }
            }

            .title {
                text-align: center;

                &.my_account {
                    margin: 0 auto;
                }

                h2 {
                    font-family: $secondaryFont;
                    font-size: 3.75rem;
                    line-height: 1;
                    letter-spacing: 0;
                    font-weight: 400;

                    & + .subtitle {
                        display: none;
                    }
                }

                h4 {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    line-height: 1;
                    letter-spacing: 0;
                    font-weight: 400;
                    margin-bottom: 1.3rem;
                    text-align: center;
                    font-weight: bold;
                }
            }

            .login_form_wrapper {
                .title {
                    margin-bottom: 1.1rem;
                    font-family: $primaryFont;

                    h3 {
                        font-size: 1.5rem;
                        line-height: 1;
                        letter-spacing: 0;
                        font-weight: 300;
                        margin: 0.5rem 0 1.2rem;
                        font-family: inherit;
                        text-align: center;
                        font-weight: bold;
                    }

                    #subtitleLogin {
                        letter-spacing: -0.45px;
                    }
                }
            }

            .login_cgv_msg {
                display: block;
                margin: 1.7rem 0 0 0;
                text-decoration: initial;
                font-size: 0.875rem;

                a {
                    text-decoration: underline;
                }

                p {
                    font-size: inherit;
                }
            }

            .w-client-form {
                align-items: flex-start;
            }

            .w-lostpass-form .w-form-line.w-submit {
                width: 100%;
                max-width: 30rem;
            }
        }

        .w-newlogin-form {
            width: 100%;
        }

        .w-newlogin-form {
            .w-fieldset-label {
                font-size: 1rem;
                line-height: 1.2;
                font-family: $primaryFont;
                margin: 0 0 0.5rem;
                padding-top: 0.5rem;
            }

            .w-form-line {
                margin-bottom: 1.5rem;

                &.optin_container {
                    margin-bottom: 0.8rem;
                }
            }

            .optin_container {
                &.column {
                    align-items: flex-start;

                    & > .w-form-line {
                        width: auto;
                        justify-content: flex-start;
                    }
                }

                .w-line-label {
                    font-size: 1rem;
                    line-height: 1.2;
                    font-family: $primaryFont;
                    color: $primaryColor;
                }

                .w-radio-input .w-input-element + .w-input-label {
                    span {
                        font-family: Arial, sans-serif;
                        font-size: 0.875rem;
                        line-height: 1;
                        color: $primaryColor;
                    }

                    &:before {
                        width: 18px;
                        height: 18px;
                    }
                }

                .w-form-line {
                    &:first-child {
                        margin-bottom: 1rem;
                    }

                    margin-bottom: 0;
                }

                .w-input-note.w-input-error {
                    text-align: left;
                }
            }

            .w-pwd-reset {
                font-family: $primaryFont;
                font-size: 1rem;
                line-height: 1;
                color: $primaryColor;
                border-color: $primaryColor;
                margin: 0;
            }

            .knowMoreOptin {
                margin-left: 0.3rem;
                margin-right: 0.3rem;
                display: inline;

                .info_site {
                    display: inline;

                    a {
                        font-size: 1rem;
                        line-height: 1;
                        color: $primaryColor;
                        border-color: $primaryColor;
                    }
                }
            }

            .w-form-line.w-submit {
                max-width: 253px;
                margin-bottom: 1.7rem;
                margin-top: 1rem;

                .w-submit-button {
                    font-family: $primaryFont;
                    width: 100%;
                    height: 40px;
                    line-height: 1;
                    font-weight: 300;
                    font-size: 0.9375rem;
                    border-radius: 10px;
                }
            }
        }
    }
}

/* Newsletter */
.wrapper_moncompte_main.content_newsletter {
    width: 96%;
    max-width: 1136px;
    margin: 0 auto;

    .img_banner_wrapper {
        display: none;
    }

    .update_success {
        margin-bottom: 5px;
    }

    .w-form-line {
        margin-bottom: 40px;

        .w-input {
            .w-input-element {
                .w-nonempty {
                    padding-top: 1.15em;
                }
            }
        }

        &.optin-select-line {
            margin-bottom: 26px;

            .w-input-container {
                .w-input {
                    input {
                        &.w-input-element {
                            &:checked {
                                & + .w-input-label {
                                    &::before {
                                        border-color: transparent;
                                        background-color: $optinBg;
                                    }

                                    &::after {
                                        left: 13px;
                                    }
                                }
                            }
                        }
                    }

                    .w-input-label {
                        align-items: flex-start;

                        &:before {
                            content: '';
                            background: $optinBg;
                            position: relative;
                            width: 24px;
                            height: 14px;
                            display: block;
                            border-radius: 10px;
                            box-shadow: none;
                        }

                        &:after {
                            top: 1px;
                            content: '';
                            display: block;
                            position: absolute;
                            left: 1px;
                            width: 12px;
                            height: 12px;
                            background: $white;
                            border-radius: 13px;
                            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
                            transition: all 0.2s ease-out;
                        }

                        .multiline {
                            font-family: $primaryFont;
                            font-size: 14px;
                            color: $primaryColor;
                            font-weight: 300;
                            margin-left: 1rem;

                            strong {
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 18px;
                                letter-spacing: 0.05em;
                                font-family: $secondaryFont;
                                color: $black;
                                text-transform: uppercase;
                                padding-bottom: 12px;
                            }

                            span {
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 21px;
                                font-family: $primaryFont;
                                color: $black;
                            }
                        }
                    }
                }

                .w-checkbox-input {
                    height: auto;
                }
            }
        }

        &.w-submit {
            margin-top: 50px;
        }
    }
}

/* Informations */
.wrapper_moncompte {
    #breadcrumbs {
        margin: 0 auto 0;
    }

    .nav_moncompte {
        .title {
            display: none;
        }

        .account_name {
            text-align: center;
            font-size: 50px;
            line-height: 33px;
            font-family: $secondaryFont;
            text-transform: uppercase;
        }

        .wrapper_menu_compte {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 96%;
            margin: 48px auto 45px;

            .item {
                margin-right: 3.6%;
                font-size: 18px;
                line-height: 11px;
                font-family: $primaryFont;
                font-weight: 300;

                &:last-of-type {
                    margin-right: 0;
                }

                &.actif {
                    text-decoration: underline;
                    text-underline-offset: 10px;
                }

                h1 {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 11px;
                }
            }
        }
    }

    .left-container {
        .wrapper_content_mes_infos {
            width: 96%;
            max-width: 1136px;
            margin: auto;
            box-sizing: border-box;

            .wrapper_form {
                .update_success {
                    margin-bottom: 5px;
                }

                .w-info-form {
                    padding-bottom: 0px;

                    .w-form-line {
                        margin-bottom: 25px;

                        .w-radio-group {
                            .w-radio-input {
                                &:nth-child(1) {
                                    margin-right: 22px;
                                }

                                .w-input-label {
                                    left: 5px;

                                    span {
                                        font-size: 14px;
                                        line-height: 46px;
                                        font-family: $tertiaryFont;
                                        color: $black;
                                    }
                                }
                            }

                            .w-checkbox-input .w-input-element:checked + .w-input-label::before,
                            .w-radio-input .w-input-element:checked + .w-input-label::before {
                                box-shadow: inset 0 0 0 2px $white;
                            }
                        }

                        &.w-has-tel {
                            .w-tel-input {
                                .selected-flag {
                                    background-color: transparent;
                                    border: 0 none;
                                    border-right: 1px solid $grey;
                                }
                            }
                        }

                        .w-submit-button {
                            margin-top: 10px;
                        }

                        .w-input-container {
                            &:nth-child(2) {
                                margin-left: 28px;
                            }

                            .w-input-element {
                                .w-nonempty {
                                    padding-top: 1.15em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ADDRESS */
.wrapper_form_adresse {
    max-width: 1136px;
    margin: auto;
    box-sizing: border-box;

    .w-address-form {
        .choix_type_adresse {
            display: flex;
            flex-direction: row;
            margin-bottom: 4vmin;
            max-width: 378px;

            .type_adresse {
                padding: 0 42px 18px;
                font-size: 21px;
                line-height: 13px;
                font-family: $secondaryFont;
                text-transform: uppercase;

                &.actif {
                    border-bottom: 1px solid $black;
                }
            }

            .f_left.ami {
                display: none;
            }
        }

        .w-input-group {
            margin-bottom: 0;
        }

        .elem_global.addrFullDisplay {
            .w-input-group {
                margin-bottom: 0;
            }

            .w-form-line {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .w-form-line {
            margin-bottom: 25px;

            .w-input-label {
                color: $black;
            }

            .w-input-container {
                .w-has-error {
                    .valid_form {
                        display: none;
                    }
                }

                .w-input-element {
                    .w-nonempty {
                        padding-top: 1.15em;
                    }
                }
            }

            &.addrCivility {
                .w-radio-group {
                    .w-radio-input {
                        &:nth-child(2) {
                            padding-left: 30px;
                        }
                    }

                    .w-input-label {
                        span {
                            font-size: 13px;
                            line-height: 46px;
                            font-family: $tertiaryFont;
                            color: $black;
                        }
                    }
                }
            }

            &.addrPhone {
                margin-bottom: 13px;
            }

            &.addrName {
                .w-input-container + .w-input-container {
                    margin-left: 2rem;
                }
            }

            &.w-has-tel {
                .w-tel-input {
                    .selected-flag {
                        background-color: transparent;
                        border: 0 none;
                        border-right: 1px solid $grey;
                    }
                }
            }
        }

        .w-input-group {
            legend.w-group-label {
                font-size: 20px;
                line-height: 24px;
                font-family: $secondaryFont;
                text-transform: uppercase;
                margin-bottom: 9px;
            }
        }

        .addrType {
            .w-input-container {
                .w-checkbox-input {
                    .w-input-label {
                        span {
                            font-family: Arial, sans-serif;
                            font-size: 15px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .addrSubmit {
            margin-top: 62px;
        }
    }
}

.wrapper_moncompte {
    .wrapper_mes_adresses {
        width: 96%;
        max-width: 1136px;
        margin: 0 auto;

        .conteneur_adresse {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;

            .adresse {
                width: 295px;
                min-height: 160px;
                display: flex;
                align-items: center;
                line-height: 18px;
                border: 1px solid $black;
                margin-bottom: 60px;
                margin-right: 33px;
                margin-left: 3px;
                padding-left: 30px;

                .addr_label {
                    display: flex;
                    font-size: 18px;
                    font-family: $secondaryFont;
                    text-transform: uppercase;
                }

                .infos {
                    margin: 1rem 0;
                    font-size: 18px;
                    font-family: $primaryFont;
                    font-weight: 300;
                }

                .modlinks_wrapper {
                    .modlink {
                        text-decoration: underline;

                        span {
                            font-size: 16px;
                            line-height: 1.6px;
                            font-family: $primaryFont;
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .form_submit.btn_container {
            width: 275px;
            margin-left: 1px;

            span {
                font-size: 15px;
                line-height: 40px;
                font-family: $primaryFont;
            }
        }
    }
}

/* WISHLIST */
.wrapper_moncompte {
    .wrapper_wishlist {
        max-width: 1132px;
        margin: 0 auto;
        width: 96%;

        .intro {
            p {
                font-size: 17px;
                line-height: 23px;
                font-family: $primaryFont;
                padding-bottom: 15px;
            }

            .nrArticlesSelected {
                font-size: 20px;
                line-height: 11px;
                font-family: $secondaryFont;
                padding-bottom: 35px;
                text-transform: uppercase;

                .price_tag {
                    font-size: 20px;
                }
            }
        }

        .wishlist_container {
            display: flex;
            justify-content: space-between;

            .w-left-bloc {
                width: 73.5%;

                .wish_liste_product {
                    display: flex;
                    margin-left: 11px;
                    flex-wrap: wrap;

                    .product_ctn {
                        margin: 0 0 37px 0;
                        padding: 6px;
                        border: 1px solid $black;
                        width: calc((100% - 50px) / 2);
                        min-height: 10.813rem;
                        position: relative;
                        box-sizing: border-box;

                        @media only screen and (max-width: 1200px) {
                            max-width: 386px;
                            width: 100%;
                        }

                        &:nth-child(odd) {
                            margin-right: 41px;
                        }

                        .product_check {
                            position: absolute;
                            left: -16px;
                            top: 46%;

                            label {
                                cursor: pointer;

                                &:before {
                                    content: '';
                                    display: block;
                                    border: 1px solid $borderColor;
                                    background: $white;
                                    width: 15px;
                                    height: 15px;
                                    margin: 0 0.5rem 0 0.45rem;
                                    border-radius: 0;
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    width: 15px;
                                    height: 15px;
                                    border: 1px solid $borderColor;
                                    background: $black;
                                    margin: 0 0.5rem 0 0.45rem;
                                    border-radius: 0;
                                    position: absolute;
                                    top: 0px;
                                    right: 1px;
                                    opacity: 0;
                                }
                            }

                            .checkbox_select_product {
                                display: none;
                                position: relative;
                                cursor: pointer;
                            }
                        }

                        &.checked {
                            .product_check {
                                label {
                                    &:after {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .wrapper_product {
                            display: flex;
                            align-items: flex-start;
                            width: 100%;
                            min-height: 130px;

                            img {
                                width: 90px;
                                padding-top: 12px;
                            }

                            .product_desc {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding-left: 52px;
                                padding-top: 9px;

                                .wrap_txt {
                                    .title {
                                        font-weight: 600;
                                        font-size: 15px;
                                        line-height: 22px;
                                        font-family: $primaryFont;
                                    }

                                    .subtitle {
                                        font-size: 15px;
                                        line-height: 22px;
                                        font-family: $primaryFont;
                                        font-weight: 300;
                                    }

                                    .item_price_wrapper {
                                        font-size: 15px;
                                        line-height: 20px;
                                        font-family: $primaryFont;
                                        padding-bottom: 8px;
                                    }

                                    .wrapper_details {
                                        font-size: 15px;
                                        line-height: 22px;
                                        font-family: $primaryFont;

                                        & + .cta-wrapper {
                                            margin-top: 27px;
                                        }
                                    }

                                    .no_stock {
                                        img {
                                            padding: 0;
                                            width: 100%;
                                        }

                                        & + .cta-wrapper {
                                            margin-top: 3px;
                                        }
                                    }
                                }
                            }

                            .cta-wrapper {
                                display: flex;

                                .cta-modify {
                                    span {
                                        text-decoration: underline;
                                        text-underline-offset: 4px;
                                        font-size: 16px;
                                        font-family: $primaryFont;
                                        display: inline-block;
                                        margin-right: 20px;
                                    }
                                }

                                .cta_add_to_basket {
                                    text-decoration: underline;
                                    font-size: 16px;
                                    font-family: $primaryFont;
                                    display: inline-block;
                                    height: auto;
                                    color: $black;
                                    background-color: $white;
                                    border: none;
                                    text-underline-offset: 4px;
                                    cursor: pointer;
                                }

                                .remove {
                                    position: absolute;
                                    top: 5px;
                                    right: 4px;
                                    width: 15px;
                                    height: 16px;
                                    display: inline-block;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-repeat: no-repeat;
                                    background-size: 562% auto;
                                    background-position: 78% 50%;

                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .w-right-bloc {
                display: flex;
                flex-direction: column;
                width: 23.6%;

                .form_submit.btn_container {
                    margin-bottom: 16px;
                    max-width: 270px;
                    margin-left: 5px;

                    span {
                        font-size: 15px;
                        font-family: $primaryFont;
                        line-height: 40px;
                    }

                    #bloc_btn_loader {
                        span {
                            color: $white;
                            display: none;
                        }
                    }
                }

                #share_wl {
                    .button {
                        text-decoration: underline;
                        color: $black;
                        background-color: $white;
                        border: none;
                        text-underline-offset: 4px;
                        height: 23px;

                        span {
                            font-size: 16px;
                            font-family: $primaryFont;
                            line-height: 40px;
                            color: $black;
                            text-transform: none;
                        }

                        &:focus {
                            background-color: $white  !important;
                            color: $black  !important;

                            span {
                                color: $black  !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.deconnecter {
        #share_wl {
            display: none;
        }
    }
}

#popup_savoir_plus {
    padding: 2rem 2rem 4rem;
    max-width: 750px;

    #fermer_savoir_plus {
        .btn_fermer {
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(19, 5, 5);
            background-color: $primaryColor;
            width: 20px;
            height: 20px;
            display: block;
            margin-left: auto;
        }
    }

    #title_popup_savoir_plus {
        font-family: $secondaryFont;
        font-size: 1.8rem;
        text-align: center;
        margin: 0 0 2rem;
    }
}

/* Creer mot de passe ~ render_pwd_forget.php */
.wrapper_moncompte {
    .nav_moncompte.pwdforget {
        .title {
            display: block;
            font-size: 50px;
            line-height: 50px;
            font-family: $secondaryFont;
            text-transform: uppercase;
            margin: 0 auto 2rem;
            max-width: 600px;
        }
    }

    .wrapper_password {
        max-width: 600px;
        margin: 0 auto;

        .w-submit {
            max-width: 253px;
        }
    }
}

/* Commandes */
.wrapper_mes_commandes {
    max-width: 1136px;
    margin: 0 auto;
    width: 96%;

    .intro {
        font-family: Rokkitt, sans-serif;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
    }

    .wrapper_menu_tab {
        display: flex;
        flex-direction: row;
        margin-bottom: 4.3%;

        .date_select {
            position: relative;
            cursor: pointer;
            width: 228px;
            height: 50px;
            border: 1px solid $black;
            border-radius: 10px;

            select {
                cursor: pointer;
                width: 228px;
                height: 100%;
                margin: 0 auto;
                padding: 0 1.5rem;
                padding-top: 0.5rem;
                display: block;
                font-size: 0.95rem;
                text-transform: uppercase;
                border: none;
                outline: 0;
                background-repeat: no-repeat;
                appearance: none;
                background: url(../svg/arrow.svg) no-repeat 88% center;
                background-size: 8%;
                font-family: $primaryFont;
            }
        }

        #com_onglet {
            width: 60.5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 9.45%;

            .onglet {
                text-decoration: underline;
                padding-top: 0.5rem;

                &.actif {
                    text-decoration: none;
                }
            }
        }
    }

    .content_commandes {
        width: 100%;

        .table_mon_compte {
            .header {
                border-bottom: 1px solid $black;
                font-family: $secondaryFont;

                th {
                    text-transform: uppercase;
                    padding-left: 1%;
                }
            }

            .content {
                td {
                    padding: 1.65rem 0;
                    padding-left: 1%;
                }

                .etat {
                    vertical-align: middle;
                    padding: 0;
                    padding-left: 1%;
                }

                .facture {
                    padding: 0;
                    vertical-align: middle;
                    padding-left: 1%;

                    .link_fact {
                        .facture-bloc {
                            .f-item:last-of-type {
                                color: $cmdFacture;
                                display: none;
                            }

                            .bill {
                                text-decoration: underline;
                            }
                        }
                    }

                    .refund {
                        text-decoration: underline;
                    }
                }

                .montant {
                    .price_tag {
                        font-size: 1.15rem;
                        padding-left: 0.75rem;

                        .cents {
                            display: none;
                        }
                    }
                }

                .link_detail {
                    a {
                        text-decoration: underline;
                        letter-spacing: -0.15px;
                    }
                }
            }

            .hidden_row {
                border-bottom: 1px solid $grey;
            }

            tbody:last-child {
                .hidden_row {
                    border-bottom: none;
                }
            }
        }
    }
}

/* Commandes details */
.wrapper_content_cmd_detail {
    position: relative;
    max-width: 1136px;
    margin: 0 auto;
    width: 96%;
    padding-top: 4.5vmin;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn_return {
        position: absolute;
        top: -1px;
        left: 0;
        font-size: 1.1rem;

        &::before {
            content: '<';
            margin-right: 0.5rem;
        }
    }

    .order_detail_head {
        width: 26%;
        margin-right: 7%;
        margin-bottom: 2rem;

        .wrapper_infos_cmd {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            line-height: 18px;
            padding: 2.6vmin 3vmin;
            border: 1px solid $black;

            .cmd_txt {
                text-transform: uppercase;
                font-family: $secondaryFont;
                margin-bottom: 0.5rem;
                font-size: 1.15rem;

                .cmd_number {
                    font-size: 1.05rem;
                    font-family: $primaryFont;
                }
            }

            .cmd_date {
                line-height: 1.45rem;
                font-size: 1.12rem;
            }

            .cmd_status {
                font-size: 1.12rem;
            }

            .num_suivi {
                line-height: 1.55rem;
                font-size: 1.12rem;
            }

            .num_suivi_colis_retour {
                font-size: 1.12rem;
                line-height: 1.29rem;
            }

            .num_suivi_btn {
                background-color: $ViolentViolet;
                text-align: center;
                border-radius: 10px;
                padding: 0.7rem 0;
                margin-top: 0.63rem;

                a {
                    color: $white;
                    text-transform: uppercase;
                    font-size: 0.92rem;
                    font-weight: 300;
                }

                &.hover {
                    background-color: $WhiteRock;
                    color: $black;
                }
            }
        }

        .wrapper_delivery_addr {
            min-height: 160px;
            display: flex;
            flex-direction: column;
            line-height: 18px;
            padding: 2.5vmin 3vmin;
            border: 1px solid $black;
            margin-top: 3.45vmin;

            p {
                line-height: 1.3rem;
                font-size: 1.12rem;
            }

            .addr_title {
                text-transform: uppercase;
                font-family: $secondaryFont;
                padding-bottom: 0.5rem;
            }
        }

        .wrapper_order_faq {
            margin-top: 1.35rem;
            padding-left: 3.25vmin;

            .order_faq_title_1 {
                text-transform: uppercase;
                font-family: $secondaryFont;
                font-size: 1.15rem;
            }

            .order_faq_title_2 {
                text-decoration: underline;
                line-height: 1rem;
            }
        }
    }

    .order_details_prod {
        margin-right: 6.25%;
        max-width: 27rem;
        width: 34.75%;

        .cmd_items {
            .product-link {
                display: flex;

                .cart_thumb_holder {
                    width: 5.6rem;
                    margin-right: 1rem;
                }

                .cart_detail_box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .cart_product {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .sub_title {
                            display: flex;
                            font-weight: 600;
                            line-height: 1rem;

                            .sstitre {
                                font-weight: 400;
                            }
                        }

                        .wrapper_price {
                            line-height: 1rem;
                        }
                    }

                    .prod_details {
                        display: flex;
                        flex-direction: column;
                        margin-top: 0.5rem;

                        div {
                            font-size: 0.95rem;
                            line-height: 1.35rem;
                        }
                    }
                }
            }
        }
    }

    .wrapper_right {
        width: 26%;
        height: fit-content;

        .cmd_detail_btm {
            background-color: $offWhite2;
            padding: 2.5vmin 3vmin;

            .wrap_total_commande {
                .titre {
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                    font-size: 1.25rem;
                }

                .wrapper_total {
                    margin-top: 1.3rem;
                    width: 93%;

                    .clearfix {
                        display: flex;
                        justify-content: space-between;
                        padding-left: 0.1rem;
                        font-size: 1.12rem;

                        .montants {
                            .price_tag {
                                line-height: 1.65rem;
                            }
                        }

                        .montant_total {
                            font-weight: 500;
                            font-size: 1.25rem;
                            text-transform: uppercase;
                        }
                    }

                    .clearfix:nth-of-type(2) {
                        padding-bottom: 0.55rem;
                    }

                    .clearfix:last-of-type {
                        border-top: 1px solid $black;
                        padding-top: 0.75rem;
                    }
                }
            }
        }

        .wrapper_bottom_cmd {
            #comment_retourner_cmd {
                #createNumRetour {
                    .intro {
                        line-height: 1.2rem;
                        margin-top: 2.25rem;
                        margin-bottom: 1rem;
                        width: 90%;
                    }

                    .links_comment_retourner_cmd {
                        margin-top: 1.35rem;
                        text-indent: 4px;

                        a {
                            text-decoration: underline;
                        }
                    }

                    .return_content {
                        display: none;
                    }

                    #liste_motif_retour {
                        .btn_container {
                            margin-left: 3px;

                            .button {
                                width: 68%;
                                padding: 0.5rem 0;
                                text-align: center;
                                height: unset;
                            }
                        }
                    }

                    #motif_retour_etape {
                        width: 89%;
                        margin: 2.35rem auto 0;

                        .print-return {
                            .content {
                                line-height: 1.15rem;
                                margin-top: 2rem;
                                margin-bottom: 1.15rem;
                            }

                            .retour_numero {
                                display: none;
                            }

                            .print-pdf {
                                margin-left: 3px;

                                .btn_container:last-of-type {
                                    display: none;
                                }
                            }

                            .btn_container {
                                margin-bottom: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Commandes details popup demande retour */
#popup_numero_retour {
    width: 43rem;

    #return_products_form {
        max-height: 500px;
        overflow-y: auto;
    }

    .close_pop {
        right: 3px;
    }

    .texte_num_retour {
        margin-bottom: 1.25vmin;
        font-family: $secondaryFont;
        text-align: center;
        margin-top: 2.2rem;
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    .recommandation {
        text-align: center;
        margin-bottom: 3.35rem;
        letter-spacing: -0.1px;
        line-height: 1.15rem;
    }

    .container_article_return {
        display: flex;
        justify-content: space-around;
        margin-bottom: 3rem;

        .productReturnListWrapper {
            display: flex;
            margin-left: 3rem;

            .imgWrapper {
                width: 4.5rem;
            }

            .formWrapper {
                margin-left: 1.6rem;
                width: 12rem;

                .prod_details {
                    margin-top: 0.35rem;

                    p {
                        line-height: 1.4rem;
                    }
                }

                .titre {
                    font-weight: 600;
                }

                .sstitre {
                    font-weight: 400;
                }
            }
        }

        .selectWrapper {
            width: 21.5rem;

            select {
                padding: 1rem 1rem;
                border-radius: 0;
                appearance: none;
            }

            .return_select:first-of-type {
                margin-bottom: 0.5rem;
            }

            .return_quantity {
                width: 5rem;
                background: url(../svg/arrow.svg) no-repeat 84% center;
                background-size: 20%;
            }

            .return_choice {
                width: 18.75rem;
                background: url(../svg/arrow.svg) no-repeat 94% center;
                background-size: 5%;
            }
        }
    }

    .btn_container {
        display: flex;
        justify-content: center;
        margin-top: 3.5rem;
        margin-bottom: 2.5rem;

        .button {
            width: 20.5rem;
        }

        &.loading {
            .loader {
                left: inherit;
            }
        }
    }
}

.fr.customer.body_login {
    .wrapper_tunnel {
        .newlogin_page {
            .login_wrapper {
                .login_bloc {
                    .subtitle {
                        letter-spacing: -0.45px;
                    }
                }
            }
        }
    }
}

body.customer {
    .errormsgadd {
        font-size: 14px !important;
        line-height: 17px;
        display: block;
    }

    .w-form-line.w-has-tel {
        .w-input-container:nth-child(2) {
            .w-input.w-tel-input.w-has-error + .w-input-note.w-input-error {
                top: 92%;
            }
        }
    }

    .w-form-line.w-has-tel.addrPhone .w-input-container .w-input-note.w-input-error {
        top: 92%;
    }

    .w-form.w-info-form.w-login-form .w-has-tel .w-input-container:nth-child(2) {
        .w-input-note.w-input-error {
            top: 88%;
        }
    }
}
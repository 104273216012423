/* Button */
.button,
.button + .loader,
.w-submit-button,
button.w-submit-button,
.w-submit-button,
button.w-submit-button,
.button_cta {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 40px;
    background-color: $ViolentViolet;
    outline: none;
    padding: 0;
    border-style: unset;
    border: 1px solid $white;
    font-family: $primaryFont;
    font-weight: 300;
    font-size: 15px;
    color: $white;
    text-transform: uppercase;
    border-radius: 10px;
    box-sizing: border-box;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $white;
    }

    &:focus {
        background-color: $ViolentViolet !important;
        color: $white !important;

        span {
            color: $white !important;
        }
    }

    &:hover {
        background-color: $WhiteRock;
        color: $black;

        span {
            color: $primaryColor;
        }
    }

    &.light {
        background-color: $ViolentViolet;
        color: $white;

        span {
            color: $white;
        }

        &:focus {
            background-color: $ViolentViolet;
            color: $white;
    
            span {
                color: $white;
            }
        }

        &:hover {
            background-color: $WhiteRock;
            color: $black;

            span {
                color: $black;
            }
        }
    }
}

.button_cta {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        line-height: 1;
    }
}

.w-submit-button:not(.w-loader):hover,
button.w-submit-button:not(.w-loader):hover {
    background-color: $WhiteRock;
    color: $primaryColor;

    span {
        color: $primaryColor;
    }
}

/* Loader */
.btn_container,
.form_submit {
    position: relative;
    width: 100%;
    height: 40px;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: none;

        span {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0;
        }
    }

    &.loading .loader {
        background: $WhiteRock url(../svg/three_dots.svg) center no-repeat;
        display: inline-block;
        overflow: hidden;
        background-size: 15%;
        border-radius: 8px;

        span {
            background-color: $WhiteRock;
            background-size: 10%;
        }
    }
}

/* Loader black */
.loader span {
    background: $WhiteRock url(../svg/three_dots.svg) center no-repeat;
    background-size: 15%;
}

/* Loader white */
.w-submit-button.w-loader,
button.w-submit-button.w-loader,
.btn_container .loader span,
#cookiesLightbox .w-form-line.w-submit .w-btn-wrapper + .w-btn-wrapper .w-submit-button.w-loader {
    &.loading {
        background: $WhiteRock url(../svg/three_dots.svg) center no-repeat;
        background-size: 11%;
    }
}

/* Loader search */
#search_results .loader span {
    display: block;
    width: 100px;
    height: 50px;
}

/* SW-8566 CTA Return */
#motif_retour_etape {
    #printPdf {
        .btn_container {
            a {
                font-size: 13px;
            }
        }
    }
}
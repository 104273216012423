.wrapper_content_sat {
    width: 100%;
    max-width: 1176px;
    min-width: 960px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: -26px;
    &.page_text {
        .title {
            font-size: 3.75rem;
            text-align: center;
            text-transform: uppercase;
            font-family: $secondaryFont;
            margin-bottom: 2rem;
        }
    }


    .content_sat {
        .row {

            .content_inside {
                ul {
                    margin-left: .9rem;
                    padding-left: 0px;
                }
            }

            li {
                list-style: none;
                font-weight: 300;
                margin: 0;
                padding: 0;
                &:before {
                    content: '•';
                    margin-right: .4rem;
                }
            }
            
            .content {
                padding: 0 20px;
                h2 {
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                    padding: 0 0 .5rem;
                    font-weight: 500;
                }

                strong {
                    font-weight: 600;
                }
            
                em {
                    display: inline-block;
                }
            
                a {
                    text-decoration: underline;
                }

                .content_inside {
                    line-height: 1.2;
                    font-weight: 300;
                    font-size: 17px;
                    margin-bottom: 1rem;

                    table {
                        width: 100% !important;
                        border-spacing: 0;
                        border: none;
                        border-collapse: collapse;
                        background-color: $white;
                        margin: 2rem 0;
                        width: 100% !important;

                        thead {
                            tr {
                                th {
                                    border: 1px solid $black;
                                    font-weight: 700;
                                    font-size: 15px;
                                }
                            }
                        }

                        tr {
                            td {
                                padding: .5rem 1rem;
                                border: 1px solid $black;
                                border-spacing: 0;
                                font-size: 15px;
                                vertical-align: middle;
                                line-height: 22px;
                            }
                        }
                    }
                }

                h3 {
                    font-size: 17px;
                }
            }
        }

    }
}

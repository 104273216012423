/* Page notre histoire */
#content_histoire {
    h1 {
        text-transform: uppercase;
        font-family: $secondaryFont;
        font-size: 3.725rem;
        text-align: center;
        position: relative;
        bottom: 2.15rem;
    }

    .section_histoire {
        display: flex;
        align-items: center;
        max-width: 1440px;
        min-width: 972px;
        margin: 0 auto 88px;

        .wrap_img {
            display: flex;

            img {
                width: 100%;
            }
        }

        .text_section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            top: 2.5rem;

            .sub_title {
                font-family: $secondaryFont;
                font-size: 1.5rem;
                text-transform: uppercase;
            }

            .sub_title_bold {
                font-family: $secondaryFont;
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 900;
            }

            .text {
                margin-top: 12px;
                font-size: 1.1rem;
                line-height: 1.3rem;
            }

            strong {
                font-weight: bold;
            }
        }
    }

    #histoire_01 {
        width: 1440px;
        max-width: 94%;

        .wrap_img {
            margin-right: 3%;
            width: 66%;
            position: relative;
            bottom: 0.45rem;
        }

        .text_section {
            max-width: 435px;
            position: relative;
            left: 1.35rem;

            @media screen and (max-width: 1125px) {
                left: 0;
            }
        }

        .title {
            font-family: $secondaryFont;
            font-size: 3.575rem;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 7.5rem;
            position: relative;
            bottom: 0.6rem;
        }

        .sub_title {
            font-size: 1.5rem;
            position: relative;
            bottom: 1.5rem;
            line-height: 2.06rem;
            padding-right: 1rem;
        }

        .text {
            position: relative;
            bottom: 1.65rem;
        }
    }

    #histoire_02 {
        width: 1025px;
        max-width: 67.7%;

        .wrap_img {
            width: 42.2%;
            margin-left: 16.95%;
        }

        .text_section {
            width: 41.1%;
            top: 3.15rem;

            .sub_title {
                position: relative;
                bottom: 2rem;
            }

            .sub_title_bold {
                position: relative;
                bottom: 1.195rem;
                line-height: 0.2rem;
            }

            .text {
                margin-top: 0.425rem;
            }
        }
    }

    #histoire_03 {
        width: 1440px;
        max-width: 94%;
        margin-bottom: 107px;

        .wrap_section {
            margin-right: 3.5%;
            margin-top: -6.7%;
            max-width: 30.7%;

            .wrap_img {
                width: 55.25%;
                margin-left: 48.8%;
                margin-bottom: 11.7%;
                position: relative;
                top: 0.55rem;
                left: 0.15rem;
            }

            .text_section {
                max-width: 423px;
                top: 1.3rem;

                .sub_title {
                    position: relative;
                    top: 0.2rem;
                }

                .text {
                    position: relative;
                    bottom: 0.2rem;
                }
            }
        }

        .wrap_img {
            position: relative;
            left: 1.1rem;
            bottom: 5.85rem;
        }
    }

    #histoire_04 {
        margin-bottom: 0;
        width: 1440px;
        max-width: 94%;
    }

    #histoire_05 {
        .text_section {
            text-align: center;
            margin: 0 auto;

            .histoire_05_title {
                position: relative;
                right: 0.3rem;
                margin-top: -6.75%;
                margin-bottom: 26px;
                font-family: $secondaryFont;
                font-size: 4.365rem;
                text-transform: uppercase;

                @media screen and (max-width: 1680px) {
                    margin-top: -6.65%;
                    font-size: 4.2rem;
                }

                @media screen and (max-width: 1440px) {
                    margin-top: -7%;
                }

                @media screen and (max-width: 1260px) {
                    margin-top: -7%;
                }

                @media screen and (max-width: 1125px) {
                    font-size: 3.25rem;
                    margin-top: -7.75%;
                }
            }

            .histoire_05_text {
                margin: 0 25% 30px 25%;
                font-size: 1.1rem;
                line-height: 1.3rem;
            }

            .button {
                max-width: 249px;
                margin: 0 auto;
                padding-top: 0.4rem;
            }
        }
    }
}
/* End Page notre histoire */

/* Page contact */
#wrapper_cotnact {
    width: 96%;
    max-width: 100%;
    margin: 0 auto;

    #breadcrumbs {
        padding: 0 !important;
        margin: 0 auto;
    }

    .wrap_content {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;

        #block_img {
            margin-right: 11.75%;
            min-width: 48%;
            position: relative;
            left: 2.7rem;
        }

        #contact_form {
            min-width: 43%;

            h1 {
                text-transform: uppercase;
                position: relative;
                bottom: 0.1rem;
                right: 0.1rem;
                font-size: 3.125rem;
                line-height: 2.8rem;
                margin-bottom: 2.4rem;
            }

            .w-contact-form .file-list {
                position: relative;
                bottom: 2rem;

                .rowList {
                    margin-bottom: 0.2rem;

                    button.closePj {
                        appearance: none;
                        border: 0;
                    }
                }
            }

            .file-input-row {
                margin-top: 1.9rem;
            }

            #form_contact1 {
                .file-input-row .w-form-line .ajout_pj_label {
                    border: 0px !important;
                    text-decoration: underline !important;
                    text-underline-offset: 2px;
                    line-height: 0.1rem !important;
                    font-size: 1rem;
                    text-transform: none;
                    max-width: 15rem;

                    &:after {
                        content: '100ko max';
                        position: absolute;
                        bottom: 1.95rem;
                        margin-left: 0.3rem;
                        line-height: 0.35rem;
                    }
                }

                .w-form-line {
                    margin-bottom: 1.55rem;
                }

                .w-lines-group {
                    display: flex;
                    margin-bottom: 1.3rem;
                    bottom: 1.8rem !important;

                    legend {
                        font-family: $primaryFont;
                        font-size: 0.925rem;
                        color: $greyForm;
                        letter-spacing: 0.02545em;
                        line-height: 1.1rem;
                    }

                    .w-form-line {
                        max-width: 13.8rem;
                        margin-right: 1.1rem;

                        .w-line-label {
                            color: $greyForm;
                            font-family: $primaryFont;
                            font-size: 0.9rem;
                            width: fit-content;
                            margin-right: 1.09rem;
                        }

                        .w-radio-group .w-radio-input .w-input-label {
                            margin-right: 0.5rem;
                        }

                        .w-radio-group .w-radio-input .w-input-label::before {
                            width: 16px;
                            height: 16px;
                            border: 1px solid $grey;
                            margin-bottom: 0.2rem;
                        }

                        .w-radio-group .w-radio-input .w-input-label span {
                            color: $greyForm;
                            font-size: 0.825rem;
                        }
                    }

                    .w-form-line:last-of-type .w-line-label {
                        margin-right: 1.345rem;
                    }
                }

                .w-dropdown {
                    border-radius: 0;
                }

                textarea.w-input-element {
                    padding: 1.5em 0.85714em;
                }

                .w-submit {
                    max-width: 13.995rem;
                    position: relative;
                    bottom: 1.2rem;
                }
            }

            #message_mail {
                .valid {
                    color: $green;
                }
            }
        }
    }

    #recaptcha {
        margin-bottom: 0;
        position: relative;
        bottom: 2.995rem;
    }
}

// PAGE RECRUTEMENT
.satellite_recrutement {
    .main_wrapper {
        padding-bottom: 5px;

        #ariane_recrute {
            margin-bottom: 16px;
        }

        #recrutement_page {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: -7px;

            #recrutement_img {
                justify-content: flex-start;
                width: fit-content;
                height: fit-content;
                margin-right: 27px;

                img {
                    width: 483px;
                    height: 640px;
                }
            }

            .recrutement_bloc {
                width: 85.5%;
            }

            #recrutement_infos {
                width: 70%;
                display: flex;
                flex-direction: column;
                margin: 24px;

                h1 {
                    font-size: 45px;
                    letter-spacing: 0.15rem;
                    text-transform: uppercase;
                }

                h2 {
                    font-size: 20px;
                    font-weight: lighter;
                    text-transform: uppercase;
                }

                p {
                    font-size: 15.76px;
                }

                #recrutement_texte p {
                    font-weight: bold;
                }

                .title_box {
                    width: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 10px;

                    &::after {
                        content: '';
                        border-top: 1px solid $black;
                        flex: 1 0 auto;
                    }

                    h2 {
                        padding-right: 32px;
                    }
                }

                #recrutement_form {
                    width: 100%;
                    margin: 0 auto;

                    .recrutement_bloc {
                        #formCandidat {
                            margin-top: 1.3rem;

                            .input_container {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: 100%;
                                margin-bottom: 1.7rem;
                                position: relative;

                                input {
                                    width: 100%;
                                    height: 2.5rem;
                                    border: 1px solid $greyLight;
                                    padding-left: 16px;
                                    color: $black;

                                    &::placeholder {
                                        @media (max-width: 1075px) {
                                            font-size: 0.65rem;
                                        }

                                        font-family: $tertiaryFont;
                                        font-weight: 400;
                                        font-size: 0.8rem;
                                        color: $greyTxt3;
                                        font-style: normal;
                                        opacity: 0.5;
                                    }

                                    text {
                                        color: $black;
                                    }

                                    &.inputErr {
                                        border-color: $red;
                                    }

                                    &#candidatTel {
                                        height: 46px !important;
                                        padding-left: 75px;
                                    }
                                }

                                &::after {
                                    display: none !important;
                                }

                                .intl-tel-input {
                                    width: 100%;

                                    .flag-container {
                                        padding: 8px;
                                    }

                                    .selected-flag {
                                        width: 55px;
                                        border-right: 1px solid $greyLight;

                                        .iti-arrow {
                                            right: 9px;
                                        }
                                    }
                                }
                            }

                            .wrapInput {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;

                                .input_container {
                                    width: 48.5%;
                                }
                            }

                            .bloc_file {
                                @media (max-width: 1100px) {
                                    flex-direction: column;
                                }

                                display: flex;
                                flex-direction: row;
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 100%;
                                margin-bottom: 20px;

                                .bloc_cv {
                                    margin-bottom: 1.25rem;
                                }

                                .bloc_cv,
                                .bloc_motivation {
                                    width: fit-content;

                                    .upload {
                                        #cv,
                                        #motivation_letter {
                                            width: 0.1px;
                                            height: 0.1px;
                                            opacity: 0;
                                            overflow: hidden;
                                            position: absolute;
                                            z-index: -1;

                                            & + label {
                                                display: inline-block;
                                                align-items: flex-start;
                                                justify-content: center;
                                                border: 1px solid $primaryColor;
                                                padding: 2px 5px;
                                                cursor: pointer;

                                                span {
                                                    @media (max-width: 1149px) {
                                                        font-size: 0.8rem;
                                                    }

                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-family: $primaryFont;
                                                    font-weight: lighter;
                                                    font-size: 1rem;
                                                    color: $primaryColor;
                                                    text-transform: uppercase;
                                                    text-align: start;
                                                }
                                            }
                                        }

                                        #cv {
                                            & + label {
                                                margin-right: 8px;
                                            }
                                        }

                                        #motivation_letter {
                                            & + label {
                                                margin-right: 8px;
                                                padding: 2px 11px;
                                            }
                                        }

                                        &.inputErr {
                                            #cv,
                                            #motivation_letter {
                                                & + label {
                                                    border-color: $red;

                                                    span {
                                                        color: $red;
                                                    }
                                                }
                                            }

                                            & + .file_msg {
                                                color: $red;
                                            }
                                        }
                                    }

                                    .file_msg {
                                        p {
                                            font-size: 0.9rem;
                                            font-weight: 100;
                                        }

                                        display: block;
                                        font-family: $primaryFont;
                                        font-size: 0.9rem;
                                        font-weight: 100;
                                        line-height: 1.3186813186813187rem;
                                        color: $greyTxt3;
                                        text-transform: none;
                                        margin-top: 0.3125rem;
                                        margin-right: 0.325rem;
                                        max-width: 10rem;
                                        text-align: left;
                                        overflow-wrap: break-word;
                                    }
                                }
                            }

                            .btn_container {
                                display: flex;
                                justify-content: flex-start;
                                align-content: flex-start;
                                max-width: fit-content;
                                margin: -1px 0 34px 1px;

                                span {
                                    font-size: 2rem;
                                }

                                .form_submit {
                                    width: 254px;
                                    height: 40px;

                                    input.envoyer {
                                        font-family: $secondaryFont;
                                        font-weight: 500;
                                        font-size: 1rem;
                                        letter-spacing: 0.1rem;
                                        background-color: $ViolentViolet;
                                        color: $white;
                                        border: 1px solid $white;

                                        &:hover {
                                            background-color: $WhiteRock;
                                            color: $black;
                                        }
                                    }
                                }

                                #loader_1 {
                                    span {
                                        display: flex;
                                        background-color: $white;
                                        color: $black;
                                        font-size: 15px;
                                        font-weight: 300;
                                        justify-content: center;
                                    }

                                    &::after {
                                        display: block;
                                        background: $black url(../svg/three_dots.svg) center no-repeat;
                                        background-size: 15%;
                                    }
                                }
                            }

                            #msg_suspens {
                                margin-top: 0.3125rem;
                                color: $red;
                            }
                        }

                        #msg_success {
                            color: $green;
                        }
                    }
                }

                /* Offres */
                #recrutement_offre {
                    .title_box {
                        h2 {
                            padding-right: 62px !important;
                        }
                    }

                    /* Filtres */
                    .filter_box {
                        display: block;
                        padding: 4px 0 10px;
                        border-bottom: 1px solid $black;

                        .filters_wrap {
                            margin: 40px 10px 19px 0;
                        }

                        .filters {
                            position: relative;
                            display: inline-block;
                            margin-bottom: 1.1rem;
                            margin-right: 1.1rem;

                            select {
                                appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                background: $greyLighter url('../img/mobile_webapp/select_arrow.png') 95% 50% no-repeat;
                            }

                            #servicefilter,
                            #contractfilter,
                            #geofilter {
                                width: 238px;
                                overflow: hidden;
                                display: inline-block;
                            }

                            #geofilter span {
                                margin-right: 40px;
                            }

                            #servicefilter,
                            #contractfilter,
                            #geofilter,
                            .filter_text,
                            .filter_options {
                                font-family: $secondaryFont;
                                font-size: 1rem;
                                text-transform: uppercase;
                            }

                            .filter_text {
                                font-weight: 800;
                            }

                            &:first-of-type.actif {
                                margin-right: 3.1rem;

                                #servicefilter {
                                    width: 105%;
                                }

                                &:after {
                                    right: -10px;
                                }
                            }

                            &:hover {
                                .filter_button {
                                    &:after {
                                        transform: rotate(270deg);
                                        transition: transform ease-in 0.35s;
                                    }
                                }
                            }

                            .filter_button {
                                background-color: $greyLighter;
                                width: fit-content;
                                height: fit-content;
                                padding: 16px;
                                border: none;
                                text-align: start;
                                font-weight: 100;
                                cursor: pointer;
                                position: relative;

                                span {
                                    color: $black;
                                    font-family: $secondaryFont;
                                    font-size: 1rem;
                                    text-transform: uppercase;
                                    letter-spacing: 0.042rem;
                                }
                            }
                        }

                        #active_filters {
                            #resetfilters {
                                display: none;
                                position: relative;
                                background-color: $white;
                                border: none;
                                text-transform: uppercase;
                                text-decoration: underline;
                                font-family: $primaryFont;
                                font-size: 14px;
                                color: $greyTxt3;
                                cursor: pointer;
                                margin: 0 16px 20px 0;
                                padding-left: 16px;

                                &::before {
                                    position: absolute;
                                    top: 4%;
                                    left: 0;
                                    content: '';
                                    @include bgIconPath('../svg/spritesheet.svg');
                                    @include bgIconCoord(19, 5, 5);
                                    display: inline-block;
                                    height: 0.75rem;
                                    width: 0.75rem;
                                }
                            }
                        }
                    }

                    /* Joblist */
                    #joblist {
                        p {
                            font-family: $primaryFont;
                            font-size: 18px;
                            font-weight: 100;
                        }

                        .accordion {
                            border-bottom: 1px solid $black;
                            margin-bottom: 32px;
                            cursor: pointer;

                            .annonce {
                                margin: 25px 64px 25px 74px;

                                h3 {
                                    color: $black;
                                    font-family: $secondaryFont;
                                    font-size: 1.2rem;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    line-height: 2rem;
                                }

                                h4,
                                span {
                                    color: $black;
                                    font-family: $primaryFont;
                                    font-size: 17px;
                                    font-weight: 100;
                                }

                                p.pub_date {
                                    font-weight: 500;
                                }

                                li {
                                    font-family: $primaryFont;
                                    font-weight: 100;
                                    list-style: disc;
                                }

                                .offer_container {
                                    display: none;

                                    &.actif {
                                        display: block;
                                    }
                                }

                                .description {
                                    max-width: 90%;

                                    h3 {
                                        margin-top: 32px;
                                    }

                                    .pub_date {
                                        font-weight: 500;
                                    }

                                    .bold_text {
                                        font-weight: bold;
                                    }
                                }

                                .btn-container {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-content: flex-start;
                                    width: fit-content;
                                    margin: 32px 0;

                                    button {
                                        width: 254px;
                                        height: 40px;

                                        span {
                                            font-family: $secondaryFont;
                                            font-size: 1rem;
                                            font-weight: 500;
                                            letter-spacing: 0.1rem;
                                            color: $white;
                                        }

                                        &:hover {
                                            span {
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        #no_offers {
                            display: none;
                            text-transform: uppercase;
                            font-family: $primaryFont;
                            font-size: 14px;
                            color: $greyTxt3;

                            &.actif {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 32px 0;
                            }
                        }

                        .offer_title_box {
                            position: relative;
                            margin-bottom: 25px;

                            &:after {
                                content: '';
                                position: absolute;
                                right: 8px;
                                top: 22px;
                                width: 1rem;
                                height: 1rem;
                                background: url('../svg/spritesheet.svg');
                                background-repeat: no-repeat;
                                background-size: 550%;
                                background-position: 100% 0;
                                transform: rotate(90deg);
                                transition: transform ease-in 0.35s;
                            }

                            &.actif {
                                &:after {
                                    transform: rotate(270deg);
                                    transition: transform ease-in 0.35s;
                                }
                            }
                        }

                        .offer_container {
                            .description ul {
                                padding-left: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
}
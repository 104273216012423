#site_head_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    #header_mention_id {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        transition: height 0.3s ease-in-out;

        .swiper-container {
            width: 100%;
            height: 100%;

            .swiper-slide {
                width: 100%;
                height: 100%;

                .wrap_preheadertxt {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-family: $primaryFont;
                    font-weight: 300;
                    font-size: 16px;
                    color: $primaryColor;

                    .navpromo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../svg/spritesheet.svg');
                        background-repeat: no-repeat;
                        background-size: 555%;
                        background-position: 100% 0%;
                        width: 10px;
                        height: 10px;
                        cursor: pointer;

                        &.promoPrev {
                            transform: rotate(180deg);
                            margin-right: 30px;
                        }

                        &.promoNext {
                            margin-left: 30px;
                        }
                    }
                }
            }

            .close_delais {
                display: flex;
                align-items: center;
                justify-content: center;
                background: url('../svg/spritesheet.svg');
                background-repeat: no-repeat;
                background-size: 550%;
                background-position: 75% 50%;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                margin-top: -7px;
                right: 4px;
                z-index: 1;
                cursor: pointer;

                @media screen and (max-width: 1024px) {
                    right: 14px;
                }

                span {
                    display: none;
                }
            }
        }
    }

    #top_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1920px;
        min-width: 990px;
        height: 84px;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 0.3s ease-in-out;

        @media screen and (max-width: 1360px) {
            height: auto;
            padding: 0;
        }

        &:hover,
        &.active_rollover {
            background-color: rgba(255, 255, 255, 1);
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 96%;
            max-width: 1680px;
            height: 100%;
            margin: 0 auto;

            @media screen and (max-width: 1360px) {
                flex-wrap: wrap;
                justify-content: space-between;

                .wrap_trigger_menu {
                    width: 40px;
                    display: flex !important;
                    align-items: center;
                    justify-content: flex-start;
                    width: 225px;

                    #trigger_menu_responsive {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        width: 40px;
                        height: 18px;
                        cursor: pointer;

                        span {
                            display: flex;
                            background: $primaryColor;
                            width: 100%;
                            height: 2px;
                        }
                    }
                }
            }

            @media screen and (max-width: 1024px) {
                max-width: 960px;
            }

            /* LOGO */
            .block_logo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 7px;

                @media screen and (max-width: 1360px) {
                    width: 225px;
                    margin-top: 0;
                }

                .logo_img_holder {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    width: 206px;
                    height: 42px;
                    overflow: hidden;
                    transition: height 0.3s ease-in-out;

                    h1 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            /* END LOGO */
            /* MENU */
            .main_menu {
                display: flex;
                width: 100%;
                height: 100%;
                margin-left: 4.2%;
                box-sizing: border-box;

                @media screen and (max-width: 1360px) {
                    order: 4;
                    margin-left: 0;
                    display: none;

                    &.showing {
                        display: block;
                    }
                }

                .main_menu_contents {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;

                    @media screen and (max-width: 1360px) {
                        justify-content: center;
                    }

                    .main_menu_itm {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding-bottom: 12px;

                        &:nth-child(-n + 3) {
                            order: -1;
                        }

                        &:not(:first-child) {
                            margin-left: 4.65%;
                        }

                        .first_level_item {
                            font-family: $secondaryFont;
                            font-weight: 400;
                            font-size: 15px;
                            text-transform: uppercase;
                            color: $primaryColor;
                            padding-bottom: 5px;
                            border-bottom: solid 1px transparent;
                            transition: border-color 0.3s ease-in-out;
                        }

                        &.hovering {
                            .first_level_item {
                                border-bottom: solid 1px $primaryColor;
                            }
                        }

                        .submenu_wrapper {
                            display: none;
                            background: $white;
                            width: 100%;
                            max-width: 1920px;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            padding: 18px 30px 29px;
                            transition: all 0.3s ease-in-out;

                            .menu_categ_wrapper {
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                width: 100%;

                                .ss_menu_categ_wrapper {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    margin-top: 40px;

                                    .container_list {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        width: 100%;

                                        .wrap_cat_list {
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            width: 100%;

                                            .menu_ss_cat_list {
                                                &:not(:first-child) {
                                                    margin-left: 90px;

                                                    @media screen and (max-width: 1024px) {
                                                        margin-left: 83px;
                                                    }
                                                }

                                                &:only-child {
                                                    margin-right: 90px;

                                                    @media screen and (max-width: 1024px) {
                                                        margin-right: 83px;
                                                    }
                                                }
                                            }
                                        }

                                        .see_all {
                                            display: block;
                                            font-weight: 300;
                                            font-size: 17px;
                                            line-height: 15px;
                                            color: $primaryColor;
                                            margin-top: 25px;
                                        }
                                    }

                                    .ss_cat {
                                        &:not(:first-child) {
                                            margin-left: 93px;

                                            @media screen and (max-width: 1024px) {
                                                margin-left: 86px;
                                            }
                                        }

                                        .ss_cat_item {
                                            .ss_cat_name {
                                                display: block;
                                                font-weight: 300;
                                                font-size: 17px;
                                                line-height: 15px;
                                                color: $primaryColor;
                                            }

                                            &:not(:last-child) {
                                                .ss_cat_name {
                                                    margin-bottom: 25px;
                                                }
                                            }

                                            .ss_cat_title {
                                                font-weight: 500;
                                                font-size: 17px;
                                                line-height: 15px;
                                                color: $primaryColor;
                                                margin-bottom: 26px;
                                                display: block;
                                            }

                                            .wrap_ul {
                                                li {
                                                    font-weight: 300;
                                                    font-size: 17px;
                                                    line-height: 15px;
                                                    color: $primaryColor;

                                                    &:not(:last-child) {
                                                        margin-bottom: 25px;
                                                    }

                                                    &:only-child {
                                                        a {
                                                            font-weight: 500;
                                                            font-size: 17px;
                                                            line-height: 15px;
                                                            color: $primaryColor;
                                                        }
                                                    }

                                                    a {
                                                        font-weight: 300;
                                                        font-size: 17px;
                                                        line-height: 15px;
                                                        color: $primaryColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .menu_visuel_wrapper {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    margin-left: 5.1%;

                                    .menu_visuel {
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;

                                        .visu_menu {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: flex-start;

                                            span.ill_img {
                                                img {
                                                    image-rendering: initial;
                                                }
                                            }

                                            &:last-child {
                                                margin-left: 29px;
                                            }

                                            .visuel_title {
                                                font-weight: 300;
                                                font-size: 17px;
                                                line-height: 10px;
                                                color: $primaryColor;
                                                text-align: center;
                                                margin-top: 19px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &#menu_maison {
                            cursor: default;
                        }
                    }
                }

                /* Recettage ~ Temporaire */
                #menu_maison {
                    #menu_maison_sscat_1 {
                        .objet {
                            li {
                                &:nth-child(1),
                                &:nth-child(3) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            /* END MENNU */
            /* ROLLOVERS */
            .block_top_links {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                min-width: 225px;
                height: 100%;
                padding-bottom: 0;
                margin-left: auto;
                margin-right: 0;

                @media screen and (max-width: 1360px) {
                    margin-left: 0;
                    padding-bottom: 0;
                }

                @media screen and (max-width: 1024px) {
                    margin-top: 0;
                }

                /* SWITCH LANG */
                .wrap_lang {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $secondaryFont;
                    font-size: 15px;
                    text-transform: uppercase;
                    margin-right: 26px;
                    margin-bottom: 15px;

                    @media screen and (max-width: 1360px) {
                        margin-bottom: 13px;
                    }

                    .lang_switch {
                        cursor: default;
                    }

                    .langRollover {
                        display: none;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        position: relative;
                        margin-left: 7px;
                        padding-left: 7px;
                        transition: all 0.3s ease-in-out;

                        .lang_itm {
                            color: $greyDark;

                            &:hover {
                                color: $primaryColor;
                            }
                        }

                        &:before {
                            content: ' ';
                            display: block;
                            background: $black;
                            width: 1px;
                            height: 13px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                        }
                    }

                    &:hover {
                        .langRollover {
                            display: flex;
                        }
                    }
                }

                /* END SWITCH LANG */
                /* RECHERCHE */
                .block_search {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 25px;
                    margin-bottom: 18px;

                    @media screen and (max-width: 1360px) {
                        margin-bottom: 13px;
                    }

                    #trigger_recherche {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../svg/spritesheet.svg');
                        background-repeat: no-repeat;
                        background-size: 600%;
                        background-position: 49% 1%;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        position: relative;

                        &:after {
                            content: ' ';
                            background: $primaryColor;
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            left: 0;
                            top: calc(100% + 13px);
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;
                        }

                        &.triggered {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }

                    #form_recherche {
                        display: none;
                        align-items: center;
                        justify-content: center;
                        background-color: $white;
                        width: 100%;
                        max-width: 1920px;
                        margin: 0 auto;
                        padding: 36px 0;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);

                        .searching_content_wrapper {
                            display: flex;
                            width: 96%;
                            max-width: 1680px;
                            margin: 0 auto;

                            .wrap_field {
                                display: flex;
                                width: 100%;
                                position: relative;
                                padding-bottom: 11px;
                                border-bottom: solid 1px $black;

                                &:before {
                                    content: ' ';
                                    display: block;
                                    background-color: $borderColor;
                                    mask: url('../svg/spritesheet.svg');
                                    mask-repeat: no-repeat;
                                    mask-size: 600%;
                                    mask-position: 49% 1%;
                                    width: 17px;
                                    height: 17px;
                                    position: absolute;
                                    top: 38%;
                                    left: 0;
                                    margin-top: -8px;
                                }

                                .search_home_input {
                                    font-family: $primaryFont;
                                    font-weight: 300;
                                    font-size: 30px;
                                    text-transform: none;
                                    color: $primaryColor;
                                    width: 100%;
                                    border: 0 none;
                                    padding-left: 30px;

                                    &::placeholder {
                                        font-family: $primaryFont;
                                        font-weight: 300;
                                        font-size: 30px;
                                        color: $borderColor;
                                    }
                                }

                                .close_search {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: url('../svg/spritesheet.svg');
                                    background-repeat: no-repeat;
                                    background-size: 850%;
                                    background-position: 72% 50%;
                                    width: 16px;
                                    height: 16px;
                                    position: absolute;
                                    top: 38%;
                                    margin-top: -8px;
                                    right: 0;
                                    cursor: pointer;
                                    z-index: 1;
                                }
                            }

                            #search_results {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: flex-start;
                                background-color: $white;
                                width: 100%;
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                top: 100%;
                                padding-bottom: 1rem;
                                z-index: 1;

                                li {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 96%;
                                    max-width: 1680px;
                                    cursor: pointer;

                                    &.loader {
                                        span {
                                            background: $white url('../svg/three_dots.svg') left center no-repeat;
                                            background-size: 25%;
                                            font-size: 0;
                                            height: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* END RECHERCHE */
                /* WISHLIST + PANIER */
                #wishlist_top,
                #cart_top {
                    display: flex;
                    height: 84px;

                    .rolloverTxt {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & > span {
                            background: url('../svg/spritesheet.svg');
                            background-repeat: no-repeat;
                            font-size: 0;
                            margin-bottom: 18px;
                            position: relative;

                            @media screen and (max-width: 1360px) {
                                margin-bottom: 13px;
                            }

                            &:after {
                                content: ' ';
                                background: $primaryColor;
                                width: 100%;
                                height: 1px;
                                position: absolute;
                                left: 0;
                                top: calc(100% + 13px);
                                opacity: 0;
                                transition: opacity 0.3s ease-in-out;
                            }

                            .nbr_item {
                                position: absolute;
                                bottom: 100%;
                                left: 0;
                                font-family: $primaryFont;
                                font-weight: 300;
                                font-size: 14px;
                                color: $primaryColor;
                                text-transform: none;
                                text-align: center;
                                width: 16px;
                                height: 21px;
                                margin-bottom: 0;
                            }
                        }

                        &:hover {
                            & > span {
                                &:after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    #show_top_cart {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        background: $white;
                        width: 402px;
                        height: calc(100vh - 129px);
                        position: absolute;
                        top: 100%;
                        right: 0;
                        padding: 33px 35px 35px;
                        transform: translateX(100%);
                        opacity: 0;
                        transition: all 0.5s ease-in-out;

                        &.show {
                            transform: translateX(0);
                            opacity: 1;
                        }

                        .rollBasketHead {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;

                            .title_panier {
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 30px;
                                line-height: 20px;
                                margin-bottom: 5px;
                                color: $primaryColor;
                                text-transform: uppercase;
                            }

                            .panier_subtitle {
                                font-size: 17px;
                            }
                        }

                        .os-scrollbar-vertical {
                            right: 5px;
                            height: 90%;
                            margin: auto 0;
                        }

                        #cart_list {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: 335px;
                            max-height: 253px;
                            margin-top: 16px;
                            padding-right: 5px;

                            .os-scrollbar-vertical {
                                right: 0;
                                height: auto;
                            }

                            .product-link {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 100%;

                                &:not(:first-child) {
                                    margin-top: 29px;
                                }

                                .cart_link {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 74px;
                                    height: 111px;
                                    margin-right: 25px;
                                    position: relative;

                                    .cart_thumb_holder {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        img {
                                            width: 100%;
                                        }
                                    }
                                }

                                .cart_detail_box {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width: calc(100% - 99px);

                                    .cart_product {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        width: 100%;
                                        position: relative;
                                        margin-top: 13px;

                                        .title {
                                            display: flex;
                                            width: 95%;

                                            span {
                                                font-weight: 300;
                                                font-size: 17px;
                                                line-height: 14px;
                                                color: $primaryColor;

                                                &.main {
                                                    font-weight: 600;
                                                    margin-right: 5px;
                                                }

                                                &.sub {
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                }
                                            }
                                        }

                                        .item_price {
                                            margin-top: 4px;

                                            .pricetag {
                                                line-height: 12px;
                                            }
                                        }

                                        .sub_couleur,
                                        .sub_taille,
                                        .sub_quantity {
                                            display: flex;
                                            font-weight: 300;
                                            font-size: 15px;
                                            line-height: 10px;
                                            margin-top: 11px;

                                            span {
                                                margin-right: 5px;
                                            }
                                        }

                                        .modify_prod {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            position: absolute;
                                            bottom: 2px;
                                            right: 0;
                                            font-weight: 300;
                                            font-size: 16px;
                                            line-height: 14px;
                                            text-decoration: underline;
                                            text-underline-offset: 2px;
                                            cursor: pointer;
                                        }

                                        .remove_prod,
                                        .remove_top_wishlist {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            position: absolute;
                                            top: -13px;
                                            right: 0;
                                            cursor: pointer;

                                            span {
                                                background-image: url('../svg/spritesheet.svg');
                                                background-repeat: no-repeat;
                                                background-size: 700%;
                                                background-position: 74% 50%;
                                                width: 11px;
                                                height: 11px;
                                                font-size: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .wrap_tot_panier {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: 100%;
                            margin-top: 45px;

                            .cart_panier_total {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                border-top: solid 1px $primaryColor;
                                padding-top: 20px;

                                .pricetag {
                                    font-size: 22px;
                                }

                                .titre_tot {
                                    font-family: $secondaryFont;
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: $primaryColor;
                                    text-transform: uppercase;
                                }
                            }

                            .upselling.price {
                                margin-top: 1.5rem;
                                box-sizing: border-box;
                                position: relative;
                                width: 100%;

                                p {
                                    font-size: 14px;
                                    line-height: 1;
                                }

                                progress {
                                    width: 100%;
                                    appearance: none;
                                    height: 6px;
                                    vertical-align: top;
                                    margin-top: 0.5rem;
                                    border: 1px solid rgba($black, 0.5);
                                    border-radius: 10px;
                                    overflow: hidden;
                                }

                                progress::-webkit-progress-bar {
                                    background-color: $white;
                                }

                                progress::-webkit-progress-value {
                                    background-color: $primaryColor;
                                }

                                progress::-moz-progress-bar {
                                    background-color: $primaryColor;
                                }
                            }

                            .form_submit {
                                margin-top: 25px;

                                button {
                                    span {
                                        line-height: 38px;
                                    }
                                }
                            }
                        }

                        .alma_payment_text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-family: $primaryFont;
                            font-size: clamp(14px,fs-vw(16,1920),16px);
                            color: $black;
                            width: 100%;
                            padding-top: 20px;
                            
                            span {
                                font-size: clamp(10px,fs-vw(12,1920),12px);
                                text-transform: uppercase;
                                padding-left: 0.5rem;
                            }

                            &::before {
                                content: "";
                                display: block;
                                background: url('../svg/alma_logo_black.svg') no-repeat;
                                background-size: contain;
                                height: 20px;
                                width: 40px;
                            }
                        }

                        .basket_gondole {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            position: relative;
                            width: 100%;
                            margin-top: 36px;
                            opacity: 1;

                            .title_gondole {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                font-family: $secondaryFont;
                                font-weight: 400;
                                font-size: 25px;
                                line-height: 20px;
                                color: $primaryColor;
                                text-transform: uppercase;
                                position: relative;
                                margin-bottom: 25px;

                                &:before {
                                    content: '';
                                    display: block;
                                    background: $primaryColor;
                                    width: calc(100% - 200px);
                                    height: 1px;
                                }
                            }

                            #gondole_interest {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 299px;
                                margin-left: 0;

                                .swiper-wrapper {
                                    .swiper-slide {
                                        a {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            justify-content: flex-start;

                                            img {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                max-width: 135px;
                                            }

                                            .wrap_title {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                width: 100%;
                                                margin: 9px 0 2px;

                                                .product_name {
                                                    font-family: $primaryFont;
                                                    font-weight: 600;
                                                    font-size: 17px;
                                                    line-height: 11px;
                                                    color: $primaryColor;
                                                    text-transform: none;
                                                }

                                                .product_sub_name {
                                                    font-family: $primaryFont;
                                                    font-weight: 100;
                                                    font-size: 17px;
                                                    line-height: 11px;
                                                    color: $primaryColor;
                                                    text-transform: none;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    margin-left: 5px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:not(.swiper-container-initialized) {
                                    .swiper-wrapper {
                                        .swiper-slide {
                                            max-width: 135px;

                                            &:first-child {
                                                margin-right: 29px;
                                            }
                                        }
                                    }

                                    & + .swiper-interest-prev {
                                        opacity: 0;

                                        & + .swiper-interest-next {
                                            opacity: 0;
                                        }
                                    }
                                }
                            }

                            .swiper-interest-next,
                            .swiper-interest-prev {
                                display: flex;
                                background: url('../svg/spritesheet.svg');
                                background-size: 1000%;
                                background-position: 93% 0%;
                                width: 9px;
                                height: 17px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                margin-top: 0;
                                z-index: 2;
                                transition: all 0.5s ease-in-out;

                                &.swiper-button-disabled {
                                    opacity: 0;
                                    cursor: auto;
                                    pointer-events: none;
                                }
                            }

                            .swiper-interest-prev {
                                top: 60%;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                #wishlist_top {
                    margin-right: 25px;

                    .rolloverTxt {
                        span {
                            background-size: 600%;
                            background-position: 74% 2%;
                            width: 17px;
                            height: 17px;

                            @media screen and (max-width: 1360px) {
                                margin-bottom: 13px;
                            }
                        }
                    }

                    #show_top_cart {
                        padding: 33px 35px 31px;

                        #cart_list {
                            max-height: 395px;
                            margin-bottom: 47px;
                        }

                        .wrap_tot_panier {
                            margin-top: auto;

                            .cart_panier_total {
                                padding-top: 17px;
                                margin-bottom: 62px;
                            }

                            .form_submit {
                                margin-top: 21px;
                            }
                        }
                    }

                    .basket_gondole {
                        display: none !important;
                    }
                }

                #cart_top {
                    .rolloverTxt {
                        & > span {
                            background-size: 600%;
                            background-position: 25% 2%;
                            width: 16px;
                            height: 16px;
                        }
                    }

                    #show_top_cart {
                        .wrap_tot_panier {
                            .form_submit {
                                margin-top: 19px;
                            }
                        }
                    }
                }

                /* END WISHLIST + PANIER */
                /* COMPTE */
                #compte_top {
                    display: flex;
                    height: 84px;
                    margin-right: 30px;

                    .rolloverTxt {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            background: url('../svg/spritesheet.svg');
                            background-repeat: no-repeat;
                            background-size: 600%;
                            background-position: 2% 0%;
                            width: 15px;
                            height: 16px;
                            margin-bottom: 18px;
                            position: relative;

                            @media screen and (max-width: 1360px) {
                                margin-bottom: 13px;
                            }

                            &:after {
                                content: ' ';
                                background: $primaryColor;
                                width: 100%;
                                height: 1px;
                                position: absolute;
                                left: 0;
                                top: calc(100% + 13px);
                                opacity: 0;
                                transition: opacity 0.3s ease-in-out;
                            }
                        }

                        &:hover {
                            & > span {
                                &:after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    #topLogin {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        background: $white;
                        width: 402px;
                        height: calc(100vh - 129px);
                        position: absolute;
                        top: 100%;
                        right: 0;
                        padding: 33px 35px 31px;
                        transform: translateX(100%);
                        opacity: 0;
                        transition: all 0.3s ease-in-out;

                        &.show {
                            transform: translateX(0);
                            opacity: 1;
                        }

                        .account_name {
                            font-family: $secondaryFont;
                            font-weight: 400;
                            font-size: 30px;
                            line-height: 22px;
                            color: $primaryColor;
                            text-transform: uppercase;
                            text-align: center;
                            width: 100%;
                            margin-bottom: 46px;
                        }

                        .wrapper_menu_compte {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;

                            .item {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;

                                &:not(:last-child) {
                                    margin-bottom: 30px;
                                }

                                .top_item_cta {
                                    display: flex;

                                    span {
                                        display: flex;
                                        font-weight: 300;
                                        font-size: 18px;
                                        line-height: 10px;

                                        &.highlight {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        .holder_cta {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            margin-top: auto;
                            margin-bottom: 0;

                            .btn_container {
                                &:first-child {
                                    margin-bottom: 24px;
                                }
                            }
                        }
                    }
                }

                /* END COMPTE */
            }

            /* END ROLLOVERS */
        }
    }

    /* START STICKY */
    &.sticky {
        #header_mention_id {
            height: 0;
        }

        #top_wrapper {
            height: 55px;
            background-color: rgba(255, 255, 255, 1);

            @media screen and (max-width: 1360px) {
                height: auto;
                padding: 0;
            }

            .header {
                .block_logo {
                    margin-top: 0;
                    margin-bottom: 3px;

                    .logo_img_holder {
                        height: 22px;
                    }
                }

                .main_menu {
                    .main_menu_contents {
                        .main_menu_itm {
                            padding-bottom: 0;
                            padding-top: 5px;

                            @media screen and (max-width: 1360px) {
                                padding-bottom: 12px;
                            }
                        }
                    }
                }

                .block_top_links {
                    padding-bottom: 0;

                    @media screen and (max-width: 1360px) {
                        padding-bottom: 0;
                    }

                    .wrap_lang {
                        margin-bottom: 0;
                    }

                    .block_search {
                        margin-bottom: 0;
                    }

                    @media screen and (max-width: 1024px) {
                        margin-top: 0;
                    }

                    #cart_top,
                    #wishlist_top {
                        height: 55px;

                        .rolloverTxt {
                            span {
                                margin-bottom: 0;
                            }
                        }

                        #show_top_cart {
                            height: calc(100vh - 60px);
                        }
                    }

                    #compte_top {
                        height: 55px;

                        .rolloverTxt {
                            span {
                                margin-bottom: 0;
                            }
                        }

                        #topLogin {
                            height: calc(100vh - 60px);
                        }
                    }
                }
            }
        }
    }

    /* END STICKY */
    &.filter_on {
        z-index: 10;
        background-color: $white;
        padding-bottom: 1px;
    }
}

body {
    &:not(.homepage) {
        #top_wrapper {
            background-color: rgba(255, 255, 255, 1) !important;
        }
    }
}

/* Inputs */
input {
    font-family: $tertiaryFont;
}

.w-form-line {
    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) input[type=email].w-input-element {
        padding-top: 1.15em;
    }
    
    .w-textarea.w-nonempty .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-textarea.w-nonempty textarea.w-input-element, .w-textarea.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-textarea.w-nonempty:not(.no-label) textarea.w-input-element{
        padding-top: 1.9em;
    }
}